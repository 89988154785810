import { type Locale } from "@/config/locale";
import { storage } from "@/utils/storage";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function UseLanguage() {
  const [language, setLanguage] = useState<Locale>(
    storage.getLanguage() as Locale,
  );
  const { i18n } = useTranslation();

  const changeLanguage = (lng: Locale) => {
    void i18n.changeLanguage(lng);
    setLanguage(lng);
    storage.setLanguage(lng);
  };

  return { language, changeLanguage };
}
