export function getEnumTransKey(namespace: string, value: string) {
  return `${namespace}.${value.charAt(0).toLocaleLowerCase() + value.slice(1)}`;
}

//backend translations
export function bT(template: string, values: string[]): string {
  return template.replace(/\{(\d+)\}/g, (match, index: string): string => {
    const paramIndex = parseInt(index, 10);
    const translation = paramIndex < values.length ? values[paramIndex] : match;
    return translation!;
  });
}
