import { Box, Button, Center, Flex, Modal, Text } from "@mantine/core";
import { BusinessUnitSelect } from "./Header/BusinessUnitSelect";
interface BusinessUnitModalProps {
  opened: boolean;
  userBusinessUnitId: string | null;
  updateUserBusinessUnit: (
    businessUnitId: string | null,
    closeModal: boolean,
  ) => void;
}

export default function BusinessUnitModal({
  opened,
  userBusinessUnitId,
  updateUserBusinessUnit,
}: BusinessUnitModalProps) {
  return (
    <Modal
      opened={opened}
      onClose={() => {
        //keep this in place. User can not close it without selecting BU
      }}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size={"45%"}
      overlayProps={{
        backgroundOpacity: 0.65,
        blur: 2,
      }}
      transitionProps={{ transition: "rotate-left" }}
    >
      <Box>
        <Center>
          <Text ta={"center"} fw={700}>
            Please Select Business Unit
          </Text>
        </Center>
        <Flex
          mt={10}
          justify="center"
          gap={"md"}
          align="center"
          direction={{ lg: "row", md: "column", base: "column" }}
        >
          <BusinessUnitSelect
            businessUnitId={userBusinessUnitId}
            setBusinessUnitId={updateUserBusinessUnit}
            closeModal={true}
          />
          <Button
            variant="subtle"
            onClick={() => {
              updateUserBusinessUnit(userBusinessUnitId, false);
            }}
          >
            Confirm
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
}
