export const config = {
  PRODUCT_TITLE: "StorageLogic",
  PRODUCT_TITLE_ACC: "StorageLogic Acceptance",
  CURRENCY: {
    code: "EUR",
    symbol: "€",
    decimals: 2,
    separator: ",",
    locale: "nl-NL",
  },
  PERCENTAGE: {
    code: "PRC",
    symbol: "%",
  },
  METERS: {
    code: "MTR",
    symbol: "m",
  },
  SQUAREMETERS: {
    code: "SQMTR",
    symbol: "m²",
  },
  CUBICMETERS: {
    code: "CMTR",
    symbol: "m³",
  },
  DATES: {
    timezone: "Europe/Amsterdam",
    timezoneValue: "Europe_Amsterdam",
    dateFormat: "YYYY-MM-DD",
  },
};
