import { useInfiniteEntityListQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";
import { type MantineStyleProps, useCombobox } from "@mantine/core";
import { type GetInputPropsReturnType } from "node_modules/@mantine/form/lib/types";
import { type QueryParams } from "@/features/entity/api";
import { useDebouncedValue } from "@mantine/hooks";
import React, { useCallback, useState } from "react";
import {
  Lookup,
  OptionRender,
  OptionGroupRender,
} from "@/components/Lookup/Lookup";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";

type ContactLookupFieldProps = GetInputPropsReturnType &
  MantineStyleProps & {
    label?: string;
    required?: boolean;
    disabled?: boolean;
    initial?: Schemas["ContactRetrieveDto"] | null;
  };

export function ContactLookupField({
  required = false,
  disabled = false,
  initial,
  ...props
}: ContactLookupFieldProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [lookupValue, setLookupValue] = useState<string | null>(
    initial?.fullName ?? null,
  );
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const queryParams: QueryParams = {
    pageSize: 50,
  };
  if (debouncedSearchTerm) {
    queryParams.searchBy = debouncedSearchTerm;
  }
  const { data, isFetching, fetchNextPage } = useInfiniteEntityListQuery<
    Schemas["ContactRetrieveDtoPagedList"]
  >({
    resourcePath: "/api/Contacts",
    queryKey: "contact",
    params: queryParams,
    enabled: isDropdownOpen,
  });

  const loadMore = useCallback(() => {
    void fetchNextPage();
  }, [fetchNextPage]);

  const infiniteScrollRef = useInfiniteScroll(loadMore, !isFetching);
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.focusTarget();
      setSearchTerm("");
      setIsDropdownOpen(false);
    },
    onDropdownOpen: () => {
      combobox.focusTarget();
      setIsDropdownOpen(true);
    },
  });
  const entityList = data?.pages.map((page) => page?.data).flat() ?? [];
  const options: JSX.Element[] = [];
  const header = (
    <React.Fragment>
      <div>First Name</div>
      <div>Last Name</div>
      <div>Email</div>
      <div>Mobile</div>
      <div>Number</div>
    </React.Fragment>
  );
  entityList.forEach((entity, index) => {
    if (entity) {
      const renderedOptions: JSX.Element[] = [];
      renderedOptions.push(
        OptionRender(entity.firstName, searchTerm, "firstName_" + entity.id),
      );
      renderedOptions.push(
        OptionRender(entity.lastName, searchTerm, "lastName_" + entity.id),
      );
      renderedOptions.push(
        OptionRender(entity.email, searchTerm, "email_" + entity.id),
      );
      renderedOptions.push(
        OptionRender(entity.mobile, searchTerm, "mobile_" + entity.id),
      );
      renderedOptions.push(
        OptionRender(entity.number, searchTerm, "number_" + entity.id),
      );
      options.push(
        OptionGroupRender(
          entity.id!,
          index,
          entity.fullName!,
          entityList.length,
          infiniteScrollRef,
          renderedOptions,
        ),
      );
    }
  });

  const pageSize = data?.pages[0]?.pageSize ?? 0;
  const currentPage = data?.pages.length ?? 0;
  const currentFocus = currentPage === 1 ? 0 : pageSize * (currentPage - 1);
  return (
    <Lookup
      combobox={combobox}
      required={required}
      disabled={disabled}
      lookupValue={lookupValue}
      setLookupValue={setLookupValue}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      options={options}
      header={header}
      isFetching={isFetching}
      currentFocus={currentFocus}
      entity={"Contacts"}
      entityId={initial?.id}
      {...props}
    />
  );
}
