import { useState, useEffect } from "react";
import { useEntityUpdateMutation } from "@/features/entity/mutations";
import { type Schemas } from "@/types";
import UseUser from "../useUser";

export default function UseUserBusinessUnit() {
  const { finalUser: user, isLoading } = UseUser();
  const [userBusinessUnitId, setCurrentBusinessUnitId] = useState(
    user?.businessUnitId,
  );
  const [showBusinessUnitModal, setShowBusinessUnitModal] = useState(
    user?.showBusinessUnitSelector,
  );
  const { mutate } = useEntityUpdateMutation<
    Schemas["AppUserPatchDto"],
    Schemas["AppUserPatchDto"]
  >({
    resourcePath: "/api/AppUsers/{id}",
    resourceId: user?.id ?? "",
    queryKey: "appUser_" + user?.businessUnitId,
  });

  useEffect(() => {
    setCurrentBusinessUnitId(user?.businessUnitId);
    setShowBusinessUnitModal(user?.showBusinessUnitSelector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.businessUnitId]);

  const updateUserBusinessUnit = (
    businessUnitId: string | null,
    closeModal = false,
  ) => {
    if (businessUnitId) {
      mutate(
        { businessUnitId: businessUnitId },
        {
          onSuccess: () => {
            console.log(closeModal);
            setCurrentBusinessUnitId(businessUnitId);
            setShowBusinessUnitModal(closeModal);
          },
        },
      );
    }
  };

  return {
    isLoading,
    updateUserBusinessUnit,
    userBusinessUnitId,
    showBusinessUnitModal,
  };
}
