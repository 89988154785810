import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import Cache from "i18next-localstorage-cache";
import { DateTime } from "luxon";
import "intl-pluralrules";

void i18n
  .use(HttpBackend)
  .use(Cache)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    debug: false,
    cache: {
      enabled: true,
    },
    fallbackLng: "English",
    interpolation: {
      escapeValue: false,
    },
  });

i18n.services.formatter?.add("DATE_HUGE", (value: Date, lng) => {
  return DateTime.fromJSDate(value)
    .setLocale(lng!)
    .toLocaleString(DateTime.DATE_HUGE);
});

i18n.services.formatter?.add("DATE_MED", (value: Date, lng) => {
  return DateTime.fromJSDate(value)
    .setLocale(lng!)
    .toLocaleString(DateTime.DATE_MED);
});

export default i18n;
