import { useState, useEffect, useMemo } from "react";
import { Box, Text, Stack } from "@mantine/core";
import classes from "../../Home.module.css";
import HomeAgendaToolbar from "./HomeAgendaToolbar";
import { useEntityListQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";
import { PageLoader } from "@/components/PageLoader";
import { HomeAgendaItem } from "./HomeAgendaItem";

type Appointment = Schemas["AppointmentRetrieveDto"];

function formatDateForQuery(date: Date): string {
  return date.toISOString().split(".")[0] ?? ""; // This removes milliseconds and 'Z'
}

interface HomeAgendaProps {
  currentUser: Schemas["AppUser"] | null;
}

export default function HomeAgenda({ currentUser }: HomeAgendaProps) {
  const [selectedPeriod, setSelectedPeriod] = useState("Week");
  const [dateRange, setDateRange] = useState({
    startDate: formatDateForQuery(new Date()),
    endDate: formatDateForQuery(new Date()),
  });

  useEffect(() => {
    const now = new Date();
    const start = new Date(now);
    const end = new Date(now);

    switch (selectedPeriod) {
      case "Today":
        end.setHours(23, 59, 59, 999);
        break;
      case "Week":
        end.setDate(now.getDate() + 6);
        end.setHours(23, 59, 59, 999);
        break;
      case "Month":
        end.setMonth(now.getMonth() + 1);
        end.setDate(0);
        end.setHours(23, 59, 59, 999);
        break;
    }

    setDateRange({
      startDate: formatDateForQuery(start),
      endDate: formatDateForQuery(end),
    });
  }, [selectedPeriod]);

  const { data, isLoading } = useEntityListQuery<
    Schemas["AppointmentRetrieveDtoPagedList"]
  >({
    resourcePath: "/api/Appointments",
    queryKey: `${dateRange.startDate}_${dateRange.endDate}_appointment`,
    params: {
      filter: `startDate >= "${dateRange.startDate}" && startDate <= "${dateRange.endDate}" && businessUnit.Id == "${currentUser!.businessUnitId ?? ""}"`,
    },
  });

  const sortedAppointments = useMemo(() => {
    const appointments = data?.data ?? [];
    return appointments.sort((a, b) => {
      const dateA = new Date(a.startDate ?? 0);
      const dateB = new Date(b.startDate ?? 0);
      return dateA.getTime() - dateB.getTime();
    });
  }, [data]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Box w="100%" h="100%" className={classes.HomeAgenda}>
      <HomeAgendaToolbar
        selectedPeriod={selectedPeriod}
        onPeriodChange={setSelectedPeriod}
      />
      {sortedAppointments.length > 0 ? (
        <Stack>
          {sortedAppointments.map((appointment: Appointment, index: number) => (
            <HomeAgendaItem key={index} appointment={appointment} />
          ))}
        </Stack>
      ) : (
        <Text ta="center" fw={700}>
          No upcoming appointments found for the selected period.
        </Text>
      )}
    </Box>
  );
}
