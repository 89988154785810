import { useCallback, useEffect, useRef } from "react";

export function useInView(callback: () => void) {
  const observerTarget = useRef(null);

  const onIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries.length === 0) {
        return;
      }

      if (entries[0]?.isIntersecting) {
        callback();
      }
    },
    [callback],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [onIntersection]);

  return observerTarget;
}
