import { Button, Flex, Text, Tooltip } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
interface ButtonMainProps {
  label: string;
  icon: JSX.Element;
  color?: string;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  loading?: boolean;
}

export default function ButtonMain({
  label,
  icon,
  color,
  type,
  onClick,
  loading,
}: ButtonMainProps) {
  const isMobile = useMediaQuery(`(max-width: 724px)`);
  return (
    <Tooltip label={label}>
      <div>
        <Button
          variant="outline"
          leftSection={isMobile ? "" : icon}
          size="compact-md"
          type={type}
          onClick={onClick}
          color={color}
          loading={loading}
        >
          <Flex>
            {isMobile ? icon : ""}
            {isMobile ? (
              ""
            ) : (
              <Text size="sm" fw={600}>
                {label}
              </Text>
            )}
          </Flex>
        </Button>
      </div>
    </Tooltip>
  );
}
