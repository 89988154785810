import { Button } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface EditButtonProps {
  to?: string;
}

export function EditButton({ to = "edit" }: EditButtonProps) {
  const { t } = useTranslation("features");

  return (
    <Link to={to}>
      <Button leftSection={<IconEdit size={16} />} variant="outline">
        {t("entity.edit")}
      </Button>
    </Link>
  );
}
