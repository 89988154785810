import { Box, Text, Group, Badge, ThemeIcon } from "@mantine/core";
import { IconCalendar, IconClock, IconBuilding } from "@tabler/icons-react";
import { type Schemas } from "@/types";
import { useTimeLeft } from "./useTimeLeft"; // Make sure to update this hook as well

type Appointment = Schemas["AppointmentRetrieveDto"];

interface HomeAgendaItemProps {
  appointment: Appointment;
}

export function HomeAgendaItem({ appointment }: HomeAgendaItemProps) {
  const startDate = appointment.startDate
    ? new Date(appointment.startDate)
    : null;
  const endDate = appointment.endDate ? new Date(appointment.endDate) : null;
  const timeLeft = useTimeLeft(startDate);

  return (
    <Box
      p="md"
      ml={5}
      bg="white"
      style={(theme) => ({
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.sm,
        transition: "box-shadow 0.3s ease",
        "&:hover": {
          boxShadow: theme.shadows.md,
        },
      })}
    >
      <Group mb="xs">
        <Group>
          <Text fw={700} size="sm">
            {timeLeft}
          </Text>
          <Badge color="blue" variant="light">
            {appointment.appointmentType}
          </Badge>
        </Group>
        <Text fw={500} size="sm" color="blue"></Text>
      </Group>

      <Group>
        {startDate && (
          <Group gap="xs">
            <ThemeIcon color="gray" size="sm" variant="light">
              <IconCalendar size={14} />
            </ThemeIcon>
            <Text size="sm" c="dimmed">
              {startDate.toLocaleDateString()}
            </Text>
          </Group>
        )}
        {startDate && endDate && (
          <Group gap="xs">
            <ThemeIcon color="gray" size="sm" variant="light">
              <IconClock size={14} />
            </ThemeIcon>
            <Text size="sm" c="dimmed">
              {startDate.toLocaleTimeString()} - {endDate.toLocaleTimeString()}
            </Text>
          </Group>
        )}
      </Group>

      {appointment.businessUnitId && (
        <Group gap="xs" mt="sm">
          <ThemeIcon color="blue" size="sm" variant="light">
            <IconBuilding size={14} />
          </ThemeIcon>
          <Text size="sm">{appointment.businessUnit?.code}</Text>
        </Group>
      )}
    </Box>
  );
}
