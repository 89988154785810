import { IconCloud } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ButtonMain from "../ButtonMain/ButtonMain";
import { useState } from "react";

interface SaveButtonProps {
  setClose: (value: boolean) => void;
  loading?: boolean;
}

export function SaveButton({ setClose, loading }: SaveButtonProps) {
  const { t } = useTranslation("features");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    setClose(false);

    setTimeout(() => {
      setIsSubmitting(false);
    }, 2000);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <ButtonMain
        loading={loading}
        label={t("entity.save")}
        icon={<IconCloud size={18} />}
        onClick={handleClick}
        type="submit"
      />
    </div>
  );
}
