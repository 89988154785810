import { useTranslation } from "react-i18next";
import { useEntityCreateMutation } from "@/features/entity/mutations";
import { useSearchParams } from "react-router-dom";
import { filterFalsyValues } from "@/utils/filters";
import { type Schemas } from "@/types";
import { AppointmentForm } from "../components/AppointmentForm";
import { notifications } from "@mantine/notifications";
import { useEntityQuery } from "@/features/entity/queries";
import { type Dispatch, type SetStateAction } from "react";
import { useSettingsContext } from "@/components/Layout/Contexts/Settings/useSettingsContext";
import { api } from "@/lib/api";
import { useQueryClient } from "react-query";

interface AppointmentCreateProps {
  refreshForm?: () => void;
  closeModal?: () => void;
  leadId?: string;
  businessUnitId?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  isModal?: boolean;
  setPhantomEvent?: Dispatch<SetStateAction<Schemas["Appointment"] | null>>;
}

export function AppointmentCreate({
  refreshForm: refreshAllAppointments,
  closeModal,
  leadId: leadIdProp,
  businessUnitId,
  startDate,
  endDate,
  isModal,
  setPhantomEvent,
}: AppointmentCreateProps) {
  const [searchParams] = useSearchParams();
  const { MovingHelpIds, MovingHelpGroups } = useSettingsContext();
  const isMovingHelp = MovingHelpIds?.includes(businessUnitId ?? "");
  const { mutate } = useEntityCreateMutation<
    Schemas["Appointment"],
    Schemas["AppointmentCreateDto"]
  >({ resourcePath: "/api/Appointments", queryKey: "appointment" });
  const { t } = useTranslation("features");
  const leadId = searchParams.get("leadId");
  const leadIdToUse = leadId ?? leadIdProp;
  const queryCache = useQueryClient();
  const {
    data: lead = {},
    isLoading,
    isFetching,
  } = useEntityQuery<Schemas["Lead"]>({
    resourcePath: "/api/Leads/{id}",
    resourceId: leadIdToUse!,
    queryKey: "leadAppointment",
  });
  const {
    data: businessUnit = {},
    isLoading: isLoadingBu,
    isFetching: isFetchingBU,
  } = useEntityQuery<Schemas["Lead"]>({
    resourcePath: "/api/BusinessUnits/{id}",
    resourceId: isMovingHelp ? "" : businessUnitId ?? "",
    queryKey: "businessUnitAppointment",
  });

  if (isLoading || isFetching || isLoadingBu || isFetchingBU) {
    return <></>;
  }

  async function AppointmentAddAttachments(
    appointmentId: string,
    attachment: File[] | null,
  ) {
    const url = `/api/appointments/${appointmentId}/attachments`;
    const formData = new FormData();
    if (attachment && attachment.length > 0) {
      attachment.forEach((file) => {
        formData.append(`Files`, file);
      });
    }
    try {
      const response = await api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response;
    } catch (error) {
      console.error("Error in CaseSendReply:", error);
      throw error;
    }
  }
  const initialValues = {
    id: "",
    description: "",
    subject: "",
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
    businessUnitId: isMovingHelp ? "" : businessUnitId ?? "",
    businessUnit: businessUnit,
    contactId: lead?.existingContactId ?? "",
    contact: lead?.existingContact ?? null,
    assignedToId: isMovingHelp ? businessUnitId ?? "" : "",
    assignedTo: isMovingHelp
      ? MovingHelpGroups?.filter((x) => {
          return x.id == businessUnitId;
        })[0] ?? null
      : null,
    leadId: leadIdToUse ?? "",
    lead: lead,
    appointmentType: null,
    appointmentStatus: "Open",
  };
  return (
    <AppointmentForm
      isCreate={true}
      isModal={isModal}
      showBackButton={false}
      isMovingHelp={isMovingHelp}
      initialValues={initialValues}
      closeModal={closeModal}
      setPhantomEvent={setPhantomEvent}
      onSubmit={(values, attachment) => {
        const filteredValues = filterFalsyValues(values);
        mutate(filteredValues, {
          onSuccess: (data) => {
            notifications.show({
              color: "green",
              title: t("appointments.createSuccessTitle"),
              message: t("appointments.createSuccessMessage"),
            });
            try {
              const appointmentId = data.data.id ?? undefined;
              if (appointmentId && attachment && attachment.length > 0) {
                void AppointmentAddAttachments(appointmentId, attachment);
              }
            } catch {
              notifications.show({
                color: "red",
                title: t("appointments.attachmentAddErrorTitle"),
                message: t("appointments.attachmentAddErrorMessage"),
              });
            }

            if (refreshAllAppointments) {
              refreshAllAppointments();
            }
            if (closeModal) {
              closeModal();
            }
            if (setPhantomEvent) {
              setPhantomEvent(null);
            }
            void queryCache.invalidateQueries(`lead_${leadIdToUse}`);
          },
        });
      }}
    />
  );
}
