import { useEntityUpdateMutation } from "@/features/entity/mutations";
import { useEntityListQuery, useEntityQuery } from "@/features/entity/queries";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { type Schemas } from "@/types";
import { type MenuLink } from "@/components/Layout/Navbar/utils";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";

export default function UseNavBar() {
  const {
    data,
    isLoading: isLoadingAppUser,
    isFetching: isFetchingAppUser,
  } = useEntityQuery<Schemas["AppUserRetrieveDto"]>({
    resourcePath: "/api/AppUsers/{id}",
    resourceId: "WhoAmI",
    queryKey: "appUser",
  });

  const {
    data: appsResponse,
    isLoading: isLoadingApp,
    isFetching: isFetchingApp,
  } = useEntityListQuery<Schemas["AppRetrieveDtoPagedList"]>({
    resourcePath: "/api/Apps",
    queryKey: "app",
  });

  const { mutate, isLoading: isUpdating } = useEntityUpdateMutation<
    Schemas["AppUserPatchDto"],
    Schemas["AppUserPatchDto"]
  >({
    resourcePath: "/api/AppUsers/{id}",
    resourceId: data?.id ?? "",
    queryKey: "appUser",
  });

  const queryCache = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const apps = appsResponse?.data ?? [];
  const currentAppId = data?.currentApp?.id;

  const updateUser = (appId: string | null) => {
    if (appId) {
      mutate(
        { currentAppId: appId },
        {
          onSuccess: () => {
            void queryCache.invalidateQueries("appUser_WhoAmI");
            navigate("/app");
          },
        },
      );
    }
  };

  const options = apps.map((d) => {
    return { label: d.name ?? "", value: d.id ?? "" };
  });
  const isLoading =
    isLoadingAppUser ||
    isLoadingApp ||
    isUpdating ||
    isFetchingApp ||
    isFetchingAppUser;
  const menuLinks: MenuLink[] = parseNavBarLinks(data, isLoading, t);

  return {
    isLoading,
    updateUser,
    menuLinks,
    apps,
    currentAppId,
    options,
  };
}

function parseNavBarLinks(
  data: Schemas["AppUserRetrieveDto"] | undefined,
  isLoading: boolean,
  t: (key: string) => string,
): MenuLink[] {
  if (isLoading || !data?.currentApp?.navBar) {
    return [];
  }

  try {
    return JSON.parse(data.currentApp.navBar) as MenuLink[];
  } catch (e) {
    notifications.show({
      color: "red",
      title: t("errors.defaultTitle"),
      message: t("errors.invalidJson"),
    });
    return [];
  }
}
