import { type components } from "@/types/api.generated";

export const ProductType: components["schemas"]["ProductTypeEnum"][] = [
  "ArchiveUnit",
  "BusinessAddress",
  "FacilityUnit",
  "HomeService",
  "Mailbox",
  "MrBox",
  "MotorUnitBasis",
  "MotorUnitBudget",
  "MotorUnitPlus",
  "NationalAccounts",
  "PersonnelUnit",
  "Savor",
  "StorageUnitL",
  "StorageUnitM",
  "StorageUnitS",
  "StorageUnitXL",
  "StorageUnitXS",
  "TireStorageBasis",
  "TireStorageBudget",
  "TireStoragePlus",
  "ParkingPlace",
  "RentVehicle",
  "MovingHelp",
  "ScooterStorage",
  "StockStorage",
  "WebshopSelfService",
  "Unknown",
  "BoatStorage",
  "CaravanStorage",
  "CamperStorage",
  "Question",
  "EngineRooms",
];

export const StartWithin: components["schemas"]["StartWithinEnum"][] = [
  "ASAP",
  "OneWeek",
  "TwoWeeks",
  "OneMonth",
  "TwoMonths",
  "ThreeMonths",
  "SixMonths",
  "Later",
];

export const Discount: components["schemas"]["DiscountEnumn"][] = [
  "LongStay12to10",
  "LongStay12to11",
  "Supersize",
  "RentFor6PayFor5",
  "RentFor4PayFor3",
  "RentFor3PayFor2",
  "PromoRentFor4PayFor2",
  "CustomDiscount",
  "LowestPriceGuarantee",
  "EngineRooms12",
  "EngineRooms6",
  "FixedTerm6",
  "FixedTerm12",
  "FixedTerm36",
];

export const StorageDuration: components["schemas"]["StorageDurationEnum"][] = [
  "Months0to3",
  "Months3to6",
  "Months6to9",
  "Months9to12",
  "Unknown",
];

export const SizeOfUnit: components["schemas"]["SizeOfUnitEnum"][] = [
  "ExtraSmall",
  "Small",
  "Medium",
  "Large",
  "ExtraLarge",
  "Archive",
  "Facility",
  "Motor",
];

export const StorageUnitReason: components["schemas"]["StorageUnitReasonEnum"][] =
  [
    "BackToParents",
    "CrisisNursingPension",
    "Emigration",
    "ExtraSpaceHobby",
    "ExtraSpaceLiveSmaller",
    "ExtraSpaceLiveTogether",
    "ExtraSpaceSeasonalStorage",
    "ExtraSpaceStaging",
    "LettersMail",
    "MovingHouse",
    "Passing",
    "Refurbishment",
    "Separation",
    "StockStorage",
    "WaitingForStudentHome",
  ];

export const ProcessStage: components["schemas"]["ProcessStage"][] = [
  "New",
  "NotReached",
  "CallbackAppointment",
  "Appointment",
  "SpaceTour",
  "FollowUp",
  "Won",
  "Lost",
];

export const RentAsBusiness: components["schemas"]["RentAsBusinessEnum"][] = [
  "Yes",
  "No",
];

export const Transportation: components["schemas"]["TransportationEnum"][] = [
  "Budget",
  "Basis",
  "Plus",
  "SelfService",
  "Unknown",
];

export const Salutation: components["schemas"]["SalutationEnum"][] = [
  "Mr",
  "Mrs",
  "MrMrs",
  "Mx",
];

export const LeadType: components["schemas"]["LeadTypeEnum"][] = [
  "Private",
  "Business",
];

export const LeadSource: components["schemas"]["LeadSourceEnum"][] = [
  "WalkIn",
  "PhoneCallAtBU",
  "PhoneCallAtSalesService",
  "Advertisement",
  "CustomerEvent",
  "NationalAccount",
  "GoogleAdWords",
  "Other",
  "Partner",
  "PurchasedList",
  "Website",
  "SalesLetter",
  "Newsletter",
];

export const PreferredLanguage: components["schemas"]["LanguageEnum"][] = [
  "English",
  "Dutch",
];

export const OptInType: components["schemas"]["OptInTypeEnum"][] = [
  "OptIn",
  "DoubleOptIn",
  "OptOut",
];

export const EntityState: components["schemas"]["EntityStateEnum"][] = [
  "Active",
  "Inactive",
];

export const BusinessUnitType: components["schemas"]["BusinessUnitTypeEnum"][] =
  ["SelfStorage", "EngineRoom", "RoboticStorage", "Other"];

export const BusinessUnitTypeFilter: components["schemas"]["BusinessUnitTypeEnum"][] =
  ["SelfStorage", "EngineRoom", "RoboticStorage"];

export const AccessControlType: components["schemas"]["AccessControlTypeEnum"][] =
  ["Prisma", "SCAccess", "Brivo"];

export const ParkingSpotsType: components["schemas"]["ParkingSpotsTypeEnum"][] =
  ["Cars", "CarSmallCaravan", "Campers", "Truck"];

export const AdvisedUnitType: components["schemas"]["AdvisedUnitTypeEnum"][] = [
  "Unit",
  "Insurance",
  "Electricity",
  "Lighting",
];

export const ReservationStatus: components["schemas"]["ReservationStatusEnum"][] =
  ["Active", "AboutToExpire", "Cancelled", "Converted", "Extended", "Expired"];

export const AppointmentType: components["schemas"]["AppointmentTypesEnum"][] =
  [
    "SpaceTour",
    "MovingVan",
    "Trailer",
    "Desk",
    "BlockCalendar",
    "FollowUp",
    "MovingHelp",
    "EngineRoomTour",
    "RoboticStorageTour",
  ];

export const AppointmentStatus: components["schemas"]["AppointmentStatusEnum"][] =
  ["Open", "Completed", "NoShow", "Cancelled"];

export const CaseStatus: components["schemas"]["CaseStatusEnum"][] = [
  "New",
  "InProgress",
  "Reopened",
  "Escalated",
  "EscalationReturned",
  "Closed",
];

export const UnitGroup: components["schemas"]["UnitGroupEnum"][] = [
  "LogisticsProduct",
  "GroundFloor",
  "Box",
  "TwentyFourHourUnits",
  "DriveThru",
  "Basement",
  "IndoorParking",
  "ParkingLot",
  "FirstFloor",
  "SecondFloor",
  "ThirdFloor",
  "FourthFloor",
  "FifthFloor",
  "SixthFloor",
  "SeventhFloor",
  "EighthFloor",
  "NinththFloor",
];

export const ContractStatus: components["schemas"]["ContractStatusEnum"][] = [
  "Active",
  "Draft",
  "CancelledByClient",
  "Cancelled",
  "Inactive",
];

export const CancelReason: components["schemas"]["CancelReasonEnum"][] = [
  "CancelContract_EarlierStartDate",
  "CancelContract_IncorrectBooking",
  "TerminatedByALLSAFE",
  "OwnStorage",
  "EndOfWinterStorage",
  "Bankruptcy",
  "Emigrated",
  "TenantDeceased",
  "RegistrationNotAcceptedByKvK",
  "LongStayConversionOrExtension",
  "NoLongerNeeded",
  "Dissatisfied",
  "NewBusinessPremises",
  "NewHome_Delivered",
  "ConvertToDifferentName",
  "ConversionFromPrivateToBusiness",
  "Evicted",
  "StoredGoodsSold",
  "SupersizeItEnd_PriceAdjustmentNeeded",
  "TooExpensive",
  "RenovationCompleted",
  "MovedWithinALLSAFE",
  "MovedToAnotherLocation",
];

export const InvoicingType: components["schemas"]["InvoicingTypeEnum"][] = [
  "Week",
  "Month",
  "Year",
];

export const PaymentMethod: components["schemas"]["PaymentMethodEnum"][] = [
  "CreditCard",
  "BankTransfer",
  "Cash",
];

export const PhoneCallStatus: components["schemas"]["PhoneCallStatusEnum"][] = [
  "Open",
  "Reached",
  "NotReached",
  "Cancelled",
];

export const QuoteStatus: components["schemas"]["QuoteStatusEnum"][] = [
  "Draft",
  "Sent",
  "Accepted",
  "Denied",
  "Expired",
  "Revised",
  "Cancelled",
];

export const ReturnReason: components["schemas"]["ReturnReasonEnum"][] = [
  "NormalUseProductDamage",
  "PostNLProductDamage",
  "ReturningUnused",
  "IncompleteProduct",
  "Quality",
  "Other",
];

export const RefundStatus: components["schemas"]["RefundStatusEnum"][] = [
  "New",
  "Incomplete",
  "Checked",
  "Closed",
];

export const MessageStatus: components["schemas"]["MessageStatusEnum"][] = [
  "Draft",
  "Sending",
  "Failed",
  "Sent",
];

export const UnitStatus: components["schemas"]["UnitStatusEnum"][] = [
  "Available",
  "InUse",
  "Blocked",
  "InMaintenance",
  "InternalUse",
  "MoveOut",
  "Reserved",
];

export const Floor: components["schemas"]["FloorEnum"][] = [
  "Basement",
  "Floor0",
  "Floor1",
  "Floor2",
  "Floor3",
  "Floor4",
  "Floor5",
  "Floor6",
  "Floor7",
  "Floor8",
  "Floor9",
  "Parking",
  "ParkingInside",
];
