import { useInfiniteEntityListQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";
import { type MantineStyleProps, useCombobox } from "@mantine/core";
import { type GetInputPropsReturnType } from "node_modules/@mantine/form/lib/types";
import { type QueryParams } from "@/features/entity/api";
import { useDebouncedValue } from "@mantine/hooks";
import React, { useCallback, useState } from "react";
import {
  Lookup,
  OptionGroupRender,
  OptionRender,
} from "@/components/Lookup/Lookup";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { useSettingsContext } from "@/components/Layout/Contexts/Settings/useSettingsContext";

type UserGroupLookupFieldProps = GetInputPropsReturnType &
  MantineStyleProps & {
    isMovingHelpOnly?: boolean;
    label?: string;
    required?: boolean;
    initial?: Schemas["UserGroupRetrieveDto"] | null;
  };

export function UserGroupLookupField({
  isMovingHelpOnly = true,
  required = false,
  initial,
  ...props
}: UserGroupLookupFieldProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const { MovingHelpIds } = useSettingsContext();
  const [lookupValue, setLookupValue] = useState<string | null>(
    initial?.name ?? null,
  );
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const queryParams: QueryParams = {
    pageSize: 50,
    ...(isMovingHelpOnly
      ? {
          filter: MovingHelpIds?.length
            ? MovingHelpIds.map((id) => `id == ${id}`).join(" || ") // Combine conditions with 'or'
            : undefined,
        } // Handle empty MovingHelpIds
      : {}),
  };
  if (debouncedSearchTerm) {
    queryParams.searchBy = debouncedSearchTerm;
  }
  const { data, isFetching, fetchNextPage } = useInfiniteEntityListQuery<
    Schemas["UserGroupRetrieveDtoPagedList"]
  >({
    resourcePath: "/api/UserGroups",
    queryKey: "userGroups",
    params: queryParams,
    enabled: isDropdownOpen,
  });
  const loadMore = useCallback(() => {
    void fetchNextPage();
  }, [fetchNextPage]);

  const infiniteScrollRef = useInfiniteScroll(loadMore, !isFetching);
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.focusTarget();
      setSearchTerm("");
      setIsDropdownOpen(false);
    },
    onDropdownOpen: () => {
      combobox.focusTarget();
      setIsDropdownOpen(true);
    },
  });
  const entityList = data?.pages.map((page) => page?.data).flat() ?? [];
  const options: JSX.Element[] = [];
  const header = (
    <React.Fragment>
      <div>Name</div>
    </React.Fragment>
  );

  entityList.forEach((entity, index) => {
    if (entity) {
      const renderedOptions: JSX.Element[] = [];
      renderedOptions.push(
        OptionRender(entity.name, searchTerm, "Name_" + entity.id),
      );
      options.push(
        OptionGroupRender(
          entity.id!,
          index,
          entity.name!,
          entityList.length,
          infiniteScrollRef,
          renderedOptions,
        ),
      );
    }
  });

  const pageSize = data?.pages[0]?.pageSize ?? 0;
  const currentPage = data?.pages.length ?? 0;
  const currentFocus = currentPage === 1 ? 0 : pageSize * (currentPage - 1);

  return (
    <Lookup
      combobox={combobox}
      required={required}
      lookupValue={lookupValue}
      setLookupValue={setLookupValue}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      options={options}
      header={header}
      isFetching={isFetching}
      currentFocus={currentFocus}
      entity={"UserGroups"}
      entityId={initial?.id}
      {...props}
    />
  );
}
