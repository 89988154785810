import { Select } from "@mantine/core";
import useLanguage from "@/hooks/useLanguage";
import { type Locale } from "@/config/locale";
import { useEntityUpdateMutation } from "@/features/entity/mutations";
import { type Schemas } from "@/types";
import { useUserContext } from "../Contexts/User/useUserContext";

const languageOptions = [
  { label: "English", value: "English" },
  { label: "Dutch", value: "Dutch" },
];

export function UserLanguage() {
  const { language, changeLanguage } = useLanguage();
  const { finalUser } = useUserContext();
  const { mutate: update } = useEntityUpdateMutation<
    Schemas["AppUser"],
    Schemas["AppUserPatchDto"]
  >({
    resourcePath: "/api/AppUsers/{id}",
    resourceId: finalUser?.id ?? "",
    queryKey: "advisedProduct",
  });
  const handleSelectChange = (value: string | null) => {
    if (!value) return;
    changeLanguage(value as Locale);
    update(
      { language: value as Locale },
      {
        onSuccess: () => {
          console.log("Language updated");
        },
        onError: (error) => {
          console.error(error);
        },
      },
    );
  };

  return (
    <Select
      type="text"
      onChange={handleSelectChange}
      value={language}
      data={languageOptions}
    />
  );
}
