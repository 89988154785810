export function filterFalsyValues<T>(
  obj: Record<string, T | null>,
): Record<string, T> {
  const filteredObj: Record<string, T> = {};

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      obj[key] !== undefined
    ) {
      filteredObj[key] = obj[key] as T;
    }
  }

  return filteredObj;
}

export function filterEmptyStrings<T>(
  obj: Record<string, T | string>,
): Record<string, T> {
  const filteredObj: Record<string, T> = {};

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      (typeof obj[key] !== "string" || (obj[key] as string).trim() !== "")
    ) {
      filteredObj[key] = obj[key] as T;
    }
  }

  return filteredObj;
}

export function upperCaseNthLetter(string: string, index = 0) {
  if (index < 0 || index >= string.length) {
    // If index is out of range, return the original string
    return string;
  }
  return (
    string.slice(0, index) +
    string.charAt(index).toUpperCase() +
    string.slice(index + 1)
  );
}

export function lowerCaseNthLetter(string: string, index = 0) {
  if (!string) {
    return string;
  }
  if (index < 0 || index >= string.length) {
    // If index is out of range, return the original string
    return string;
  }
  return (
    string.slice(0, index) +
    string.charAt(index).toLowerCase() +
    string.slice(index + 1)
  );
}
