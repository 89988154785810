import { type AppState, Auth0Provider } from "@auth0/auth0-react";
import { type PropsWithChildren } from "react";
import { AuthTokenProvider } from "./authToken";

export function AuthProvider({ children }: PropsWithChildren) {
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: import.meta.env.VITE_AUTH0_REDIRECT_URI,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      }}
      cacheLocation="localstorage"
      onRedirectCallback={(appState?: AppState | undefined) => {
        window.history.replaceState(
          {},
          document.title,
          appState?.returnTo || window.location.pathname,
        );
      }}
    >
      <AuthTokenProvider>{children}</AuthTokenProvider>
    </Auth0Provider>
  );
}
