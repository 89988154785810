import { useState } from "react";
import { Box, Flex, ScrollArea } from "@mantine/core";
import { startOfMonth, endOfMonth } from "date-fns";
import {
  type Graph,
  type GraphReturn,
  type ResourcePath,
} from "../../HomeDashboard/HomeDashboard";
import React from "react";
import classes from "../../Home.module.css";
import { getCards } from "./getCards";

export interface CardProps {
  onClick?: () => void;
  className?: string;
  isActive?: boolean;
}
interface HomeCardsProps {
  setActiveGraphReturn: (value: GraphReturn) => void;
  setActiveGraph: (value: Graph) => void;
  setResourcePath: (value: ResourcePath) => void;
  setFilter: (value: string) => void;
  setTitle: (value: string) => void;
  graphMonth: Date;
}

const HomeCards: React.FC<HomeCardsProps> = ({
  setActiveGraphReturn,
  setActiveGraph,
  setResourcePath,
  setFilter,
  setTitle,
  graphMonth,
}) => {
  const firstDay = startOfMonth(graphMonth);
  const lastDay = endOfMonth(graphMonth);
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  const cards = getCards({
    firstDay,
    lastDay,
    setActiveGraphReturn,
    setActiveGraph,
    setResourcePath,
    setFilter,
    setTitle,
  });

  const handleCardClick = (index: number, originalOnClick?: () => void) => {
    setActiveCardIndex(index);
    if (originalOnClick) {
      originalOnClick();
    }
  };

  const wrappedCards = cards.map((card, index) =>
    React.cloneElement(card, {
      key: index,
      isActive: index === activeCardIndex,
      className:
        index === activeCardIndex ? classes.activeCard : classes.homeCard,
      onClick: () => handleCardClick(index, card.props.onClick),
    }),
  );

  return (
    <Flex>
      <ScrollArea.Autosize
        type="always"
        scrollbarSize={8}
        w="100%"
        classNames={classes}
      >
        <Flex w="20vw" mb={10}>
          {wrappedCards.map((card, index) => (
            <Box key={index} m={5}>
              {card}
            </Box>
          ))}
        </Flex>
      </ScrollArea.Autosize>
    </Flex>
  );
};

export default HomeCards;
