import { useEntityListQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";
import { Center, Loader } from "@mantine/core";
import { createContext, type ReactNode } from "react";

interface SettingsContextType {
  isLoading: boolean;
  settings?: Schemas["SettingRetrieveDto"];
  MovingHelpNorth?: Schemas["UserGroup"];
  MovingHelpWest?: Schemas["UserGroup"];
  MovingHelpSouth?: Schemas["UserGroup"];
  MovingHelpGroups?: Schemas["UserGroup"][];
  MovingHelpIds?: string[];
}

const defaultSettingsContext = {
  isLoading: false,
  settings: undefined,
  MovingHelpNorth: undefined,
  MovingHelpWest: undefined,
  MovingHelpSouth: undefined,
  MovingHelpGroups: undefined,
  MovingHelpIds: undefined,
};

export const SettingsContext = createContext<SettingsContextType>(
  defaultSettingsContext,
);

interface SettingsContextProviderProps {
  children: ReactNode;
}
export const SettingsContextProvider = ({
  children,
}: SettingsContextProviderProps) => {
  const { data, isLoading } = useEntityListQuery<
    Schemas["SettingRetrieveDtoPagedList"]
  >({
    resourcePath: "/api/Settings",
    queryKey: "settings",
    params: {
      filter: `name == "Unitlogic"`,
    },
  });

  if (isLoading) {
    return (
      <Center h="100%" w={"100%"}>
        <Loader />
      </Center>
    );
  }
  const settings = data?.data?.find((setting) => setting.name === "UnitLogic");
  const MovingHelpNorth = settings?.movingHelpNorth;
  const MovingHelpSouth = settings?.movingHelpSouth;
  const MovingHelpWest = settings?.movingHelpWest;
  const MovingHelpGroups = [];
  const MovingHelpIds = [];
  if (MovingHelpNorth) {
    MovingHelpGroups.push(MovingHelpNorth);
    MovingHelpIds.push(MovingHelpNorth.id!);
  }
  if (MovingHelpSouth) {
    MovingHelpGroups.push(MovingHelpSouth);
    MovingHelpIds.push(MovingHelpSouth.id!);
  }
  if (MovingHelpWest) {
    MovingHelpGroups.push(MovingHelpWest);
    MovingHelpIds.push(MovingHelpWest.id!);
  }

  return (
    <SettingsContext.Provider
      value={{
        isLoading,
        settings,
        MovingHelpNorth,
        MovingHelpWest,
        MovingHelpSouth,
        MovingHelpGroups,
        MovingHelpIds,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
