import {
  Box,
  Button,
  Fieldset,
  Flex,
  ScrollArea,
  Title,
  Tooltip,
} from "@mantine/core";
import { type ReactNode, type PropsWithChildren } from "react";
import { DeleteButton } from "../Elements/DeleteButton";
import { DeleteButtonList } from "../Elements/DeleteButtonList";
import { EditButton } from "../Elements/EditButton";
import { CreateButton } from "../Elements/CreateButton";
import { AssignRolesButton } from "../Elements/AssignRolesButton";
import { TableMantine } from "../TableMantine";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { SaveButton } from "../Elements/SaveButton/SaveButton";
import { SaveAndCloseButton } from "../Elements/SaveAndCloseButton/SaveAndCloseButton";
import { RefreshButton } from "../Elements/RefreshButton/RefreshButton";
import { OwnerHeader } from "../Elements/OwnerHeader/OwnerHeader";
import { WizardButton } from "../Elements/WizardButton/WizardButton";
import { useTranslation } from "react-i18next";
import { useIsModal } from "@/hooks/useModalContext";

interface EntityLayoutProps {
  title?: string;
  recordState?: string;
  disabled?: boolean;
  actionSection?: ReactNode;
  disabledActionSection?: ReactNode;
  headerSection?: ReactNode;
  showBackButton?: boolean;
  backRedirect?: string;
  stickyHeader?: boolean;
}

export function EntityLayout({
  title,
  recordState,
  children,
  actionSection,
  disabledActionSection,
  headerSection,
  disabled,
  showBackButton = true,
  backRedirect,
  stickyHeader = true,
}: PropsWithChildren<EntityLayoutProps>) {
  const { t } = useTranslation("features");
  const navigate = useNavigate();
  const { isModal, closeModal } = useIsModal();
  const HandleBack = () => {
    if (isModal && closeModal) {
      closeModal();
    } else if (backRedirect) {
      navigate(backRedirect);
    } else {
      navigate(-1);
    }
  };
  const stickyStyle = stickyHeader
    ? {
        position: "absolute" as const,
        top: "6vh",
        zIndex: 100,
        minWidth: "100%",
        backgroundColor: "#fafaff",
        borderBottom: "1px solid #e8e8e8",
      }
    : {};

  return (
    <Box>
      <Box
        style={{
          display: actionSection ? undefined : "none",
          ...stickyStyle,
        }}
      >
        <Flex
          align="center"
          direction="row"
          justify={"flex-start"}
          wrap={"nowrap"}
          h={40}
          mih={40}
          pt={stickyHeader ? 10 : 0}
          mb={stickyHeader ? 10 : 0}
          ml={stickyHeader ? 10 : 0}
          mah={40}
        >
          <Tooltip label={t("entity.goBack")}>
            <Button
              variant="subtle"
              mr={10}
              h={40}
              w={60}
              maw={60}
              miw={60}
              onClick={HandleBack}
              display={showBackButton ? undefined : "none"}
              style={{ pointerEvents: "auto" }}
            >
              <IconArrowLeft size={34} />
            </Button>
          </Tooltip>
          <ScrollArea type="always" h={40} mih={40} mah={40} scrollbarSize={6}>
            <Flex
              align="center"
              gap={"sm"}
              direction="row"
              justify={"flex-start"}
              wrap={"nowrap"}
            >
              {!disabled && (actionSection ?? null)}
              {disabled && (disabledActionSection ?? null)}
            </Flex>
          </ScrollArea>
        </Flex>
      </Box>
      <Box ml={10} mt={stickyHeader ? "6vh" : 0}>
        <Fieldset
          disabled={disabled}
          display={stickyHeader ? undefined : "none"}
        >
          <Flex
            align="center"
            direction="row"
            justify="flex-start"
            pb="xs"
            ml="xs"
          >
            <Flex
              flex={1}
              align="center"
              gap={"md"}
              direction="row"
              justify="flex-start"
            >
              <Title order={4}>{title}</Title>
              <Title order={6}>{recordState && <>{recordState}</>}</Title>
            </Flex>
            <Flex
              flex={1}
              align="center"
              gap={"md"}
              direction="row"
              justify="flex-end"
              mr={stickyHeader ? 40 : 0}
            >
              {headerSection ?? null}
            </Flex>
          </Flex>
        </Fieldset>
        <Fieldset disabled={disabled}>{children}</Fieldset>
      </Box>
    </Box>
  );
}
EntityLayout.OwnerHeader = OwnerHeader;
EntityLayout.DeleteButton = DeleteButton;
EntityLayout.DeleteButtonList = DeleteButtonList;
EntityLayout.EditButton = EditButton;
EntityLayout.CreateButton = CreateButton;
EntityLayout.SaveButton = SaveButton;
EntityLayout.WizardButton = WizardButton;
EntityLayout.SaveAndCloseButton = SaveAndCloseButton;
EntityLayout.RefreshButton = RefreshButton;
EntityLayout.AssignRolesButton = AssignRolesButton;
EntityLayout.TableMantine = TableMantine;
