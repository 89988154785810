import { useEntityListQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";

export default function UseAllRoles() {
  const {
    data: allRolesResponse,
    isLoading: allRolesLoading,
    refetch: refetchAllRoles,
  } = useEntityListQuery<Schemas["RoleDtoPagedList"]>({
    resourcePath: "/auth/roles",
    queryKey: "allRoles",
  });

  return {
    allRolesResponse,
    allRolesLoading,
    refetchAllRoles,
  };
}
