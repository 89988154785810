import { Button, Flex, Group } from "@mantine/core";
import {
  IconCalendar,
  IconFolderQuestion,
  IconListDetails,
  IconStar,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const actions = [
  {
    icon: IconStar,
    label: "commonActions.newLead",
    path: "/app/leads/create",
    newWindow: false,
    color: undefined,
  },
  {
    icon: IconCalendar,
    label: "commonActions.newAppointment",
    path: "/app/appointments",
    newWindow: false,
    color: undefined,
  },
  {
    icon: IconFolderQuestion,
    label: "commonActions.newCase",
    path: "/app/cases/create",
    newWindow: false,
    color: undefined,
  },
  {
    icon: IconListDetails,
    label: "commonActions.feedback",
    path: "https://feedback.unitlogic.nl/",
    newWindow: true,
    color: "yellow",
  },
];

export default function CommonActions() {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const handleClick = (path: string, newWindow: boolean) => {
    if (path) {
      if (newWindow) {
        window.open("https://feedback.unitlogic.nl/", "_blank");
      } else {
        navigate(path);
      }
    } else {
      console.log("Button clicked, but no path specified");
    }
  };
  return (
    <Flex w="100%" justify="flex-end" align="center" ml={20} mb={10}>
      <Group gap="xs" wrap="wrap">
        {actions.map(({ icon: Icon, label, path, newWindow, color }) => (
          <Button
            key={label}
            variant="subtle"
            color={color}
            onClick={() => {
              if (path) {
                handleClick(path, newWindow);
              }
            }}
            leftSection={<Icon size={16} />}
          >
            {t(label)}
          </Button>
        ))}
      </Group>
    </Flex>
  );
}
