import Axios, {
  type AxiosRequestConfig,
  type AxiosResponse,
  type AxiosError,
} from "axios";

export const api = Axios.create({
  baseURL: import.meta.env.VITE_PUBLIC_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export type ApiRequestConfig<D = unknown> = AxiosRequestConfig<D>;
export type ApiResponse<T = unknown, D = unknown> = AxiosResponse<T, D>;
export type ApiError<T = unknown, D = unknown> = AxiosError<T, D>;
