import { useState, useEffect } from "react";
import {
  getEndOfTheMonth,
  getStartOfTheMonth,
  getStartOfTheWeek,
  getEndOfTheWeek,
  getStartOfTheDay,
  getEndOfTheDay,
} from "@/utils/date";
import { type DatesRangeValue } from "@mantine/dates";

export function useDateRange(initialDateView = "month") {
  const [calendarView, setCalendarView] = useState<string>(initialDateView);
  const [isCustomRange, setIsCustomRange] = useState<boolean>(false);

  const calculateDateRange = (view: string, date: Date) => {
    switch (view) {
      case "month":
        return {
          startDate: getStartOfTheMonth(date),
          endDate: getEndOfTheMonth(date),
        };
      case "week":
        return {
          startDate: getStartOfTheWeek(date),
          endDate: getEndOfTheWeek(date),
        };
      case "day":
        return {
          startDate: getStartOfTheDay(date),
          endDate: getEndOfTheDay(date),
        };
      default:
        console.error("Invalid date view");
        return {
          startDate: getStartOfTheMonth(date),
          endDate: getEndOfTheMonth(date),
        };
    }
  };

  const today = new Date();

  const initialRange = calculateDateRange(calendarView, today);
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>(initialRange);

  useEffect(() => {
    if (!isCustomRange) {
      const newRange = calculateDateRange(calendarView, today);
      setDateRange(newRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarView]);

  const updateDateRange = (date: Date) => {
    if (!isCustomRange) {
      const newRange = calculateDateRange(calendarView, date);
      setDateRange(newRange);
    }
  };

  const setCustomDateRange = (range: DatesRangeValue) => {
    if (range[0] && range[1]) {
      setIsCustomRange(true);
      setDateRange({
        startDate: range[0].toISOString().split("T")[0]!,
        endDate: range[1].toISOString().split("T")[0]!,
      });
    }
  };

  return {
    dateRange,
    setCustomDateRange,
    setDateRange: updateDateRange,
    setCalendarView: (view: string) => {
      setIsCustomRange(view === "custom");
      setCalendarView(view);
    },
    calendarView,
  };
}
