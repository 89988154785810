import { Flex, FocusTrap, Modal, Paper, Tooltip } from "@mantine/core";
import { useState, type PropsWithChildren } from "react";
import "@/lib/i18n";
import { api } from "@/lib/api";
import { IconLogout } from "@tabler/icons-react";
import { useAuth0 } from "@auth0/auth0-react";

interface ErrorResponse {
  response: {
    status: number;
  };
}

export function ErrorProvider({ children }: PropsWithChildren) {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeError, setActiveError] = useState<ErrorResponse | null>(null);
  const [errorText, setErrorText] = useState("");
  const { logout } = useAuth0();
  const handleLogout = () => {
    void logout();
  };
  api.interceptors.response.use(
    (response) => response,
    (error: ErrorResponse) => {
      const status = error.response ? error.response.status : null;
      if (status === 403) {
        setActiveError(error);
        setErrorText(
          "You do not have enough permissions, to complete this action. Contact administrator for support",
        );
        setModalOpen(true);
        return Promise.reject(error);
      } else if (status === 401) {
        console.error("Unauthorized request. Redirecting to login...");
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    },
  );
  return (
    <>
      {!modalOpen && children}
      <Modal.Root
        size="xl"
        opened={modalOpen}
        centered
        closeOnClickOutside={false}
        transitionProps={{
          transition: "fade",
          duration: 600,
          timingFunction: "linear",
        }}
        onClose={() => {
          handleLogout();
        }}
      >
        <Modal.Overlay backgroundOpacity={0.55} blur={3} />

        <Modal.Content>
          <Flex m={4}>
            <FocusTrap.InitialFocus />
            <Tooltip label={"Logout"}>
              <Modal.CloseButton p={4} icon={<IconLogout />} autoFocus />
            </Tooltip>
          </Flex>
          <Modal.Body>
            <Flex justify={"center"} gap={"xl"}>
              <Paper>{errorText}</Paper>
            </Flex>
            <Flex justify={"center"} gap={"xl"}>
              <Paper>error code: {activeError?.response.status}</Paper>{" "}
            </Flex>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}
