import { useEntityListQuery } from "@/features/entity/queries";
import { type PathKeys, type Schemas } from "@/types";
import useUser from "../useUser";

export default function UseBusinessUnits() {
  const { finalUser } = useUser();
  const { data: buResponse, isLoading: isLoadingBusinessUnits } =
    useEntityListQuery<Schemas["BusinessUnitRetrieveDtoPagedList"]>({
      resourcePath: `/api/BusinessUnits` as PathKeys,
      queryKey: "BusinessUnit_" + finalUser?.id,
      params: {
        pageSize: 400,
        orderBy: "code",
        desc: false,
      },
    });

  const isLoading = isLoadingBusinessUnits;
  const appUserBusinessUnits: Schemas["BusinessUnitRetrieveDto"][] =
    buResponse?.data ?? [];

  const businessUnits = appUserBusinessUnits.map((businessUnit) => {
    return {
      label: `${businessUnit?.code ?? ""}`,
      value: businessUnit?.id ?? "",
      color: businessUnit?.color ?? "#6082B6",
    };
  });

  return {
    isLoading,
    businessUnits,
  };
}
