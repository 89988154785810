import {
  AppShell,
  Box,
  Center,
  Flex,
  Loader,
  ScrollArea,
  Stack,
} from "@mantine/core";
import UseNavBar from "@/hooks/useNavBar";
import { Select } from "@mantine/core";

import { renderNavBar } from "./utils";
import { useUserContext } from "../Contexts/User/useUserContext";
import { useLayoutVisibility } from "../Contexts/LayoutVisibility/LayoutVisibilityContext";

interface NavbarProps {
  onClickMobile: VoidFunction;
}

export function Navbar({ onClickMobile }: NavbarProps) {
  const { isLoading, menuLinks, options, currentAppId, updateUser } =
    UseNavBar();
  const { setActiveNavbar } = useLayoutVisibility();
  const { roles } = useUserContext();

  if (isLoading) {
    return (
      <AppShell.Navbar>
        <Center w={"100%"} h={"100%"}>
          <Loader />
        </Center>
      </AppShell.Navbar>
    );
  }
  return (
    <AppShell.Navbar>
      <Flex direction="column" justify="space-between" h={"100%"}>
        <ScrollArea w={"100%"} type="always" scrollbars="y">
          <Stack justify="flex-end" flex={1}>
            <Flex justify="center" align="center" direction="column" w={"90%"}>
              {renderNavBar(menuLinks, roles, onClickMobile)}
            </Flex>
            <Box flex={1} />
          </Stack>
        </ScrollArea>
        <Select
          allowDeselect={false}
          styles={{
            option: { justifyContent: "center", textAlign: "center" },
            input: { justifyContent: "center", textAlign: "center" },
            dropdown: { textAlign: "center" },
          }}
          data={options}
          defaultValue={currentAppId}
          onChange={(value: string | null) => {
            if (value) {
              updateUser(value);
              setActiveNavbar(
                options.find((o) => o.value === value)?.label ?? "",
              );
            }
          }}
        />
      </Flex>
    </AppShell.Navbar>
  );
}
