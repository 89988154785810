import { useState } from "react";
import { Modal } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { IconPlus } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { ModalProvider } from "@/hooks/useModalContext";
import ButtonMain from "../ButtonMain/ButtonMain";

interface CreateButtonProps<T> {
  tooltipLabel?: string;
  FormComponent?: React.ComponentType<T & { closeModal?: () => void }>;
  formProps?: Partial<T>;
  to?: string;
}

export function CreateButton<T>({
  FormComponent,
  formProps,
  to = "create",
}: CreateButtonProps<T>) {
  const { t } = useTranslation("features");
  const [opened, setOpened] = useState(false);
  const openModal = () => setOpened(true);
  const closeModal = () => setOpened(false);
  return (
    <>
      {FormComponent ? (
        <ButtonMain
          label={t("entity.createNew")}
          icon={<IconPlus size={18} />}
          onClick={openModal}
        />
      ) : (
        <Link to={to}>
          <ButtonMain
            label={t("entity.createNew")}
            icon={<IconPlus size={18} />}
          />
        </Link>
      )}
      {FormComponent && (
        <Modal
          opened={opened}
          onClose={closeModal}
          withCloseButton={false}
          size="auto"
          centered
        >
          <ModalProvider closeModal={closeModal}>
            <FormComponent
              {...(formProps as T)}
              closeModal={closeModal}
              usingModal={true}
            />
          </ModalProvider>
        </Modal>
      )}
    </>
  );
}
