import type React from "react";
import { Select } from "@mantine/core";
import { useEntityListQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";
interface OwnerChangeProps {
  onUserChange: (userId: string) => void;
  currentUser?: string;
}

const OwnerChange: React.FC<OwnerChangeProps> = ({
  onUserChange,
  currentUser,
}) => {
  const { data, isLoading } = useEntityListQuery<
    Schemas["AppUserRetrieveDtoPagedList"]
  >({ resourcePath: "/api/AppUsers", queryKey: "appUser" });

  if (isLoading) {
    return <></>;
  }

  const Users = data?.data ?? [];

  return (
    <Select
      value={currentUser ?? ""}
      data={Users?.map((user) => ({
        value: user.id ?? "",
        label: `${user.name}`,
      }))}
      onChange={(value) => value && onUserChange(value)}
      searchable
      styles={{
        input: {
          textAlign: "center",
        },
        option: {
          justifyContent: "center",
        },
        root: {
          textAlign: "center",
        },
      }}
    />
  );
};

export default OwnerChange;
