import { api } from "@/lib/api";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useEffect,
  type PropsWithChildren,
  useCallback,
  useState,
} from "react";

export function AuthTokenProvider({ children }: PropsWithChildren) {
  const {
    getAccessTokenSilently,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isTokenSet, setIsTokenSet] = useState(false);

  const setAuthToken = useCallback(async () => {
    if (!isAuthenticated || isTokenSet) return;

    try {
      const token = await getAccessTokenSilently();
      api.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      });
      setIsTokenSet(true);
    } catch (error) {
      console.error("Failed to set auth token:", error);
    }
  }, [getAccessTokenSilently, isAuthenticated, isTokenSet]);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        void setAuthToken();
      } else if (!isRedirecting) {
        setIsRedirecting(true);
        loginWithRedirect().catch((error) => {
          console.error("Failed to redirect to login:", error);
          setIsRedirecting(false);
        });
      }
    }
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    isRedirecting,
    setAuthToken,
  ]);

  if (isLoading || isRedirecting || (isAuthenticated && !isTokenSet)) {
    return <></>;
  }

  if (isAuthenticated && isTokenSet) {
    return <>{children}</>;
  }

  return <></>;
}
