import { Button, Loader, Modal, Tooltip } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { notifications } from "@mantine/notifications";
import { type Schemas } from "@/types";
import { type MutableRefObject, useEffect, useState } from "react";
import { useEntityPostMutation } from "@/features/entity/mutations";
import UseAllRoles from "@/hooks/useAllRoles";
import { type MRT_RowData, type MRT_TableInstance } from "mantine-react-table";
import { IconUsers } from "@tabler/icons-react";
interface AssignRolesButtonProps {
  table: MutableRefObject<MRT_TableInstance<MRT_RowData> | null>;
}
export function AssignRolesButton({ table }: AssignRolesButtonProps) {
  const { t } = useTranslation("features");
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [roles, setRoles] = useState<Schemas["RoleDto"][]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { allRolesResponse, allRolesLoading, refetchAllRoles } = UseAllRoles();

  useEffect(() => {
    if (showModal && selectedRows) {
      const auth0UserIds = [];
      for (const item of selectedRows) {
        if (item.original?.auth0UserId) {
          auth0UserIds.push(item.original.auth0UserId);
        }
      }
      setSelectedUsers(auth0UserIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    if (!allRolesResponse && !allRolesLoading) {
      void refetchAllRoles();
    }
  }, [allRolesResponse, allRolesLoading, refetchAllRoles]);

  useEffect(() => {
    if (allRolesResponse) {
      setRoles(allRolesResponse.data ?? []);
    }
  }, [allRolesResponse]);

  useEffect(() => {
    if (!showModal) {
      setSelectedRoles([]);
    }
  }, [showModal]);
  const selectedRows = table?.current?.getSelectedRowModel().rows ?? [];
  const handleRoleSelect = (roleId: string) => {
    setSelectedRoles([roleId]);
  };

  const { mutate: assignRoles } = useEntityPostMutation<
    Schemas["RoleDto"],
    Schemas["UserRequest"]
  >({
    resourcePath: `/auth/roles/${selectedRoles[0]}/users`,
    queryKey: `assignRoles_${selectedRoles[0]}`,
  });

  function HandleAssignRoles() {
    if (selectedRows) {
      assignRoles(
        { users: selectedUsers },
        {
          onSuccess: () => {
            toggleModal();
            notifications.show({
              color: "green",
              title: t("roles.successTitle"),
              message: t("roles.successMessage"),
            });
          },
        },
      );
    }
  }

  return (
    <>
      <Modal
        opened={showModal}
        onClose={toggleModal}
        title={t("appUsers.allRoles")}
        size="sm"
      >
        {allRolesLoading ? (
          <Loader />
        ) : (
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {roles.map((role) => (
              <li key={role.id}>
                <label>
                  <input
                    type="radio"
                    name="selectedRole"
                    checked={selectedRoles[0] === role.id}
                    onChange={() => handleRoleSelect(role.id ?? "")}
                  />
                  {role.name}
                </label>
              </li>
            ))}
          </ul>
        )}
        <Button
          onClick={() => {
            if (selectedRoles.length === 0) {
              notifications.show({
                color: "red",
                title: t("roles.error"),
                message: t("roles.noRolesSelected"),
              });
              return;
            }
            HandleAssignRoles();
          }}
        >
          {t("appUsers.confirm")}
        </Button>
      </Modal>
      <Tooltip label={t("appUsers.manageRoles")} withArrow>
        <Button
          onClick={() => {
            if (table?.current?.getSelectedRowModel().rows?.length === 0) {
              notifications.show({
                color: "red",
                title: t("appUsers.error"),
                message: t("appUsers.noRowsSelected"),
              });
              return;
            } else {
              toggleModal();
            }
          }}
          variant="subtle"
          styles={(theme) => ({
            root: {
              padding: 0,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: theme.colors.gray[1],
              },
            },
          })}
        >
          <IconUsers size={16} />
        </Button>
      </Tooltip>
    </>
  );
}
