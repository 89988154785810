import { useEntityUpdateMutation } from "@/features/entity/mutations";
import { useEntityQuery } from "@/features/entity/queries";
import { useEntityListQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";

interface AppUserProps {
  userId?: string;
}

export interface OCLeads {
  label: string;
  value: string;
}

export default function UseUser(
  { userId }: AppUserProps = { userId: undefined },
) {
  const { data: User, isLoading: userLoading } = useEntityQuery<
    Schemas["AppUserRetrieveDto"]
  >({
    resourcePath: "/api/AppUsers/{id}",
    resourceId: userId!,
    queryKey: "appUser",
  });

  const {
    data: loggedInUser,
    isLoading: loggedUserLoading,
    isFetching: loggedUserFetching,
  } = useEntityListQuery<Schemas["AppUserRetrieveDto"]>({
    resourcePath: "/api/AppUsers/WhoAmI",
    queryKey: "appUser_WhoAmI",
  });

  const { mutate } = useEntityUpdateMutation<
    Schemas["AppUserPatchDto"],
    Schemas["AppUserPatchDto"]
  >({
    resourcePath: "/api/AppUsers/{id}",
    resourceId: loggedInUser?.id ?? "",
    queryKey: "appUserOmnichannelUpdate",
  });

  const isLoading = userLoading || loggedUserLoading;
  const isFetching = loggedUserFetching;
  const name = User?.name ?? "";
  const finalUser = User ?? loggedInUser;
  const initials = name.charAt(0).toUpperCase();
  return {
    userId,
    isLoading,
    isFetching,
    finalUser,
    name,
    initials,
    mutate,
  };
}
