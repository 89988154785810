import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
  Calendar,
  type EventPropGetter,
  type ToolbarProps,
  momentLocalizer,
  type EventProps,
  type DateLocalizer,
  type Formats,
} from "react-big-calendar";
import classes from "./AppointmentCalendar.module.css";
import { type Schemas } from "@/types";
import { formatDateTimeForBackend } from "@/utils/date";
import moment from "moment";
import { useEffect, useState } from "react";
import { Box, Flex } from "@mantine/core";
import { AppointmentCreate } from "../routes/AppointmentCreate";
import { AppointmentShow } from "../routes/AppointmentShow";
import CustomToolbar from "./CustomToolbar";
import { AppointmentBuDrawer } from "./AppointmentBuDrawer";
import { useDisclosure } from "@mantine/hooks";
import { type DatesRangeValue } from "@mantine/dates";
import { AppointmentEvent } from "./events/AppointmentEvent";
import { useEntityListQuery } from "@/features/entity/queries";
import { useSettingsContext } from "@/components/Layout/Contexts/Settings/useSettingsContext";

import "./index.css";

const DnDCalendar = withDragAndDrop<Schemas["Appointment"]>(Calendar);

type AppointmentWithDates = Schemas["Appointment"] & {
  startDate: Date;
  endData: Date;
};

interface AppointmentCalendarProps {
  redirectTo?: string;
  dateRange: { startDate: string; endDate: string };
  setDateRange: (value: DatesRangeValue) => void;
  setCalendarView: (view: string) => void;
  calendarView: string;
  onDateChange?: (date: Date) => void;
  values?: string[];
  refreshForm?: () => void;
  disableDnD?: boolean;
  onDrop?: (
    range: { startDate: string; endDate: string },
    eventId: string,
  ) => void;
  onResize?: (
    range: { startDate: string; endDate: string },
    eventId: string,
  ) => void;
  leadId?: string;
  defaultBU?: string;
  fromView?: string;
}

function formatAppointments(
  appointments: Schemas["Appointment"][] = [],
): AppointmentWithDates[] {
  return appointments.map(
    (appointment) =>
      ({
        ...appointment,
        startDate: new Date(appointment.startDate!),
        endDate: new Date(appointment.endDate!),
      }) as unknown as AppointmentWithDates,
  );
}

export function AppointmentCalendar({
  onDateChange,
  onResize,
  refreshForm,
  disableDnD,
  onDrop,
  dateRange,
  setDateRange,
  leadId,
  setCalendarView,
  calendarView,
  values: initialValues = [],
  defaultBU,
  fromView = "calendar",
}: AppointmentCalendarProps) {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [showModalOpen, setShowModalOpen] = useState(false);
  const [appointmentId, setAppointmentId] = useState<string | null>(null);
  const [phantomEvent, setPhantomEvent] = useState<
    Schemas["Appointment"] | null
  >(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [opened, { open, close }] = useDisclosure(
    document.URL.includes("find-need")
      ? false
      : fromView == "calendar"
        ? true
        : false,
  );
  const { MovingHelpIds } = useSettingsContext();

  //set values as initialStoreValues if they exist in localStorage, else default to initialValues
  const localStorageString =
    leadId == null ? localStorage.getItem("selectedBusinessUnit") ?? "" : "";

  const initialStoreValues =
    localStorageString === "" || localStorageString === "[]"
      ? null
      : (JSON.parse(localStorageString) as string[]);

  const [values, setValues] = useState<string[]>(
    initialStoreValues ?? initialValues,
  );
  const [businessUnitNames, setBusinessUnitNames] = useState<string[]>([]);

  // Sync state changes with localStorage
  useEffect(() => {
    if (leadId == null) {
      localStorage.setItem("selectedBusinessUnit", JSON.stringify(values));
    }
  }, [values, leadId]);

  const [businessUnitTypes, setBusinessUnitTypes] = useState<string[]>([]);
  const [appointmentTypes, setAppointmentTypes] = useState<string[]>([]);

  const [appointmentSearch, setAppointmentSearch] = useState<string>("");
  moment.locale("en-gb", {
    week: {
      dow: 1,
    },
  });
  const localizer = momentLocalizer(moment);

  const shouldFetchAppointments =
    values.length > 1 || (values.length >= 1 && !values.includes(""));

  const MovingHelpMatches = values.filter((v) => MovingHelpIds?.includes(v));
  const MovingHelpTrigger = MovingHelpMatches.length > 0;
  const BusinessUnitMatches = values.filter(
    (v) => !MovingHelpIds?.includes(v) && v !== "",
  );
  const BusinessUnitTrigger = BusinessUnitMatches.length > 0;

  const mainFilter = `startDate >= ${dateRange.startDate} && endDate <= ${dateRange.endDate}`;

  const businessUnitFilter = ` ${BusinessUnitMatches.length > 0 ? "&&" : ""} ${BusinessUnitMatches.map(
    (v) => {
      return `businessUnitId == ${v}`;
    },
  ).join(" || ")}`;

  const movingHelpFilter = ` ${MovingHelpMatches.length > 0 ? (BusinessUnitMatches.length > 0 ? "||" : "&&") : ""} ${MovingHelpMatches.map(
    (v) => {
      return `assignedToId == ${v}`;
    },
  ).join(" || ")}`;

  const filterParam =
    mainFilter +
    (BusinessUnitTrigger ? businessUnitFilter : "") +
    (MovingHelpTrigger ? movingHelpFilter : "");
  const { data } = useEntityListQuery<
    Schemas["AppointmentRetrieveDtoPagedList"]
  >({
    resourcePath: "/api/Appointments",
    queryKey: `appointment`,
    params: {
      filter: filterParam,
      singlePage: true,
    },
    enabled: shouldFetchAppointments,
  });

  let appointments: Schemas["AppointmentRetrieveDto"][] = [];
  if (shouldFetchAppointments) {
    appointments = data?.data ?? [];
  }

  let allAppointments = appointments ?? [];

  allAppointments = appointments.filter((a) => {
    if (appointmentSearch.length === 0) {
      return true;
    }

    const search = appointmentSearch.toLowerCase();

    return (
      a.contact?.fullName?.toLowerCase().includes(search) ||
      a.businessUnit?.name?.toLowerCase().includes(search) ||
      a.businessUnit?.code?.toLowerCase().includes(search) ||
      a.description?.toLowerCase().includes(search) ||
      a.appointmentType?.toLowerCase().includes(search)
    );
  });

  if (appointmentTypes.length > 0) {
    allAppointments = allAppointments.filter((a) =>
      appointmentTypes.includes(a.appointmentType!),
    );
  }

  let businessUnitId = null;
  if (leadId != null) {
    businessUnitId = initialValues[0] ?? "";
  } else if (values.length === 1) {
    businessUnitId = values[0] ?? "";
  } else {
    businessUnitId = initialValues[0] ?? "";
  }

  const eventStyleGetter: EventPropGetter<Schemas["Appointment"]> = (event) => {
    let backgroundColorToUse = "#6082B6"; // Fallback background (blue) color
    let textColorToUse = "black"; // Fallback text color

    // Check for appointmentStatus and set color accordingly
    switch (event.appointmentStatus) {
      case "NoShow":
        backgroundColorToUse = "#F5F4F2"; // Color for 'NoShow'
        textColorToUse = "#A9A9A9"; // Text color for 'NoShow'
        break;
      case "Completed":
        backgroundColorToUse = "#C1E1C1"; // Color for 'Completed'
        textColorToUse = "#40826D"; // Text color for 'Completed'
        break;
      default:
        backgroundColorToUse =
          event.businessUnit?.color == "" ||
          event.businessUnit?.color == null ||
          event.businessUnit?.color == undefined
            ? backgroundColorToUse
            : event.businessUnit?.color; // Color for 'default'
        break;
    }

    switch (event.appointmentType) {
      case "BlockCalendar":
        backgroundColorToUse = "#e95a4a"; // Color for 'Blocked'
        textColorToUse = "#A42A04"; // Text color for 'Blocked'
        break;
    }

    const style = {
      backgroundColor: backgroundColorToUse,
      borderRadius: "0px",
      opacity: 1,
      color: textColorToUse,
      border: "0px",
    };
    return {
      style: style,
    };
  };

  const components = {
    toolbar: (props: ToolbarProps) =>
      fromView == "calendar" ? (
        <CustomToolbar
          {...props}
          openDrawer={open}
          closeDrawer={close}
          drawerIsOpen={opened}
          dateRange={dateRange}
          setDateRange={setDateRange}
          viewState={calendarView}
          setViewState={setCalendarView}
          businessUnitNames={businessUnitNames}
        />
      ) : (
        <></>
      ),
    event: (event: EventProps<Schemas["Appointment"]>) => {
      const data = event.event;
      return <AppointmentEvent event={data} calendarView={calendarView} />;
    },
  };

  interface DateRange {
    start: Date;
    end: Date;
  }

  const formats: Formats = {
    timeGutterFormat: "HH:mm",
    eventTimeRangeFormat: (
      { start, end }: DateRange,
      culture?: string,
      localizer?: DateLocalizer,
    ): string =>
      localizer
        ? `${localizer.format(start, "HH:mm", culture)} — ${localizer.format(
            end,
            "HH:mm",
            culture,
          )}`
        : `${start.toLocaleTimeString()} — ${end.toLocaleTimeString()}`,
    agendaTimeFormat: "HH:mm",
    dayHeaderFormat: "dddd, D MMMM YYYY",
    dayRangeHeaderFormat: (
      { start, end }: DateRange,
      culture?: string,
      localizer?: DateLocalizer,
    ): string =>
      localizer
        ? `${localizer.format(start, "D MMM", culture)} — ${localizer.format(
            end,
            "D MMM",
            culture,
          )}`
        : `${start.toLocaleDateString()} — ${end.toLocaleDateString()}`,
    dayFormat: "ddd D MMM",
    monthHeaderFormat: "MMMM YYYY",
  };

  const STEP = 15;
  const TIME_SLOTS = 30 / STEP;
  if (phantomEvent != null && allAppointments.indexOf(phantomEvent) === -1) {
    allAppointments = allAppointments.filter((a) => a.id !== "phantom");
    allAppointments.push(phantomEvent);
  }

  return (
    <Flex
      mt={document.location.pathname.includes("app/appointments") ? 0 : 10}
      h={
        document.location.pathname.includes("app/appointments")
          ? "100%"
          : "60vh"
      }
    >
      {createModalOpen && !showModalOpen && (
        <Box
          w={"25%"}
          miw={"25%"}
          maw={"25%"}
          key={`create-${startDate}-${endDate}`}
        >
          <AppointmentCreate
            refreshForm={refreshForm}
            closeModal={() => {
              setCreateModalOpen(false);
            }}
            setPhantomEvent={setPhantomEvent}
            leadId={leadId}
            businessUnitId={businessUnitId}
            startDate={startDate}
            endDate={endDate}
          />
        </Box>
      )}
      {showModalOpen && !createModalOpen && (
        <Box w={"25%"} miw={"25%"} maw={"25%"} key={`show-${appointmentId}`}>
          <AppointmentShow
            fromCalendar={true}
            refreshForm={refreshForm}
            closeModal={() => {
              setShowModalOpen(false);
            }}
            appointmentId={appointmentId}
          />
        </Box>
      )}
      <Box
        w="100%"
        h={
          document.location.pathname.includes("app/appointments")
            ? "90vh"
            : "65vh"
        }
        mih={
          document.location.pathname.includes("app/appointments")
            ? "90vh"
            : undefined
        }
      >
        <DnDCalendar
          style={{
            maxHeight: "100%",
            minHeight: document.location.pathname.includes("app/appointments")
              ? "100%"
              : undefined,
          }}
          draggableAccessor={() => (disableDnD ? false : true)}
          scrollToTime={new Date(Date.now())}
          formats={formats}
          defaultView={fromView == "calendar" ? "week" : "agenda"}
          events={formatAppointments(allAppointments)}
          startAccessor="startDate"
          endAccessor="endDate"
          views={{
            week: true,
            month: true,
            day: true,
            agenda: true,
          }}
          localizer={localizer}
          className={classes.calendar}
          selectable={fromView == "calendar" ? true : false}
          eventPropGetter={eventStyleGetter}
          components={components}
          timeslots={TIME_SLOTS}
          popup
          doShowMoreDrillDown={false}
          onEventDrop={({ start, end, event }) => {
            if (!onDrop) return;
            if (fromView == "agenda") return;
            onDrop(
              {
                startDate: formatDateTimeForBackend(new Date(start)),
                endDate: formatDateTimeForBackend(new Date(end)),
              },
              event.id!,
            );
          }}
          onEventResize={({ start, end, event }) => {
            if (!onResize) return;
            if (fromView == "agenda") return;
            const endDateToUse = new Date(end);
            if (start.toString() == end.toString()) {
              endDateToUse.setMinutes(endDateToUse.getMinutes() + 15);
            }
            onResize(
              {
                startDate: formatDateTimeForBackend(new Date(start)),
                endDate: formatDateTimeForBackend(new Date(endDateToUse)),
              },
              event.id!,
            );
          }}
          onSelectEvent={(event) => {
            if (event.id === "phantom") return;
            if (fromView == "agenda") return;
            setAppointmentId(event.id!);
            setPhantomEvent(null);
            setShowModalOpen(true);
            setCreateModalOpen(false);
          }}
          onNavigate={onDateChange}
          onSelectSlot={(slotInfo) => {
            if (fromView == "agenda") return;
            setEndDate(slotInfo.end.toString());
            setStartDate(slotInfo.start.toString());
            setPhantomEvent({
              id: "phantom",
              startDate: slotInfo.start.toString(),
              endDate: slotInfo.end.toString(),
            } as Schemas["Appointment"]);
            setCreateModalOpen(true);
            setShowModalOpen(false);
          }}
        />
      </Box>

      <Box
        ml={10}
        w="16vw"
        miw={"16vw"}
        maw={"16vw"}
        style={{
          visibility: opened ? "visible" : "hidden",
          position: opened ? "relative" : "absolute",
          zIndex: opened ? 10 : -1,
        }}
      >
        <AppointmentBuDrawer
          values={values}
          setValues={setValues}
          businessUnitNames={businessUnitNames}
          setBusinessUnitNames={setBusinessUnitNames}
          defaultBU={defaultBU ?? ""}
          setAppointmentTypes={setAppointmentTypes}
          appointmentTypes={appointmentTypes}
          setAppointmentSearch={setAppointmentSearch}
          appointmentSearch={appointmentSearch}
          businessUnitTypes={businessUnitTypes}
          setBusinessUnitTypes={setBusinessUnitTypes}
          leadId={leadId}
        />
      </Box>
    </Flex>
  );
}
