import { Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { modals } from "@mantine/modals";
import { type ReactNode } from "react";
import ButtonMain from "../ButtonMain/ButtonMain";

interface DeleteButtonProps {
  onClick: VoidFunction;
  modalTitle?: string;
  modalContent?: ReactNode;
  confirmLabel?: string;
  deleteLabel?: string;
  cancelLabel?: string;
}

export function DeleteButton({
  onClick,
  modalTitle,
  modalContent,
  confirmLabel,
  cancelLabel,
}: DeleteButtonProps) {
  const { t } = useTranslation("features");
  const openDeleteModal = () => {
    modals.openConfirmModal({
      title: modalTitle ?? t("entity.deleteEntity"),
      centered: true,
      children: (
        <Text size="sm">
          {modalContent ?? t("entity.deleteEntityConfirmation")}
        </Text>
      ),
      labels: {
        confirm: confirmLabel ?? t("entity.deleteEntity"),
        cancel: cancelLabel ?? t("entity.cancel"),
      },
      confirmProps: { color: "red" },
      onConfirm: onClick,
    });
  };

  return (
    <ButtonMain
      color="red"
      label={t("entity.delete")}
      icon={<IconTrash size={18} />}
      type="submit"
      onClick={openDeleteModal}
    />
  );
}
