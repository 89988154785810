import { createContext, type ReactNode, useContext } from "react";

const ModalContext = createContext<{
  isModal: boolean;
  closeModal?: () => void;
}>({ isModal: false });

// eslint-disable-next-line react-refresh/only-export-components
export const useIsModal = () => useContext(ModalContext);

interface ModalProviderProps {
  children: ReactNode;
  closeModal?: () => void;
}

export const ModalProvider = ({ children, closeModal }: ModalProviderProps) => {
  return (
    <ModalContext.Provider value={{ isModal: true, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};
