import { Avatar, Group } from "@mantine/core";
import OwnerChange from "./OwnerChange";
import UseUser from "@/hooks/useUser";
interface OwnerHeaderProps {
  ownerId?: string;
  setOwner: (ownerId: string) => void;
}

export function OwnerHeader({ ownerId, setOwner }: OwnerHeaderProps) {
  const { isLoading, initials } = UseUser({ userId: ownerId });

  if (isLoading) {
    return <></>;
  }

  const handleUserChange = (userId: string) => {
    setOwner(userId);
  };

  return (
    <Group>
      <OwnerChange currentUser={ownerId} onUserChange={handleUserChange} />
      <Avatar color="cyan" radius="xl">
        {initials}
      </Avatar>
    </Group>
  );
}
