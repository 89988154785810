import { useState } from "react";
import { Box, Flex, useMantineTheme } from "@mantine/core";
import { type Schemas } from "@/types";
import HomeCards from "../components/cards/HomeCards";
import CommonActions from "./CommonActions";
import MainGraph from "../components/graphs/MainGraph";
import { useMediaQuery } from "@mantine/hooks";

export type Graph =
  | "AppointmentRetrieveDto"
  | "LeadRetrieveDto"
  | "ReservationRetrieveDto"
  | "CaseRetrieveDto";
export type GraphReturn =
  | "AppointmentRetrieveDtoPagedList"
  | "LeadRetrieveDtoPagedList"
  | "ReservationRetrieveDtoPagedList"
  | "CaseRetrieveDtoPagedList";
export type ResourcePath =
  | "/api/Appointments"
  | "/api/Leads"
  | "/api/Reservations"
  | "/api/Cases";

interface DashboardState {
  activeGraph: Graph;
  activeGraphReturn: GraphReturn;
  resourcePath: ResourcePath;
  graphMonth: Date;
  filter: string;
  title: string;
}

export default function HomeDashboard() {
  const [state, setState] = useState<DashboardState>({
    activeGraph: "AppointmentRetrieveDto",
    activeGraphReturn: "AppointmentRetrieveDtoPagedList",
    resourcePath: "/api/Appointments",
    graphMonth: new Date(),
    filter: "",
    title: "Appointments Created",
  });
  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const updateState = (newState: Partial<DashboardState>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  return (
    <Box h="100%">
      <Flex direction="column">
        {!isTablet && <DashboardHeader />}
        <HomeCards
          setActiveGraph={(graph: Graph) => updateState({ activeGraph: graph })}
          setActiveGraphReturn={(graphReturn: GraphReturn) =>
            updateState({ activeGraphReturn: graphReturn })
          }
          setResourcePath={(path: ResourcePath) =>
            updateState({ resourcePath: path })
          }
          setFilter={(filter: string) => updateState({ filter })}
          setTitle={(title: string) => updateState({ title })}
          graphMonth={state.graphMonth}
        />
        <Box ml={10} mt={5}>
          <MainGraph<
            Schemas[typeof state.activeGraph],
            Schemas[typeof state.activeGraphReturn]
          >
            resourcePath={state.resourcePath}
            queryKey={`${state.resourcePath}_GraphList${state.graphMonth.toISOString()}`}
            filter={state.filter}
            title={state.title}
            graphMonth={state.graphMonth}
            setGraphMonth={(month: Date) => updateState({ graphMonth: month })}
          />
        </Box>
      </Flex>
    </Box>
  );
}

function DashboardHeader() {
  const theme = useMantineTheme();
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  return (
    <Flex w="100%" justify={"flex-start"} direction={"row"}>
      {!isTablet && <CommonActions />}
    </Flex>
  );
}
