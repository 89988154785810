import { useRef, useEffect, useState } from "react";
import {
  Button,
  Flex,
  Title,
  Stack,
  Modal,
  TextInput,
  ScrollArea,
  Divider,
  Text,
  Affix,
  Input,
  em,
  Paper,
} from "@mantine/core";
import { IconArrowBackUp, IconRefresh } from "@tabler/icons-react";
import { useLayoutVisibility } from "@/components/Layout/Contexts/LayoutVisibility/LayoutVisibilityContext";
import { modals } from "@mantine/modals";
import classes from "./Wizard.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { AppointmentsTab } from "@/features/leads/components/Tabs/AppointmentsTab";

interface Functionality {
  operationType: "input" | "autocomplete";
  fieldName: string;
  fieldType?: string;
  fieldValue: string;
}

interface Question {
  question: string;
  answer: string;
  functionality: Functionality | null;
  answers: Question[];
  leaf?: boolean;
  element?: JSX.Element;
  review?: boolean;
}

export default function Wizard() {
  const [searchParams] = useSearchParams();
  const leadId = searchParams.get("leadId");
  const businessUnitId = searchParams.get("businessUnitId");
  const autoCompleteQuestion = {
    question: "",
    answer: "Review Answers",
    functionality: null,
    answers: [],
    review: true,
  };

  const data: Question = {
    question:
      "I saw that you were looking for a parking space in (place), is it for a car?",
    answer: "",
    functionality: null,
    answers: [
      {
        question: "How long is the caravan/ camper?",
        answer: "No, it is for a caravan/ camper",
        functionality: null,
        answers: [
          {
            question: "This unfortunately not work.",
            answer:
              "If bigger than the pre-checked available spots, usually 7 meters",
            functionality: {
              operationType: "autocomplete",
              fieldName: "discard",
              fieldValue: "Product/Service not available",
            },
            answers: [],
          },
          {
            question:
              "You can rent a parking space from us in our secure outdoor parking lot, which you can access 24/7. We offer these starting from 90 euros per month. Is that what you were looking for?",
            answer:
              "If smaller than the pre-checked available spots, usually 7 meters",
            functionality: null,
            answers: [
              {
                question:
                  "Furthermore, we unfortunately do not have any facilities for the caravan/ camper",
                answer: "No",
                functionality: {
                  operationType: "autocomplete",
                  fieldName: "discard",
                  fieldValue: "Product/Service not available",
                },
                answers: [],
              },
              {
                question:
                  "Please come by the relevant location. We'll explain how the parking lot works and show it to you. When do you need this?",
                answer: "Yes",
                functionality: null,
                answers: [
                  {
                    question: "When would it be convenient to come by?",
                    answer: "As soon as possible",
                    functionality: null,
                    answers: [
                      {
                        question: "",
                        answer: "Activity",
                        functionality: null,
                        leaf: true,
                        element: (
                          <AppointmentsTab
                            leadId={leadId!}
                            businessUnitId={businessUnitId!}
                          />
                        ),
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Return call apointment",
                        functionality: null,
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Discard Reason",
                        functionality: null,
                        answers: [],
                      },
                    ],
                  },
                  {
                    question:
                      "Then you are well ahead of schedule. Come by soon. When would be the best time for you in the upcoming weeks?",
                    answer: "In 1-6 months",
                    functionality: null,
                    answers: [
                      {
                        question: "",
                        answer: "Activity",
                        functionality: null,
                        leaf: true,
                        element: (
                          <AppointmentsTab
                            leadId={leadId!}
                            businessUnitId={businessUnitId!}
                          />
                        ),
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Return call apointment",
                        functionality: null,
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Discard Reason",
                        functionality: null,
                        answers: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        question: "How long is the boat (with trailer)?",
        answer: "No, for a boat storage.",
        functionality: null,
        answers: [
          {
            question: "This unfortunately not work.",
            answer:
              "If bigger than the pre-checked available spots, usually 7 meters",
            functionality: {
              operationType: "autocomplete",
              fieldName: "discard",
              fieldValue: "Product/Service not available",
            },
            answers: [],
          },
          {
            question:
              "You can rent a parking space from us in our secure outdoor parking lot, which you can access 24/7. We offer these starting from 90 euros per month. Is that what you were looking for?",
            answer:
              "If smaller than the pre-checked available spots, usually 7 meters",
            functionality: null,
            answers: [
              {
                question:
                  "Furthermore, we unfortunately do not have any facilities for the boat",
                answer: "No",
                functionality: {
                  operationType: "autocomplete",
                  fieldName: "discard",
                  fieldValue: "Product/Service not available",
                },
                answers: [],
              },
              {
                question:
                  "Please come by the relevant location. We'll explain how the parking lot works and show it to you. When do you need this?",
                answer: "Yes",
                functionality: null,
                answers: [
                  {
                    question: "When would it be convenient to come by?",
                    answer: "As soon as possible",
                    functionality: null,
                    answers: [
                      {
                        question: "",
                        answer: "Activity",
                        functionality: null,
                        leaf: true,
                        element: (
                          <AppointmentsTab
                            leadId={leadId!}
                            businessUnitId={businessUnitId!}
                          />
                        ),
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Return call apointment",
                        functionality: null,
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Discard Reason",
                        functionality: null,
                        answers: [],
                      },
                    ],
                  },
                  {
                    question:
                      "Then you are well ahead of schedule. Come by soon. When would be the best time for you in the upcoming weeks?",
                    answer: "In 1-6 months",
                    functionality: null,
                    answers: [
                      {
                        question: "",
                        answer: "Activity",
                        functionality: null,
                        leaf: true,
                        element: (
                          <AppointmentsTab
                            leadId={leadId!}
                            businessUnitId={businessUnitId!}
                          />
                        ),
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Return call apointment",
                        functionality: null,
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Discard Reason",
                        functionality: null,
                        answers: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        question: "Did you notice that those are outdoor spaces?",
        answer: "Yes, it is for a vintage car/ convertible/ second car",
        functionality: null,
        answers: [
          {
            question:
              "You can rent a parking space with us in our secure outdoor parking lot, which you have access to 24/7. We offer these starting from 90 euros per month and they accommodate vehicles up to a maximum of 7 meters. Is that what you were looking for?",
            answer: "Yes, I want to suspend my car registration etc.",
            functionality: null,
            answers: [
              {
                question: "Discard reason",
                answer: "No",
                functionality: {
                  operationType: "autocomplete",
                  fieldName: "discard",
                  fieldValue: "Product/Service not available",
                },
                answers: [],
              },
              {
                question:
                  "Please come by the relevant location. We'll explain how the parking lot works and show it to you. When do you need this?",
                answer: "Yes",
                functionality: null,
                answers: [
                  {
                    question: "When would it be convenient to come by?",
                    answer: "As soon as possible",
                    functionality: null,
                    answers: [
                      {
                        question: "",
                        answer: "Activity",
                        functionality: null,
                        leaf: true,
                        element: (
                          <AppointmentsTab
                            leadId={leadId!}
                            businessUnitId={businessUnitId!}
                          />
                        ),
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Return call apointment",
                        functionality: null,
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Discard Reason",
                        functionality: null,
                        answers: [],
                      },
                    ],
                  },
                  {
                    question:
                      "Then you are well ahead of schedule. Come by soon. When would be the best time for you in the upcoming weeks?",
                    answer: "In 1-6 months",
                    functionality: null,
                    answers: [
                      {
                        question: "",
                        answer: "Activity",
                        functionality: null,
                        leaf: true,
                        element: (
                          <AppointmentsTab
                            leadId={leadId!}
                            businessUnitId={businessUnitId!}
                          />
                        ),
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Return call apointment",
                        functionality: null,
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Discard Reason",
                        functionality: null,
                        answers: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            question:
              "We offer engine rooms at four different locations in the Netherlands (AMOAK, AMOBZ, AMOOG, AMOWE). These are indoor parking spaces, primarily intended for exclusive cars. Is this an option for you?",
            answer: "No, my car must be covered.",
            functionality: null,
            answers: [
              {
                question: " Discard reason",
                answer: "NaN Discard",
                functionality: {
                  operationType: "autocomplete",
                  fieldName: "discard",
                  fieldValue: "Product/Service not available",
                },
                answers: [],
              },
              {
                question:
                  "Please come by the relevant location as soon as possible. We'll explain how the engine rooms work and show them to you. When do you need this",
                answer: "NaN Continue",
                functionality: null,
                answers: [
                  {
                    question: "When would it be convenient to come by?",
                    answer: "As soon as possible",
                    functionality: null,
                    answers: [
                      {
                        question: "",
                        answer: "Activity",
                        functionality: null,
                        leaf: true,
                        element: (
                          <AppointmentsTab
                            leadId={leadId!}
                            businessUnitId={businessUnitId!}
                          />
                        ),
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Return call apointment",
                        functionality: null,
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Discard Reason",
                        functionality: null,
                        answers: [],
                      },
                    ],
                  },
                  {
                    question:
                      "Then you are well ahead of schedule. Come by soon. When would be the best time for you in the upcoming weeks?",
                    answer: "In 1-6 months",
                    functionality: null,
                    answers: [
                      {
                        question: "",
                        answer: "Activity",
                        functionality: null,
                        leaf: true,
                        element: (
                          <AppointmentsTab
                            leadId={leadId!}
                            businessUnitId={businessUnitId!}
                          />
                        ),
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Return call apointment",
                        functionality: null,
                        answers: [],
                      },
                      {
                        question: "",
                        answer: "Discard Reason",
                        functionality: null,
                        answers: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  const { setVisibility } = useLayoutVisibility();
  const [currentQuestion, setCurrentQuestion] = useState<Question>(data);
  const [questionHistory, setQuestionHistory] = useState<Question[]>([]);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [quizFinishedModalOpen, setQuizFinishedModalOpen] = useState(false);
  const [modalInput, setModalInput] = useState("");
  const [activeFunctionality, setActiveFunctionality] =
    useState<Functionality | null>(null);
  const [activeQuestion, setActiveQuestion] = useState<Question>(data);
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const historyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setVisibility({
      isHeaderVisible: false,
      isNavbarVisible: false,
      isOmnichannelVisible: false,
    });
    return () =>
      setVisibility({
        isHeaderVisible: true,
        isNavbarVisible: true,
        isOmnichannelVisible: false,
      });
  }, [setVisibility]);

  useEffect(() => {
    if (historyRef.current && questionHistory.length > 0) {
      // Scroll to the rightmost (latest) element inside the container
      const scrollContainer = historyRef.current;
      scrollContainer.scrollLeft = scrollContainer.scrollWidth;
    }
  }, [questionHistory]); // Dependency array includes questionHistory

  const handleAnswerClick = (nextQuestion: Question) => {
    const existingIndex = questionHistory.findIndex(
      (historyEntry) => historyEntry.question === currentQuestion.question,
    );
    if (existingIndex !== -1) {
      modals.openConfirmModal({
        title: "Warning",
        centered: true,
        children: (
          <Text size="sm">
            You have already answered this question. Are you sure you want to
            proceed? Continueing will overwrite your previous answer and
            subsequential entries.
          </Text>
        ),
        labels: {
          confirm: "I Understand",
          cancel: "Return",
        },
        confirmProps: { color: "red" },
        onConfirm: () => {
          if (
            nextQuestion.functionality &&
            nextQuestion.functionality.operationType !== "autocomplete"
          ) {
            setModalOpen(true);
            setActiveFunctionality(nextQuestion.functionality);
            setActiveQuestion(nextQuestion);
          } else {
            const updatedQuestion = {
              ...currentQuestion,
              answer: `[${nextQuestion.answer}]`,
            };

            setQuestionHistory((prevHistory) => [
              ...prevHistory.slice(0, existingIndex),
              updatedQuestion,
            ]);

            setCurrentQuestion(nextQuestion);

            if (
              nextQuestion.functionality &&
              nextQuestion.functionality.operationType === "autocomplete"
            ) {
              const updatedQuestion = {
                ...autoCompleteQuestion,
                functionality: nextQuestion.functionality,
              };
              setQuestionHistory((prevHistory) => [
                ...prevHistory,
                updatedQuestion,
              ]);
            }
          }
        },
      });
    } else {
      if (
        nextQuestion.functionality &&
        nextQuestion.functionality.operationType !== "autocomplete"
      ) {
        setModalOpen(true);
        setActiveFunctionality(nextQuestion.functionality);
        setActiveQuestion(nextQuestion);
      } else {
        setCurrentQuestion(nextQuestion);
        const updatedQuestion = {
          ...currentQuestion,
          answer: `[${nextQuestion.answer}]`,
        };
        setQuestionHistory((prevHistory) => [...prevHistory, updatedQuestion]);

        if (
          nextQuestion.functionality &&
          nextQuestion.functionality.operationType === "autocomplete"
        ) {
          const updatedQuestion = {
            ...autoCompleteQuestion,
            functionality: nextQuestion.functionality,
          };
          setQuestionHistory((prevHistory) => [
            ...prevHistory,
            updatedQuestion,
          ]);
        }
      }
    }
  };

  const handleModalConfirm = () => {
    const existingIndex = questionHistory.findIndex(
      (historyEntry) => historyEntry.question === currentQuestion.question,
    );
    if (existingIndex !== -1) {
      const updatedQuestion = {
        ...currentQuestion,
        answer: `[${activeQuestion.answer}][${modalInput}]`,
      };

      setQuestionHistory((prevHistory) => [
        ...prevHistory.slice(0, existingIndex),
        updatedQuestion,
      ]);

      setCurrentQuestion(activeQuestion);
      setModalOpen(false);
      setModalInput("");
    } else {
      const updatedQuestion = {
        ...currentQuestion,
        answer: `[${activeQuestion.answer}][${modalInput}]`,
      };
      setQuestionHistory((prevHistory) => [...prevHistory, updatedQuestion]);
      setCurrentQuestion(activeQuestion);
      setModalOpen(false);
      setModalInput("");
    }
  };

  const handleQuizReview = () => {
    setQuizFinishedModalOpen(true);
    const patch = questionHistory
      .map((question) => {
        // Check if the question has functionality
        if (question.functionality) {
          // Return a new object representing the patch operation
          return {
            operation: "patch",
            fieldName: question.functionality.fieldName,
            fieldValue: question.functionality.fieldValue,
          };
        }
        // Return null for questions without functionality
        return null;
      })
      .filter((item) => item !== null); // Filter out the null values to keep only the patch objects

    console.log(patch);
  };
  const formatAnswer = (text: string, maxLineLength = isMobile ? 30 : 45) => {
    const result = [];
    let line = "";

    text.split(" ").forEach((word) => {
      if (line.length + word.length + 1 > maxLineLength) {
        result.push(line);
        line = word;
      } else {
        if (line.length > 0) {
          line += " ";
        }
        line += word;
      }
    });

    if (line) {
      result.push(line);
    }

    return result.join("\n");
  };

  const handleHistoryClick = (index: number) => {
    if (index === 0) {
      setCurrentQuestion(data);
    } else {
      const selectedQuestion = questionHistory[index];
      setCurrentQuestion(selectedQuestion!);
      setModalOpen(false);
    }
  };

  const constructLeaf = (data: Question) => (
    <Paper mt={60}>{data.element}</Paper>
  );

  const constructQuestion = (data: Question) => (
    <>
      <Flex
        justify="center"
        align="center"
        direction="column"
        mt={"6vh"}
        mb={"4vh"}
        mr={isMobile ? "0" : "20vw"}
        ml={isMobile ? "0" : "20vw"}
        style={{ textAlign: "center" }}
      >
        <Title size={isMobile ? "h4" : "h2"}>{data.question}</Title>
      </Flex>
      <Divider mb={"1vh"} />
      <ScrollArea
        h={isMobile ? "40vh" : "60vh"}
        mb={"2vh"}
        type="always"
        scrollbarSize={12}
        scrollbars="y"
        classNames={classes}
      >
        {data.leaf === null ||
          data.leaf === false ||
          (data.leaf === undefined && (
            <Stack align="center" justify="flex-start" gap="sm">
              {data.answers.map((answer, index) => {
                const isInHistory = questionHistory.some(
                  (historyEntry) =>
                    historyEntry.question === data.question &&
                    historyEntry.answer.includes(answer.answer),
                );
                const formattedAnswer = formatAnswer(answer.answer)
                  .split("\n")
                  .map((line, idx) => (
                    <React.Fragment key={idx}>
                      {line}
                      <br />
                    </React.Fragment>
                  ));
                return (
                  <Button
                    key={index}
                    w={isMobile ? "80vw" : "30vw"}
                    h="fit-content"
                    mih={"80"}
                    mt={10}
                    onClick={() => handleAnswerClick(answer)}
                    className={isInHistory ? classes.answerInHistory : ""}
                  >
                    <Text fw={700} size={isMobile ? "xs" : "l"}>
                      {formattedAnswer}
                    </Text>
                  </Button>
                );
              })}
            </Stack>
          ))}
      </ScrollArea>
    </>
  );

  const constructData = (data: Question) => (
    <Paper>{data.leaf ? constructLeaf(data) : constructQuestion(data)}</Paper>
  );

  const displayHistory = () => (
    <Affix
      position={{ bottom: 0, left: 10, right: 10 }}
      display={modalOpen || quizFinishedModalOpen ? "none" : ""}
    >
      <ScrollArea
        viewportRef={historyRef}
        h={isMobile ? "8vh" : "8vh"}
        type="always"
        w={isMobile ? "94vw" : "99vw"}
        scrollbarSize={12}
        classNames={classes}
        scrollbars="x"
      >
        <Flex
          mah={"8vh"}
          mt={24}
          justify="flex-start"
          align="center"
          gap="lg"
          direction="row"
        >
          {questionHistory.map((entry, index) => (
            <Button
              key={index}
              onClick={() =>
                entry.review ? handleQuizReview() : handleHistoryClick(index)
              }
              className={entry.review ? classes.reviewHistoryButton : ""}
              style={{ cursor: "pointer" }}
            >
              {entry.review ? "Review Answers" : `Question #${index + 1}`}
            </Button>
          ))}
        </Flex>
      </ScrollArea>{" "}
    </Affix>
  );
  const modalContent = () => {
    if (!activeFunctionality) return null;
    switch (activeFunctionality.operationType) {
      case "input":
        return (
          <TextInput
            label={activeFunctionality.fieldName}
            type={activeFunctionality.fieldType}
            placeholder="Type here"
            value={modalInput}
            onChange={(event) => setModalInput(event.currentTarget.value)}
          />
        );
      case "autocomplete":
        {
          if (modalInput === "") {
            setModalInput(activeFunctionality.fieldValue);
          }
        }
        break;
      default:
        return null;
    }
    return null;
  };
  const quizFinishedModalContent = () => {
    return questionHistory.map((entry, index) => {
      if (entry.functionality) {
        return (
          <>
            <Text>
              {entry.functionality.operationType === "autocomplete"
                ? "Automatic Action - " + entry.functionality.fieldName
                : entry.functionality.fieldName}
            </Text>
            <Input
              key={index}
              readOnly={entry.functionality.operationType === "autocomplete"}
              value={entry.functionality.fieldValue}
            ></Input>
          </>
        );
      }
      return null;
    });
  };
  return (
    <div>
      <Affix position={{ top: 10, left: 10, right: 10 }}>
        <Button
          onClick={() => {
            modals.open({
              centered: true,
              children: (
                <>
                  <Flex
                    gap="md"
                    justify="center"
                    align="center"
                    direction="row"
                  >
                    <Text size="sm">
                      Are you sure you want to return to the Leads page? All
                      progress will be lost
                    </Text>
                  </Flex>
                  <Flex
                    gap="md"
                    justify="center"
                    align="center"
                    direction="row"
                  >
                    <Button
                      w={"45%"}
                      onClick={() => {
                        modals.closeAll();
                      }}
                      variant="outline"
                      mt="md"
                    >
                      No
                    </Button>
                    <Button
                      w={"45%"}
                      onClick={() => {
                        navigate("/app/leads");
                        modals.closeAll();
                      }}
                      variant="outline"
                      mt="md"
                    >
                      Yes
                    </Button>
                  </Flex>
                </>
              ),
            });
          }}
        >
          <IconArrowBackUp />
        </Button>
      </Affix>
      <Affix position={{ top: 10, right: 10 }}>
        <Button
          onClick={() => {
            modals.open({
              centered: true,
              children: (
                <>
                  <Flex
                    gap="md"
                    justify="center"
                    align="center"
                    direction="row"
                  >
                    <Text size="sm">
                      Are you sure you want to reset the entire quiz ?
                    </Text>
                  </Flex>
                  <Flex
                    gap="md"
                    justify="center"
                    align="center"
                    direction="row"
                  >
                    <Button
                      w={"45%"}
                      onClick={() => {
                        modals.closeAll();
                      }}
                      variant="outline"
                      mt="md"
                    >
                      No
                    </Button>
                    <Button
                      w={"45%"}
                      onClick={() => {
                        window.location.reload();
                      }}
                      variant="outline"
                      mt="md"
                    >
                      Yes
                    </Button>
                  </Flex>
                </>
              ),
            });
          }}
        >
          <IconRefresh />
        </Button>
      </Affix>
      {constructData(currentQuestion)}
      <Modal
        opened={modalOpen}
        closeOnEscape={false}
        closeOnClickOutside={false}
        onClose={() => setModalOpen(false)}
        centered
        title={activeFunctionality?.fieldName ?? "Details"}
      >
        {modalContent()}
        <Button mt="md" onClick={handleModalConfirm}>
          Confirm
        </Button>
      </Modal>
      <Modal
        opened={quizFinishedModalOpen}
        closeOnEscape={false}
        closeOnClickOutside={false}
        onClose={() => setQuizFinishedModalOpen(false)}
        centered
        title={"Review Your Answers"}
      >
        {quizFinishedModalContent()}
        <Button w={"100%"} mt="md" onClick={() => alert("hey")}>
          Finish Quiz
        </Button>
      </Modal>
      {displayHistory()}
    </div>
  );
}
