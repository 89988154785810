import { useState, useEffect } from "react";

export function useTimeLeft(date: Date | null): string {
  const [timeLeft, setTimeLeft] = useState<string>("");

  useEffect(() => {
    if (!date) {
      setTimeLeft("");
      return;
    }

    const updateTimeLeft = () => {
      const now = new Date();
      const difference = date.getTime() - now.getTime();

      if (difference <= 0) {
        setTimeLeft("");
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      let result = "";
      if (days > 0) result += `${days}d `;
      if (hours > 0 || days > 0) result += `${hours}h `;
      if (minutes > 0 || hours > 0 || days > 0) result += `${minutes}m `;
      result += `${seconds}s`;

      setTimeLeft(result.trim());
    };

    updateTimeLeft();
    const timerId = setInterval(updateTimeLeft, 1000); // Update every second

    return () => clearInterval(timerId);
  }, [date]);

  return timeLeft;
}
