import {
  QueryClient,
  type QueryClientConfig,
  type UseMutationOptions,
  type UseQueryOptions,
} from "react-query";
import { type AxiosError } from "axios";
import { type Promisable } from "type-fest";

const queryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
};

export const queryClient = new QueryClient(queryConfig);

export type ExtractFnReturnType<
  FnType extends (...args: unknown[]) => unknown,
> = Promisable<ReturnType<FnType>>;

export type QueryConfig<QueryFnType extends (...args: unknown[]) => unknown> =
  Omit<
    UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
    "queryKey" | "queryFn"
  >;

export type MutationConfig<
  MutationFnType extends (...args: unknown[]) => unknown,
> = UseMutationOptions<
  ExtractFnReturnType<MutationFnType>,
  AxiosError,
  Parameters<MutationFnType>[0]
>;
