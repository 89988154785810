import { IconCloudFog } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ButtonMain from "../ButtonMain/ButtonMain";
interface SaveAndCloseButtonProps {
  setClose: (value: boolean) => void;
}
export function SaveAndCloseButton({ setClose }: SaveAndCloseButtonProps) {
  const { t } = useTranslation("features");

  return (
    <ButtonMain
      label={t("entity.saveAndClose")}
      icon={<IconCloudFog size={18} />}
      onClick={() => setClose(true)}
      type="submit"
    />
  );
}
