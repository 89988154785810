import { Center, Grid, Loader, em, useMantineTheme } from "@mantine/core";
import MainDashboard from "./HomeDashboard/HomeDashboard";
import DashboardAgenda from "./components/agenda/HomeAgenda";
import { useMediaQuery } from "@mantine/hooks";
import { useUserContext } from "../Layout/Contexts/User/useUserContext";

export default function Home() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const { finalUser, isLoading: isLoadingUser } = useUserContext();
  if (isLoadingUser) {
    return (
      <Center mt={40}>
        <Loader />
      </Center>
    );
  }
  return (
    <Grid>
      <Grid.Col span={isMobile ? 12 : isTablet ? 12 : 10}>
        <MainDashboard />
      </Grid.Col>
      {!isTablet && (
        <Grid.Col span={isMobile ? 12 : 2}>
          <DashboardAgenda currentUser={finalUser ?? null} />
        </Grid.Col>
      )}
    </Grid>
  );
}
