import { NavLink } from "@mantine/core";
import { type ReactNode } from "react";
import { Link, useLocation, useResolvedPath } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface NavbarLinkProps {
  leftSection?: ReactNode;
  label: string;
  to: string;
  disabled?: boolean;
  onClickMobile?: VoidFunction;
}

export function NavbarLink({
  leftSection,
  label,
  to,
  onClickMobile,
  disabled = false,
}: NavbarLinkProps) {
  const { t } = useTranslation("common");
  const resolvedPath = useResolvedPath(to);
  const location = useLocation();
  const isActive = location.pathname.startsWith(resolvedPath.pathname);

  return (
    <NavLink
      ml={disabled ? 0 : 14}
      active={disabled ? false : isActive}
      component={Link}
      to={to}
      label={t(label)}
      leftSection={leftSection}
      disabled={disabled}
      onClick={onClickMobile}
    />
  );
}
