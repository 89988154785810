import { Button, Flex } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCloud } from "@tabler/icons-react";
import { type MRT_RowData, type MRT_TableInstance } from "mantine-react-table";
import { type MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
interface WizardButtonButtonProps {
  table?: MutableRefObject<MRT_TableInstance<MRT_RowData> | null>;
}
export function WizardButton({ table }: WizardButtonButtonProps) {
  const { t } = useTranslation("features");
  const isMobile = useMediaQuery(`(max-width: 724px)`);
  // For future use
  //const isTablet = useMediaQuery(`(max-width: 986px)`);
  return (
    <Button
      type="button"
      variant="outline"
      onClick={() => {
        if (table !== null) {
          const rows = table?.current?.getSelectedRowModel().rows;
          if (rows?.length === 0) {
            alert("Please select at least one row to proceed.");
          }
        }
      }}
      leftSection={isMobile ? "" : <IconCloud size={22} />}
      size={isMobile ? "compact-md" : undefined}
    >
      <Flex>
        {isMobile ? <IconCloud size={18} /> : ""}
        {isMobile ? "" : t("entity.wizard")}
      </Flex>
    </Button>
  );
}
