import { z } from "zod";
import { createFormContext } from "@mantine/form";
import {
  Discount,
  ProcessStage,
  ProductType,
  SizeOfUnit,
  RentAsBusiness,
  StorageDuration,
  StorageUnitReason,
  Transportation,
  LeadType,
  Salutation,
  StartWithin,
  LeadSource,
  PreferredLanguage,
  OptInType,
} from "@/types/enums";
import * as validator from "validator";
import i18next from "i18next";

export const leadFormSchema = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    email: z
      .string()
      .min(1)
      .refine(
        (value) => {
          if (value) {
            return validator.isEmail(value);
          } else {
            return true;
          }
        },
        { message: i18next.t("common:validation.invalidEmail") },
      ),
    mobile: z
      .string()
      .min(1)
      .refine(
        (value) => {
          if (value) {
            return validator.isMobilePhone(value);
          } else {
            return true;
          }
        },
        { message: i18next.t("common:validation.invalidPhone") },
      ),
    phone: z.string().refine(
      (value) => {
        if (value) {
          return validator.isMobilePhone(value);
        } else {
          return true;
        }
      },
      { message: i18next.t("common:validation.invalidPhone") },
    ),
    salutation: z.enum(Salutation as [string]).nullable(),
    companyName: z.string(),
    discountText: z.string(),
    webformTitle: z.string(),
    webformDetails: z.string(),
    businessUnitId: z.string().min(1),
    businessUnit: z.object({}).nullable(),
    storageValue: z.coerce.number().nullable(),
    spaceTourRemarks: z.string(),
    callCount: z.number().nullable(),
    callCountReached: z.number().nullable(),
    callCountNotReached: z.number().nullable(),
    quotationEmailSent: z.boolean().default(false),
    moveInDate: z.date().nullable(),
    nextCallback: z.date().nullable(),
    reservationStart: z.date().nullable(),
    reservedUntil: z.date().nullable(),
    rentAsBusiness: z.enum(RentAsBusiness as [string]).nullable(),
    transportation: z.enum(Transportation as [string]).nullable(),
    discount: z.enum(Discount as [string]).nullable(),
    storageDuration: z.enum(StorageDuration as [string]).nullable(),
    storageUnitReason: z.enum(StorageUnitReason as [string]).nullable(),
    processStage: z.enum(ProcessStage as [string]),
    type: z.enum(LeadType as [string]),
    startWithin: z.enum(StartWithin as [string]).nullable(),
    productType: z.enum(ProductType as [string]).nullable(),
    sizeOfUnit: z.enum(SizeOfUnit as [string]).nullable(),
    optOutPhone: z.boolean().default(false),
    description: z.string(),
    existingContactId: z.string().nullable(),
    existingContact: z.object({}).nullable(),
    existingCustomerId: z.string().nullable(),
    existingCustomer: z.object({}).nullable(),
    contractId: z.string().nullable(),
    contract: z.object({}).nullable(),
    recordState: z.string(),
    comments: z.string(),
    promotionCode: z.string(),
    fax: z.string(),
    city: z.string(),
    zip: z.string(),
    street: z.string(),
    countryId: z.string().nullable(),
    country: z.object({}).nullable(),
    approvalForAddressUsage: z.boolean().default(false),
    storageTypeId: z.string().nullable(),
    storageType: z.object({}).nullable(),
    leadSource: z.enum(LeadSource as [string]).nullable(),
    preferredLanguage: z.enum(PreferredLanguage as [string]).nullable(),
    optInType: z.enum(OptInType as [string]).nullable(),
    step: z.string().nullable(),
    price: z.string().nullable(),
    unitSize: z.string().nullable(),
    volume: z.coerce.number().nullable(),
    amount: z.coerce.number().nullable(),
  })
  .refine((data) => (data.firstName && data.lastName) || data.companyName, {
    message: i18next.t("common:validation.requiredLeadName"),
    path: ["firstName"],
  })
  .refine((data) => (data.firstName && data.lastName) || data.companyName, {
    message: i18next.t("common:validation.requiredLeadName"),
    path: ["lastName"],
  })
  .refine((data) => (data.firstName && data.lastName) || data.companyName, {
    message: i18next.t("common:validation.requiredLeadName"),
    path: ["companyName"],
  });

export type LeadFormSchema = z.infer<typeof leadFormSchema>;

export const [LeadFormProvider, useLeadFormContext, useLeadForm] =
  createFormContext<LeadFormSchema>();
