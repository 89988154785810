import {
  Box,
  Flex,
  Text,
  Paper,
  Title,
  useMantineTheme,
  Loader,
} from "@mantine/core";
import { AreaChart } from "@mantine/charts";
import { useGraphData } from "./dataControllers";
import { type PathKeys } from "@/types";
import { type QueryKey } from "react-query";
import { IconChartLine } from "@tabler/icons-react";
import { CustomMonthPickerInput } from "./CustomMonthPickerInput";

interface MainGraphProps {
  graphMonth: Date;
  setGraphMonth: (date: Date) => void;
  resourcePath: PathKeys;
  queryKey: QueryKey;
  filter: string;
  title: string;
}

interface BasicEntity {
  id?: string;
}

interface BasicResponse<TData> {
  data?: TData[] | null;
  totalPages?: number;
  totalCount?: number;
}

export default function MainGraph<
  TData extends BasicEntity,
  TResponse extends BasicResponse<TData>,
>({
  resourcePath,
  queryKey,
  graphMonth,
  filter,
  title,
  setGraphMonth,
}: MainGraphProps) {
  const theme = useMantineTheme();
  const { data, isLoading, isFetching } = useGraphData<TData, TResponse>({
    resourcePath,
    queryKey,
    graphMonth,
    filter,
  });

  const totalCount = data.reduce((sum, item) => sum + item.Count, 0);
  return (
    <Paper shadow="sm" radius="md" p="md" withBorder>
      <Flex direction="column" gap="md">
        <Flex justify="space-between" align="center">
          <Flex align="center" gap="xs">
            <IconChartLine size={24} color={theme.colors.blue[6]} />
            <Title order={3}>{title}</Title>
          </Flex>
          <CustomMonthPickerInput value={graphMonth} onChange={setGraphMonth} />
        </Flex>
        <Text size="lg" fw={700} c="dimmed">
          Total: {totalCount}
        </Text>
        <Box h={300}>
          {isLoading || isFetching ? (
            <Flex justify="center" align="center" h="100%">
              <Loader />
            </Flex>
          ) : (
            <AreaChart
              h="100%"
              data={data}
              dataKey="date"
              series={[{ name: "Count", color: theme.colors.blue[6] }]}
              curveType="bump"
              withLegend
              withTooltip
              withYAxis
              tickLine="y"
            />
          )}
        </Box>
      </Flex>
    </Paper>
  );
}
