import { forwardRef, useMemo } from "react";
import { IconChevronRight } from "@tabler/icons-react";
import { Group, Text, UnstyledButton } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

interface UserButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  name?: string | undefined;
  email?: string | undefined;
  icon?: React.ReactNode;
}

export const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ name, email, icon, className, ...others }: UserButtonProps, ref) => {
    const isMobile = useMediaQuery("(max-width: 724px)");
    const memoizedOthers = useMemo(
      () => ({ ...Object.assign(others, className ? { className } : {}) }),
      [className, others],
    );

    return (
      <UnstyledButton
        ref={ref}
        style={{
          color: "var(--mantine-color-white)",
          borderRadius: "var(--mantine-radius-sm)",
        }}
        {...memoizedOthers}
      >
        <Group>
          <div style={{ flex: 1, display: isMobile ? "none" : undefined }}>
            <Text size="sm" fw={500}>
              {name}
            </Text>

            <Text c="white" opacity={0.6} size="xs">
              {email}
            </Text>
          </div>

          {icon ?? <IconChevronRight size={isMobile ? "2rem" : "1rem"} />}
        </Group>
      </UnstyledButton>
    );
  },
);

UserButton.displayName = "UserButton";
