import { useDownloadFile } from "./useDownloadFile";
import { Button } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { type AxiosRequestConfig } from "axios";
import { api } from "@/lib/api";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";

interface DownloadFileProps {
  id: string;
  fileName: string;
}

export function DownloadFile({ id, fileName }: DownloadFileProps) {
  const { t } = useTranslation("features");
  const onErrorDownloadFile = (error: string) => {
    notifications.show({
      color: "red",
      title: t("emails.error"),
      message: error,
    });
  };

  const getFileName = () => {
    return fileName;
  };

  const downloadFile = () => {
    const config = {
      responseType: "blob",
      headers: {
        "Content-Type": "application/octet-stream",
      },
    } as AxiosRequestConfig;

    const fileUrl = "/api/Attachments/DownloadAttachmment/" + id;
    return api.get(fileUrl, config);
  };

  const { ref, download } = useDownloadFile({
    apiDefinition: downloadFile,
    preDownloading: () => void 0,
    postDownloading: () => void 0,
    onError: onErrorDownloadFile,
    getFileName,
  });

  return (
    <>
      <a ref={ref} className="hidden" />
      <Button
        variant="light"
        leftSection={<IconDownload size={16} />}
        size="xs"
        onClick={download}
      >
        {t("attachments.downloadLabel")}
      </Button>
    </>
  );
}
