import { Card, Text, Center, Progress, Flex, Loader } from "@mantine/core";
import { useEntityListQuery } from "@/features/entity/queries";
import { type PathKeys } from "@/types";
import { type QueryKey } from "react-query";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import UseUser from "@/hooks/useUser";

interface BasicEntity {
  id?: string;
  recordState?: "Active" | "Inactive";
  startDate?: string | null | undefined;
}

interface BasicResponse<TData> {
  data?: TData[] | null;
  totalPages?: number;
  totalCount?: number;
}

interface HomeCardProps<TData extends BasicEntity> {
  title?: string;
  resourcePath: PathKeys;
  queryKey: QueryKey;
  isCompleted?: (item: TData) => boolean;
  filter?: string;
  goal?: number;
  filterDates: { start: Date; end: Date };
  onClick?: () => void;
  className?: string;
  inverted?: boolean;
}

export default function HomeCard<
  TData extends BasicEntity,
  TResponse extends BasicResponse<TData>,
>({
  title,
  resourcePath,
  queryKey,
  filterDates,
  isCompleted,
  className,
  filter,
  goal,
  inverted = false,
  onClick,
}: HomeCardProps<TData>) {
  const { finalUser } = UseUser();
  const fullFilter = `${finalUser?.businessUnitId ? `businessUnit.Id == ${finalUser?.businessUnitId} &&` : ""} createdOn >= "${format(filterDates.start, "yyyy-MM-dd'T'HH:mm:ss")}" && createdOn <= "${format(filterDates.end, "yyyy-MM-dd'T'HH:mm:ss")}" ${filter ? `&& ${filter}` : ""}`;
  const {
    data: pagedData,
    isLoading,
    isFetching,
  } = useEntityListQuery<TResponse>({
    resourcePath,
    queryKey,
    params: {
      filter: fullFilter,
      singlePage: true,
    },
  });
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isLoading || isFetching) {
      interval = setInterval(() => {
        setLoadingProgress((prevProgress) => {
          if (prevProgress >= 100) {
            return 0;
          }
          return prevProgress + 1; // Adjust the increment as needed
        });
      }, 50); // Adjust the interval delay as needed
    } else {
      setLoadingProgress(0); // Reset when not loading
    }

    return () => {
      clearInterval(interval);
    };
  }, [isLoading, isFetching]);

  if (isLoading || isFetching) {
    return (
      <Card
        w="50%"
        maw={180}
        miw={160}
        radius="lg"
        withBorder
        onClick={onClick}
        className={className}
      >
        <Card.Section p={8}>
          <Text ta={"center"} size="xs" fw={700}>
            {title?.toUpperCase().split(" ")[0] ?? ""}
          </Text>
          <Text ta="center" size="xs" fw={700}>
            {title?.toUpperCase().split(" ").slice(1).join(" ") ?? ""}
          </Text>
        </Card.Section>
        <Center>
          <Progress w="100%" value={loadingProgress} size="md" radius="xl" />
        </Center>
        <Flex justify="center" align="center" direction="column" mt="xs">
          <Text ta={"center"} size="md" fw={700}></Text>
        </Flex>
        <Center mt={8}>
          <Loader />
        </Center>
      </Card>
    );
  }

  const data = pagedData?.data ?? [];
  const count = data.length;
  let completed = null;
  if (isCompleted) {
    completed = data.filter(isCompleted).length;
  }
  let completionPercentage = 0;
  if (goal !== undefined && goal > 0) {
    completionPercentage = (count / goal) * 100;
  } else if (count > 0 && completed !== null) {
    completionPercentage = (completed / count) * 100;
  }
  const color = inverted
    ? completionPercentage < 40
      ? "green"
      : completionPercentage < 70
        ? "yellow"
        : "red"
    : completionPercentage < 40
      ? "red"
      : completionPercentage < 70
        ? "yellow"
        : "green";

  let footerContent = null;
  if (goal !== undefined && goal > 0) {
    footerContent = (
      <Flex align="center">
        <Text size="md" c={color}>{`${count}/${goal}`}</Text>
      </Flex>
    );
  } else if (count > 0) {
    footerContent = (
      <Flex align="center">
        <Text size="md" c={color}>{`${completed}/${count}`}</Text>
      </Flex>
    );
  } else {
    footerContent = <Text>No data available</Text>;
  }

  return (
    <Card
      w="50%"
      maw={180}
      miw={160}
      radius="lg"
      withBorder
      onClick={onClick}
      className={className}
    >
      <Card.Section p={8}>
        <Text ta={"center"} size="xs" fw={700}>
          {title?.toUpperCase().split(" ")[0] ?? ""}
        </Text>
        <Text ta="center" size="xs" fw={700}>
          {title?.toUpperCase().split(" ").slice(1).join(" ") ?? ""}
        </Text>
      </Card.Section>
      <Center>
        <Progress
          w="100%"
          value={completionPercentage}
          color={color}
          size="md"
          radius="xl"
        />
      </Center>
      <Flex justify="center" align="center" direction="column" mt="xs">
        <Text ta={"center"} c={color} size="md" fw={700}>
          {`${completionPercentage.toFixed(0)}%`}
        </Text>
      </Flex>
      <Center>{footerContent}</Center>
    </Card>
  );
}
