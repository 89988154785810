import {
  IconError404,
  IconCalendar,
  IconClockHour8,
  IconBuilding,
  IconFileCheck,
  IconChecklist,
  IconUsers,
  IconBox,
  IconHelp,
  IconAdjustmentsAlt,
  IconTemplate,
  IconUserCog,
  IconShieldLock,
  IconBuildingWarehouse,
  IconUserQuestion,
  IconUser,
  IconTag,
  IconHomeDollar,
  IconDimensions,
  IconHome,
  IconFlag,
  IconCategory,
  IconReceiptRefund,
  IconFileEuro,
  IconAffiliate,
  IconBookmark,
  IconBookmarks,
  IconMessageQuestion,
  IconProgressAlert,
  IconProgressHelp,
  IconMailbox,
  IconChartBar,
  IconUsersGroup,
  IconBrandReason,
} from "@tabler/icons-react";
import React from "react";
import { NavbarLink } from "../NavbarLink";

const menuIcons: Record<string, JSX.Element> = {
  "navbar.userGroups": <IconUsersGroup />,
  "navbar.calendar": <IconCalendar />,
  "navbar.omnichannel": <IconAffiliate />,
  "navbar.home": <IconHome />,
  "navbar.dashboards": <IconChartBar />,
  "navbar.leads": <IconUserQuestion />,
  "navbar.contacts": <IconUser />,
  "navbar.customers": <IconUsers />,
  "navbar.reservations": <IconClockHour8 />,
  "navbar.advisedUnits": <IconHomeDollar />,
  "navbar.contracts": <IconFileCheck />,
  "navbar.contractLines": <IconChecklist />,
  "navbar.products": <IconBox />,
  "navbar.businessUnits": <IconBuilding />,
  "navbar.units": <IconBuildingWarehouse />,
  "navbar.unitTypes": <IconDimensions />,
  "navbar.cases": <IconHelp />,
  "navbar.appUsers": <IconUserCog />,
  "navbar.roles": <IconShieldLock />,
  "navbar.settings": <IconAdjustmentsAlt />,
  "navbar.htmlTemplates": <IconTemplate />,
  "navbar.contactRoles": <IconUserCog />,
  "navbar.tags": <IconTag />,
  "navbar.countries": <IconFlag />,
  "navbar.storageTypes": <IconCategory />,
  "navbar.originCategories": <IconBookmarks />,
  "navbar.origins": <IconBookmark />,
  "navbar.complaintReasons": <IconProgressAlert />,
  "navbar.caseReasons": <IconProgressHelp />,
  "navbar.caseComments": <IconMessageQuestion />,
  "navbar.refunds": <IconReceiptRefund />,
  "navbar.refundProducts": <IconBox />,
  "navbar.quotes": <IconFileEuro />,
  "navbar.smsMessages": <IconFileEuro />,
  "navbar.mailboxes": <IconMailbox />,
  "navbar.lossReasons": <IconBrandReason />,
};

export function getMenuIconBySlug(slug: string) {
  const icon = slug ? menuIcons[slug] ?? <IconError404 /> : <IconError404 />;
  return React.cloneElement(icon);
}

export interface MenuLink {
  slug: string;
  url?: string;
  menuLinks: MenuLink[];
}

export const renderNavBar = (
  menuLinks: MenuLink[],
  roles: string[],
  onClickMobile: VoidFunction,
) => {
  const navBar: JSX.Element[] = [];
  for (const menuLink of menuLinks) {
    if (menuLink.menuLinks && menuLink.menuLinks.length > 0) {
      navBar.push(
        <NavbarLink
          key={menuLink.slug}
          to={""}
          label={menuLink.slug}
          disabled
        />,
      );
      // If it's an array, recursively process it
      navBar.push(...renderNavBar(menuLink.menuLinks, roles, onClickMobile));
    } else {
      navBar.push(
        <NavbarLink
          key={menuLink.slug}
          to={menuLink.url ?? ""}
          label={menuLink.slug}
          leftSection={getMenuIconBySlug(menuLink.slug)}
          onClickMobile={onClickMobile}
        />,
      );
      if (menuLink.slug === "navbar.appUsers") {
        navBar.push(
          <NavbarLink
            key={"navbar.userGroups"}
            to={"/app/usergroups"}
            label={"navbar.userGroups"}
            leftSection={getMenuIconBySlug("navbar.userGroups")}
            onClickMobile={onClickMobile}
          />,
        );
      }
      if (menuLink.slug === "navbar.leads" && roles.includes("Sales")) {
        navBar.push(
          <NavbarLink
            key={"navbar.omnichannel"}
            to={"/app/omnichannel"}
            label={"navbar.omnichannel"}
            leftSection={getMenuIconBySlug("navbar.omnichannel")}
            onClickMobile={onClickMobile}
          />,
        );
      }
    }
  }
  return navBar;
};
