import { Button, Flex, Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { modals } from "@mantine/modals";
import {
  useEffect,
  useState,
  type MutableRefObject,
  type ReactNode,
} from "react";
import {
  type MRT_Row,
  type MRT_RowData,
  type MRT_TableInstance,
} from "mantine-react-table";
import { deleteEntity } from "@/features/entity/api";
import { type PathKeys } from "@/types";
import { useQueryClient } from "react-query";
import { notifications } from "@mantine/notifications";
import { type ApiError } from "@/lib/api";
import { type SetProgressSections } from "@/hooks/useListCommands";

interface DeleteButtonListProps {
  modalTitle?: string;
  modalContent?: ReactNode;
  confirmLabel?: string;
  deleteLabel?: string;
  cancelLabel?: string;
  table?: MutableRefObject<MRT_TableInstance<MRT_RowData> | null>;
  entityPath?: string;
  queryKey?: string;
  setConfirmed?: (value: boolean) => void;
  setProgressPercentage?: (value: number) => void;
  setProgressSections?: SetProgressSections;
}

interface BasicEntity extends MRT_RowData {
  id?: string;
}
const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export function DeleteButtonList({
  modalTitle,
  modalContent,
  confirmLabel,
  cancelLabel,
  table,
  entityPath,
  queryKey,
  setConfirmed,
  setProgressPercentage,
  setProgressSections,
}: DeleteButtonListProps) {
  const { t } = useTranslation("features");
  const [progressCount, setProgressCount] = useState(0);

  const queryCache = useQueryClient();
  const handleDelete = async (
    row: MRT_Row<MRT_RowData>,
    iterativeStep: number,
  ) => {
    if (row.original) {
      const data = (row?.original ?? null) as BasicEntity | null;
      await deleteEntity({
        resourcePath: `/api/${entityPath ?? ""}/${data?.id}` as PathKeys,
        resourceId: data?.id ?? "",
      })
        .then(() => {
          setProgressCount((prev) => prev + iterativeStep);
          if (setProgressSections) {
            setProgressSections((prev) => {
              const updatedSections = [...prev];
              const tealSection = updatedSections.find(
                (section) => section.color === "teal",
              );

              if (tealSection) {
                tealSection.value += iterativeStep;
              } else {
                updatedSections.push({
                  value: iterativeStep,
                  color: "teal",
                });
              }

              return updatedSections;
            });
          }
        })
        .catch((error: ApiError<{ title?: string; detail?: string }>) => {
          setProgressCount((prev) => prev + iterativeStep);
          if (setProgressSections) {
            setProgressSections((prev) => {
              const updatedSections = [...prev];
              const redSection = updatedSections.find(
                (section) => section.color === "red",
              );

              if (redSection) {
                redSection.value += iterativeStep;
              } else {
                updatedSections.push({
                  value: iterativeStep,
                  color: "red",
                });
              }
              notifications.show({
                color: "red",
                title: error.response?.data?.title ?? t("errors.defaultTitle"),
                message:
                  error.response?.data?.detail ?? "errors.defaultMessage",
              });

              return updatedSections;
            });
          }
          row.toggleSelected(true);
        });
    }
  };
  const handleConfirm = async () => {
    if (table?.current) {
      const selectedRowModel = table.current.getSelectedRowModel();
      table.current.resetRowSelection();
      if (selectedRowModel.rows?.length > 0) {
        if (setConfirmed) {
          setConfirmed(true);
        }
        if (setProgressSections) {
          setProgressSections([]);
        }
        setProgressCount(0);

        const rowCount = selectedRowModel.rows.length;
        const iterativeStep = 100 / rowCount;
        for (const row of selectedRowModel.rows) {
          await delay(10);
          await handleDelete(row, iterativeStep);
        }
        await queryCache.invalidateQueries(`${queryKey!}_list`);
        await delay(200);
        if (setConfirmed) {
          setConfirmed(false);
        }
      }
    }
  };

  useEffect(() => {
    if (setProgressPercentage) {
      setProgressPercentage(progressCount.toFixed(0) as unknown as number);
    }
  }, [progressCount, setProgressPercentage]);

  // For future use
  //const isTablet = useMediaQuery(`(max-width: 986px)`);
  const openDeleteModal = () => {
    modals.openConfirmModal({
      title: modalTitle ?? t("entity.deleteEntity"),
      centered: true,
      children: (
        <Text size="sm">
          {modalContent ?? t("entity.deleteEntityConfirmation")}
        </Text>
      ),
      labels: {
        confirm: confirmLabel ?? t("entity.deleteEntity"),
        cancel: cancelLabel ?? t("entity.cancel"),
      },
      confirmProps: { color: "red" },
      onConfirm: () => {
        void handleConfirm();
      },
    });
  };

  return (
    <Button
      onClick={() => {
        if (table?.current?.getSelectedRowModel().rows?.length === 0) {
          alert("Please select at least one row to proceed.");
        } else {
          openDeleteModal();
        }
      }}
      variant="subtle"
      color="red"
      type="button"
      size="compact-md"
    >
      <Flex>
        <IconTrash size={18} />
      </Flex>
    </Button>
  );
}
