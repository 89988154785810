import { EntityLayout } from "@/features/entity";
import { AttachmentRenderer } from "@/features/entity/components/TableMantine/CellRenderers";
import { type PathKeys, type Schemas } from "@/types";
import { type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

interface AppointmentAttachmentsProps {
  appointmentId: string;
}

export function AppointmentAttachments({
  appointmentId,
}: PropsWithChildren<AppointmentAttachmentsProps>) {
  const { t } = useTranslation("features");

  return (
    <>
      <EntityLayout.TableMantine<
        Schemas["AttachmentRetrieveDto"],
        Schemas["AttachmentRetrieveDtoPagedList"]
      >
        resourcePath={
          `/api/Appointments/${appointmentId}/attachments` as PathKeys
        }
        queryKey="attachment"
        entityPath="attachmens"
        title={t("appointments.attachments")}
        toolbarEnabled={false}
        disableNavigation={true}
        selectionEnabled={false}
        columns={[
          {
            accessorKey: "name",
            header: t("appointments.name"),
            filterVariant: "text",
            enableResizing: false,
          },
          {
            accessorKey: "id",
            header: "",
            enableColumnFilter: false,
            enableSorting: false,
            enableResizing: false,
            size: 60,
            Cell: AttachmentRenderer,
          },
        ]}
      />
    </>
  );
}
