import { Menu, rem } from "@mantine/core";
import { UserButton } from "./UserButton";
import { IconLogout } from "@tabler/icons-react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { UserLanguage } from "./UserLanguage";

export function UserMenu() {
  const { t } = useTranslation("common");
  const { user, logout } = useAuth0();
  const handleLogout = () => {
    void logout();
  };

  return (
    <Menu withArrow>
      <Menu.Target>
        <UserButton name={user?.name} email={user?.email} />
      </Menu.Target>
      <Menu.Dropdown miw={250}>
        <Menu.Label>{t("userMenu.application")}</Menu.Label>
        <UserLanguage />
        <Menu.Item
          mt={4}
          color="red"
          onClick={handleLogout}
          leftSection={
            <IconLogout style={{ width: rem(14), height: rem(14) }} />
          }
        >
          {t("userMenu.logout")}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
