import { api, type ApiResponse } from "@/lib/api";
import { type PathKeys } from "@/types";
import { filterFalsyValues } from "@/utils/filters";
import { formatDateTimeForBackend } from "@/utils/date";

export interface QueryParams {
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  desc?: boolean;
  filter?: string;
  searchBy?: string;
  singlePage?: boolean;
}

interface GetEntityListRequestProps {
  resourcePath: PathKeys;
  params: QueryParams;
}

export async function getEntityList<TResponse>({
  resourcePath,
  params = {
    pageSize: 20,
  },
}: GetEntityListRequestProps) {
  const response = await api.get<
    TResponse,
    ApiResponse<TResponse>,
    QueryParams
  >(resourcePath, {
    params: {
      pageSize: 20,
      ...params,
    },
  });

  if (response?.data) {
    return response?.data;
  }

  return null;
}

interface getEntitiesByIdsProps {
  resourcePath: PathKeys;
  resourceIds: string[];
  params: QueryParams;
}

export async function getEntitiesByIds<TResponse>({
  resourcePath,
  resourceIds,
  params = {
    pageSize: 20,
  },
}: getEntitiesByIdsProps) {
  if (resourceIds.length === 0) return Promise.resolve([]);
  const { data } = await api.get<
    TResponse,
    ApiResponse<TResponse>,
    QueryParams
  >(resourcePath + "?ids=" + resourceIds.join("&ids="), {
    params: { pageSize: 20, ...params },
  });

  return data;
}

interface GetEntityListByIdRequestProps {
  resourcePath: PathKeys;
  resourceId: string;
  params: QueryParams;
}

export async function getEntityListById<TResponse>({
  resourcePath,
  resourceId,
  params = {
    pageSize: 20,
  },
}: GetEntityListByIdRequestProps) {
  const { data } = await api.get<
    TResponse,
    ApiResponse<TResponse>,
    QueryParams
  >(resourcePath.replace("{id}", resourceId), {
    params: {
      pageSize: 20,
      ...params,
    },
  });

  return data;
}

interface GetEntityRequestProps {
  resourcePath: PathKeys;
  resourceId: string;
}

export async function getEntity<TResponse>({
  resourcePath,
  resourceId,
}: GetEntityRequestProps) {
  const { data } = await api.get<
    TResponse,
    ApiResponse<TResponse>,
    QueryParams
  >(resourcePath.replace("{id}", resourceId));

  return data;
}

interface CreateEntityRequestProps<TParams> {
  resourcePath: PathKeys;
  params: TParams;
}

export async function createEntity<
  TResponse,
  TParams extends Record<string, unknown>,
>({ resourcePath, params }: CreateEntityRequestProps<TParams>) {
  const formattedParams = Object.fromEntries(
    Object.entries(params).map(([key, value]) => {
      if (value instanceof Date) {
        value = formatDateTimeForBackend(value);
      }
      return [key, value];
    }),
  );

  const result = await api.post<TResponse, ApiResponse<TResponse>, TParams>(
    resourcePath,
    formattedParams as TParams,
  );

  return result;
}

interface PostEntityRequestProps<TParams> {
  resourcePath: string;
  params: TParams;
}

export async function postEntity<TResponse, TParams>({
  resourcePath,
  params,
}: PostEntityRequestProps<TParams>) {
  const result = await api.post<TResponse, ApiResponse<TResponse>, TParams>(
    resourcePath,
    params,
  );

  return result;
}

interface UpdateEntityRequestProps<TParams> {
  resourcePath: PathKeys;
  resourceId: string;
  params: TParams;
}

export async function updateEntity<
  TResponse,
  TParams extends Record<string, unknown>,
>({ resourcePath, resourceId, params }: UpdateEntityRequestProps<TParams>) {
  const patchPayload = Object.entries(filterFalsyValues(params))
    .map(([key, value]) => {
      if (value instanceof Date) {
        value = formatDateTimeForBackend(value);
      }
      return {
        op: "replace",
        path: key,
        value: value,
      };
    })
    .filter(({ path }) => path.length > 0);

  const res = await api.patch<TResponse>(
    resourcePath.replace("{id}", resourceId),
    patchPayload,
  );

  return res;
}

interface DeleteEntityRequestProps {
  resourcePath: PathKeys;
  resourceId: string;
}

interface RemoveRolesRequestProps {
  resourcePath: PathKeys;
  resourceId: string;
  roles: string[];
}

export async function deleteEntity({
  resourcePath,
  resourceId,
}: DeleteEntityRequestProps) {
  const res = await api.delete<never>(resourcePath.replace("{id}", resourceId));

  return res;
}

export async function removeRoles({
  resourcePath,
  resourceId,
  roles,
}: RemoveRolesRequestProps) {
  const result = await api.delete(resourcePath.replace("{id}", resourceId), {
    data: { roles },
  });

  return result;
}
