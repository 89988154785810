import {
  type MRT_Column,
  type MRT_Row,
  type MRT_TableInstance,
  type MRT_Cell,
  type MRT_RowData,
} from "mantine-react-table";
import { type ReactNode, type RefObject } from "react";
import { DownloadFile } from "./DownloadFile";
import { DeleteFile } from "./DeleteFile";
import { Flex } from "@mantine/core";

export function AttachmentRenderer(props: {
  cell: MRT_Cell<MRT_RowData, unknown>;
  column: MRT_Column<MRT_RowData, unknown>;
  renderedCellValue: ReactNode | number | string;
  renderedColumnIndex?: number;
  renderedRowIndex?: number;
  row: MRT_Row<MRT_RowData>;
  rowRef?: RefObject<HTMLTableRowElement>;
  table: MRT_TableInstance<MRT_RowData>;
}) {
  if (!props.cell.getValue<string>()) {
    return <></>;
  }

  const name = getPropertyFromObject("name", props.row.original);

  return (
    <>
      <Flex gap="8" justify="flex-end">
        <DownloadFile id={props.cell.getValue<string>()} fileName={name} />
        <DeleteFile id={props.cell.getValue<string>()} fileName={name} />
      </Flex>
    </>
  );
}

const getPropertyFromObject = (field: string, obj: object): string => {
  type prop = keyof typeof obj;
  const selectedProp = field as prop;

  return obj[selectedProp];
};
