import { useRoutes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "@/components/PageLoader";
import { protectedRoutes } from "./protected";
import { salesRoutes } from "./protected";
import { publicRoutes } from "./public";
import { useUserContext } from "@/components/Layout/Contexts/User/useUserContext";

export function AppRoutes() {
  const { isLoading } = useAuth0();
  const { roles } = useUserContext();

  let routes = [...publicRoutes, ...protectedRoutes];

  if (roles.length > 0) {
    if (roles.includes("Sales")) {
      routes = [...routes, ...salesRoutes];
    }
  }

  const element = useRoutes(routes);

  if (isLoading) {
    return <PageLoader />;
  }

  return <>{element}</>;
}
