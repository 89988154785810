import { type PathKeys } from "@/types";
import { notifications } from "@mantine/notifications";
import { type QueryKey, useMutation, useQueryClient } from "react-query";
import {
  createEntity,
  deleteEntity,
  removeRoles,
  postEntity,
  updateEntity,
} from "../api";
import { useTranslation } from "react-i18next";
import { type ApiError } from "@/lib/api";
import { CustomHandledErrors } from "@/config/error";
import { bT } from "@/utils/trans";

interface EntityCreateMutationProps {
  resourcePath: PathKeys;
  queryKey: QueryKey;
}

interface ApiErrorResponse {
  title?: string;
  detail?: string;
  errorName?: string;
  args?: string[];
  fallbackMessage?: string;
}

const throwError = (
  error: ApiError<ApiErrorResponse>,
  errorName: string,
  defaultMessage: string,
) => {
  if (!CustomHandledErrors.includes(error.response?.status ?? 0)) {
    let message = errorName;
    if (errorName == `${error.response?.data.errorName}.message`) {
      message = error.response?.data.fallbackMessage ?? "";
      if (message == "") {
        message = defaultMessage;
      }
    }

    notifications.show({
      color: "red",
      title: error.response?.data?.title ?? "",
      message: bT(message, error.response?.data.args ?? []),
    });
  }
};

export function useEntityCreateMutation<
  TResponse = unknown,
  TParams extends Record<string, unknown> = Record<string, unknown>,
>({ resourcePath, queryKey }: EntityCreateMutationProps) {
  const { t } = useTranslation("backend");

  const queryCache = useQueryClient();
  return useMutation(
    queryKey,
    (params: TParams) =>
      createEntity<TResponse, TParams>({ resourcePath, params }),
    {
      onSuccess: () => {
        void queryCache.invalidateQueries("lead_omnichannel_list");
        void queryCache.invalidateQueries(`${queryKey as string}_list`);
      },
      onError: (error: ApiError<ApiErrorResponse>) => {
        throwError(
          error,
          t(`${error.response?.data.errorName}.message`),
          t("error.defaultMessage"),
        );
      },
    },
  );
}

interface EntityPostMutationProps {
  resourcePath: string;
  queryKey: QueryKey;
}

export function useEntityPostMutation<TResponse = unknown, TParams = unknown>({
  resourcePath,
  queryKey,
}: EntityPostMutationProps) {
  const { t } = useTranslation("common");
  const queryCache = useQueryClient();
  return useMutation(
    queryKey,
    (params: TParams) =>
      postEntity<TResponse, TParams>({ resourcePath, params }),
    {
      onSuccess: () =>
        queryCache.invalidateQueries(`${queryKey as string}_list`),
      onError: (error: ApiError<ApiErrorResponse>) => {
        throwError(
          error,
          t(`${error.response?.data.errorName}.message`),
          t("error.defaultMessage"),
        );
      },
    },
  );
}

interface EntityUpdateMutationProps {
  resourcePath: PathKeys;
  resourceId: string;
  queryKey: QueryKey;
}

export function useEntityUpdateMutation<
  TResponse = unknown,
  TParams extends Record<string, unknown> = Record<string, unknown>,
>({ resourcePath, resourceId, queryKey }: EntityUpdateMutationProps) {
  const { t } = useTranslation("common");
  const queryCache = useQueryClient();

  return useMutation(
    queryKey,
    (params: TParams) =>
      updateEntity<TResponse, TParams>({ resourcePath, resourceId, params }),
    {
      onSuccess: () => {
        void queryCache.invalidateQueries(`${queryKey as string}_list`);
        void queryCache.invalidateQueries("lead_omnichannel_list");
        void queryCache.invalidateQueries(
          `${queryKey as string}_${resourceId}`,
        );
      },
      onError: (error: ApiError<ApiErrorResponse>) => {
        throwError(
          error,
          t(`${error.response?.data.errorName}.message`),
          t("error.defaultMessage"),
        );
      },
    },
  );
}

interface EntityLazyUpdateMutationProps {
  resourcePath: PathKeys;
  queryKey: QueryKey;
}

export function useEntityLazyUpdateMutation<
  TResponse = unknown,
  TParams extends Record<string, unknown> = Record<string, unknown>,
>({ resourcePath, queryKey }: EntityLazyUpdateMutationProps) {
  const { t } = useTranslation("common");
  const queryCache = useQueryClient();

  return useMutation(
    queryKey,
    ({
      resourceId,
      params,
    }: {
      resourceId: string;
      queriesToInvalidate?: QueryKey[];
      params: TParams;
    }) =>
      updateEntity<TResponse, TParams>({ resourcePath, resourceId, params }),
    {
      onSuccess: (_, { resourceId, queriesToInvalidate }) => {
        void queryCache.invalidateQueries(`${queryKey as string}_list`);
        void queryCache.invalidateQueries(
          `${queryKey as string}_${resourceId}`,
        );

        if (queriesToInvalidate) {
          void queryCache.invalidateQueries(queriesToInvalidate);
        }
      },
      onError: (error: ApiError<ApiErrorResponse>) => {
        throwError(
          error,
          t(`${error.response?.data.errorName}.message`),
          t("error.defaultMessage"),
        );
      },
    },
  );
}

interface EntityDeleteMutationProps {
  resourcePath: PathKeys;
  resourceId: string;
  queryKey: QueryKey;
}

export function useEntityDeleteMutation({
  resourcePath,
  resourceId,
  queryKey,
}: EntityDeleteMutationProps) {
  const { t } = useTranslation("common");
  const queryCache = useQueryClient();

  return useMutation(
    queryKey,
    () => deleteEntity({ resourcePath, resourceId }),
    {
      onSuccess: () =>
        queryCache.invalidateQueries(`${queryKey as string}_list`),
      onError: (error: ApiError<ApiErrorResponse>) => {
        throwError(
          error,
          t(`${error.response?.data.errorName}.message`),
          t("error.defaultMessage"),
        );
      },
    },
  );
}

interface RemoveRolesMutationProps {
  resourcePath: PathKeys;
  resourceId: string;
  queryKey: QueryKey;
}

export function useRemoveRolesMutation({
  resourcePath,
  resourceId,
  queryKey,
}: RemoveRolesMutationProps) {
  const { t } = useTranslation("common");
  const queryCache = useQueryClient();

  return useMutation(
    queryKey,
    (roles: string[]) => removeRoles({ resourcePath, resourceId, roles }),
    {
      onSuccess: () =>
        queryCache.invalidateQueries(`${queryKey as string}_list`),
      onError: (error: ApiError<ApiErrorResponse>) => {
        throwError(
          error,
          t(`${error.response?.data.errorName}.message`),
          t("error.defaultMessage"),
        );
      },
    },
  );
}
