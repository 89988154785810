import { Center, Container } from "@mantine/core";
import { Loader } from "@/components/Elements/Loader";

export function PageLoader() {
  return (
    <Container>
      <Center h="90vh">
        <Loader />
      </Center>
    </Container>
  );
}
