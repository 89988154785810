import { useState, useEffect, type ReactNode } from "react";
import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Center,
  type DefaultMantineColor,
  Flex,
  Popover,
  ScrollArea,
  SegmentedControl,
  Text,
} from "@mantine/core";
import {
  IconCircleCheck,
  IconAlertTriangle,
  IconExclamationCircle,
} from "@tabler/icons-react";
import { useNotifications } from "@mantine/notifications";
import { useAuth0 } from "@auth0/auth0-react";

interface CustomNotification {
  id: string;
  color: DefaultMantineColor;
  title: ReactNode;
  message: ReactNode;
  timestamp: number;
  read: boolean;
}

export function NotificationPopover() {
  const notificationsStore = useNotifications();
  const [opened, setOpened] = useState(false);
  const { user } = useAuth0();
  const [notifications, setNotifications] = useState<CustomNotification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [filterStatus, setFilterStatus] = useState<string>("all");

  // Load notifications from localStorage on component mount
  useEffect(() => {
    const storedNotificationsJson = localStorage.getItem("notifications");
    const storedNotifications: CustomNotification[] = storedNotificationsJson
      ? (JSON.parse(storedNotificationsJson) as CustomNotification[])
      : [];

    setNotifications(storedNotifications);
    const unread = storedNotifications.filter((n) => !n.read).length;
    setUnreadCount(unread);
  }, []);

  // Update notifications when notificationStore changes
  useEffect(() => {
    const storeNotifications = notificationsStore.notifications || [];

    // Get stored notifications from localStorage
    const storedNotificationsJson = localStorage.getItem("notifications") ?? "";
    const storedNotifications: CustomNotification[] = storedNotificationsJson
      ? (JSON.parse(storedNotificationsJson) as CustomNotification[])
      : [];

    // Find new notifications not already stored
    const newNotifications = storeNotifications
      .filter(
        (notification) =>
          !storedNotifications.some(
            (storedNotification) => storedNotification.id === notification.id,
          ),
      )
      .map((notification) => ({
        id: notification.id ?? "",
        color: notification.color || "blue",
        title: notification.title || "",
        message: notification.message || "",
        timestamp: Date.now(),
        read: false,
      }));

    if (newNotifications.length > 0) {
      const updatedNotifications = [
        ...storedNotifications,
        ...newNotifications,
      ];
      localStorage.setItem(
        "notifications",
        JSON.stringify(updatedNotifications),
      );
      setNotifications(updatedNotifications);
      const unread = updatedNotifications.filter((n) => !n.read).length;
      setUnreadCount(unread);
    }
  }, [notificationsStore.notifications]);

  // Mark notifications as read when popover is opened
  useEffect(() => {
    if (opened) {
      const updatedNotifications = notifications.map((n) => ({
        ...n,
        read: true,
      }));
      setNotifications(updatedNotifications);
      setUnreadCount(0);
      localStorage.setItem(
        "notifications",
        JSON.stringify(updatedNotifications),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  // Handle clearing all notifications
  const handleClearNotifications = () => {
    localStorage.removeItem("notifications");
    setNotifications([]);
    setUnreadCount(0);
    setOpened(false);
  };

  // Filtered notifications based on filter criteria
  const filteredNotifications = notifications.filter((notification) => {
    // Filter by status
    const statusMatch =
      filterStatus === "all" || filterStatus.includes(notification.color);

    return statusMatch;
  });

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      width={"50%"}
      position="bottom-start"
      shadow="xl"
    >
      <Popover.Target>
        <ActionIcon
          variant="transparent"
          w={64}
          h={48}
          onClick={() => setOpened((o) => !o)}
          style={{ position: "relative", cursor: "pointer" }}
        >
          {user?.picture ? (
            <Avatar src={user?.picture} radius="xl" size={32} />
          ) : (
            <Avatar src={null} />
          )}
          {unreadCount > 0 && (
            <Badge
              color="red"
              variant="filled"
              size="sm"
              style={{ position: "absolute", top: 0, right: 6 }}
            >
              {unreadCount}
            </Badge>
          )}
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown w={"auto"} style={{ border: "1px solid #e0e0e0" }}>
        <Flex
          gap="xs"
          justify="center"
          align="center"
          direction="row"
          wrap="nowrap"
        >
          <Button
            flex={1}
            size="xs"
            variant="subtle"
            onClick={handleClearNotifications}
          >
            Clear All
          </Button>
          <SegmentedControl
            flex={12}
            value={filterStatus}
            onChange={setFilterStatus}
            data={[
              {
                label: (
                  <Center>
                    <Flex dir="row" gap={4}>
                      <Text size="md" w={40} fw={600}>
                        {"All"}
                      </Text>
                    </Flex>
                  </Center>
                ),
                value: "all",
              },
              {
                label: (
                  <Center>
                    <Flex dir="row" gap={4}>
                      <IconCircleCheck size={24} color="green" />
                      <Text size="md" fw={600}>
                        Success
                      </Text>
                    </Flex>
                  </Center>
                ),
                value: "green",
              },
              {
                label: (
                  <Center>
                    <Flex dir="row" gap={4}>
                      <IconAlertTriangle size={24} color="orange" />
                      <Text size="md" fw={600}>
                        Warning
                      </Text>
                    </Flex>
                  </Center>
                ),
                value: "orange",
              },
              {
                label: (
                  <Center>
                    <Flex dir="row" gap={4}>
                      <IconExclamationCircle size={24} color="red" />
                      <Text size="md" fw={600}>
                        Error
                      </Text>
                    </Flex>
                  </Center>
                ),
                value: "red",
              },
            ]}
          />
        </Flex>

        <ScrollArea.Autosize mah={360} scrollbarSize={8} type="always">
          {filteredNotifications.length > 0 ? (
            [...filteredNotifications].reverse().map((notification) => (
              <Flex
                key={notification.id}
                gap="md"
                justify="flex-start"
                align="flex-start"
                direction="row"
                mr={30}
                style={{
                  padding: "6px 0",
                  borderBottom: "1px solid #e9ecef",
                }}
              >
                {notification.color === "green" && (
                  <IconCircleCheck size={32} color={notification.color} />
                )}
                {notification.color === "orange" && (
                  <IconAlertTriangle size={32} color={notification.color} />
                )}
                {notification.color === "red" && (
                  <IconExclamationCircle size={32} color={notification.color} />
                )}
                <div style={{ flex: 1 }}>
                  <Text fw={500} style={{ color: notification.color }}>
                    {notification.title}
                  </Text>
                  <Text size="sm">{notification.message}</Text>
                  {/* Display the timestamp */}
                  <Text size="xs" color="dimmed">
                    {new Date(notification.timestamp).toLocaleString()}
                  </Text>
                </div>
              </Flex>
            ))
          ) : (
            <Center>
              <Text size="xl" fw={900}>
                No new notifications
              </Text>
            </Center>
          )}
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
}
