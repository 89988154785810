import { useEntityListQuery } from "@/features/entity/queries";
import { type PathKeys } from "@/types";
import { type QueryKey } from "react-query";
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  format,
  isValid,
  parseISO,
} from "date-fns";
import { useUserContext } from "@/components/Layout/Contexts/User/useUserContext";

interface DataProps {
  graphMonth: Date;
  resourcePath: PathKeys;
  queryKey: QueryKey;
  filter: string;
}

interface BasicEntity {
  id?: string;
  recordState?: "Active" | "Inactive";
  createdOn?: string;
  startDate?: string;
}

interface BasicResponse<TData> {
  data?: TData[] | null;
  totalPages?: number;
  totalCount?: number;
}

export interface DateCount {
  date: string;
  Count: number;
}
export function useGraphData<
  TData extends BasicEntity,
  TResponse extends BasicResponse<TData>,
>({
  resourcePath,
  queryKey,
  graphMonth,
  filter,
}: DataProps): { data: DateCount[]; isLoading: boolean; isFetching: boolean } {
  const firstDay = startOfMonth(graphMonth);
  const lastDay = endOfMonth(graphMonth);

  const { finalUser } = useUserContext();
  const {
    data: pagedData,
    isLoading,
    isFetching,
  } = useEntityListQuery<TResponse>({
    resourcePath,
    queryKey,
    params: {
      filter: `${finalUser?.businessUnitId ? `businessUnit.Id == ${finalUser?.businessUnitId} &&` : ""} createdOn >= "${format(firstDay, "yyyy-MM-dd'T'HH:mm:ss")}" && createdOn <= "${format(lastDay, "yyyy-MM-dd'T'HH:mm:ss")}" ${filter ? `&& ${filter}` : ""}`,
      singlePage: true,
    },
  });

  if (isLoading || isFetching) {
    return { data: [], isLoading, isFetching };
  }

  const monthDays = eachDayOfInterval({ start: firstDay, end: lastDay }).reduce<
    Record<string, DateCount>
  >((acc, day) => {
    const dateString = format(day, "yyyy-MM-dd");
    acc[dateString] = { date: dateString, Count: 0 };
    return acc;
  }, {});

  pagedData?.data?.forEach((entity) => {
    const createdDate = entity.createdOn
      ? parseISO(entity.createdOn)
      : undefined;
    const startDate = entity.startDate ? parseISO(entity.startDate) : undefined;
    const date =
      createdDate && isValid(createdDate)
        ? createdDate
        : startDate && isValid(startDate)
          ? startDate
          : undefined;

    if (date) {
      const dateString = format(date, "yyyy-MM-dd");
      // Use a type assertion here
      const dayCount = monthDays[dateString] as DateCount | undefined;
      if (dayCount) {
        dayCount.Count += 1;
      } else {
        console.warn(`Unexpected date encountered: ${dateString}`);
      }
    }
  });

  return {
    data: Object.values(monthDays).sort((a, b) => a.date.localeCompare(b.date)),
    isLoading,
    isFetching,
  };
}
