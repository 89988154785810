import {
  Box,
  Button,
  Center,
  Chip,
  Divider,
  Group,
  Loader,
} from "@mantine/core";
import { useUserContext } from "../Contexts/User/useUserContext";
import { OCLeadTab } from "./OCLeadTab";
import { OmnichannelTimer } from "./OmnichannelTimer";
import { useEntityListQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

const prioritizedConditions = [
  "Schedule an appointment",
  "Maak een afspraak",
  "tour",
  "reservation",
];

export default function Omnichannel() {
  const { OCLeadValue, setOCLeadValue, finalUser } = useUserContext();
  const { t } = useTranslation("features");
  const queryCache = useQueryClient();
  const { data, isLoading } = useEntityListQuery<
    Schemas["LeadRetrieveDtoPagedList"]
  >({
    resourcePath: "/api/Leads/omnichannel",
    queryKey: "lead_omnichannel",
    params: {
      filter: `OwnerId == ${finalUser?.id}`,
    },
    refetchInterval: 10000,
  });

  const leadsRaw = data?.data?.sort((a, b) => {
    // Sort by Priority (ascending)
    if (a.priority !== b.priority) {
      return a.priority! - b.priority!;
    }
    // Then by CallCount (ascending)
    if (a.callCount !== b.callCount) {
      return a.callCount! - b.callCount!;
    }
    // Then by CreatedOn (descending)
    return a.createdOn! < b.createdOn! ? -1 : 1;
  });

  const priorityLeads: Schemas["LeadRetrieveDto"][] = [];
  const otherLeads: Schemas["LeadRetrieveDto"][] = [];

  leadsRaw?.forEach((lead) => {
    if (
      prioritizedConditions.includes(lead.webformTitle ?? "") ||
      prioritizedConditions.includes(lead.step ?? "")
    ) {
      priorityLeads.push(lead);
    } else {
      otherLeads.push(lead);
    }
  });

  if (isLoading) {
    return (
      <Center w="100%" h="100%">
        <Loader />
      </Center>
    );
  }

  const OCLeadsPriority = priorityLeads?.map((x) => {
    return {
      label: x.fullName ?? "Name Invalid",
      value: x.id ?? "Id Invalid",
    };
  });

  const OCLeads = otherLeads?.map((x) => {
    return {
      label: x.fullName ?? "Name Invalid",
      value: x.id ?? "Id Invalid",
    };
  });

  return (
    <Box w={"100%"}>
      <Chip.Group
        multiple={false}
        value={OCLeadValue}
        onChange={setOCLeadValue}
      >
        <Group
          justify="center"
          align="center"
          gap={"xl"}
          w={"100vw"}
          h={"5vh"}
          mt={".35vh"}
          ml={"1vw"}
        >
          {OCLeadsPriority?.map((OCLead) => {
            return (
              <OCLeadTab
                priority={true}
                key={OCLead.value}
                label={OCLead.label}
                value={OCLead.value}
                currentValue={OCLeadValue}
                lead={priorityLeads?.find((x) => x.id === OCLead.value)}
              />
            );
          })}
          {OCLeadsPriority.length > 0 && (
            <Divider
              size="sm"
              mt={5}
              mb={5}
              orientation="vertical"
              color="green"
            />
          )}
          {OCLeads?.map((OCLead) => {
            return (
              <OCLeadTab
                key={OCLead.value}
                label={OCLead.label}
                value={OCLead.value}
                currentValue={OCLeadValue}
                lead={otherLeads?.find((x) => x.id === OCLead.value)}
              />
            );
          })}
          {OCLeads.length > 0 && (
            <Divider
              size="sm"
              mt={5}
              mb={5}
              orientation="vertical"
              color="gray"
            />
          )}
          <Button
            color="gray"
            variant="outline"
            onClick={() => {
              void queryCache.invalidateQueries(`lead_omnichannel_list`);
            }}
          >
            {t("omnichannel.refresh")}
          </Button>
        </Group>
      </Chip.Group>
      <OmnichannelTimer />
    </Box>
  );
}
