/**
 * App Routes Configuration
 *
 * This file defines the routing structure for the protected areas of the application.
 * It uses React Router and lazy loading to efficiently manage routes and components.
 *
 * Key features:
 * - Lazy loading of route components for improved performance
 * - Protected routes wrapped with AuthGuard
 * - Alphabetically organized routes and imports for easy management
 * - Placeholder comments for future route additions
 *
 * Note: When adding new routes, place them in the appropriate alphabetical section
 * in both the imports and the route definitions, and update the imports at the top of the file.
 */

/* eslint-disable react-refresh/only-export-components */
import { Suspense, lazy } from "react";
import { Navigate, Outlet, type RouteObject } from "react-router-dom";
import { MainLayout } from "@/components/Layout";
import { AuthGuard } from "@/components/Guards/AuthGuard";
import { PageLoader } from "@/components/PageLoader";
import Wizard from "@/components/Wizard/Wizard";
import Home from "@/components/HomeView/Home";

// Lazy-loaded route components
// A
const AppointmentsRoutes = lazy(() => import("@/features/appointments/routes"));
const AppointmentTypeTemplateMappingsRoutes = lazy(
  () => import("@/features/appointmentTypeTemplateMappings/routes"),
);
const AdvisedProductsRoutes = lazy(
  () => import("@/features/advisedProducts/routes"),
);
const AdvisedUnitsRoutes = lazy(() => import("@/features/advisedUnits/routes"));
const AppUsersRoutes = lazy(() => import("@/features/appUsers/routes"));

// B
const BusinessUnitsRoutes = lazy(
  () => import("@/features/businessUnits/routes"),
);

// C
const CaseCommentsRoutes = lazy(() => import("@/features/caseComments/routes"));
const CaseReasonsRoutes = lazy(() => import("@/features/caseReasons/routes"));
const CasesRoutes = lazy(() => import("@/features/cases/routes"));
const ComplaintReasonsRoutes = lazy(
  () => import("@/features/complaintReasons/routes"),
);
const ContactRolesRoutes = lazy(() => import("@/features/contactRoles/routes"));
const ContactsRoutes = lazy(() => import("@/features/contacts/routes"));
const ContractLinesRoutes = lazy(
  () => import("@/features/contractLines/routes"),
);
const ContractsRoutes = lazy(() => import("@/features/contracts/routes"));
const CountriesRoutes = lazy(() => import("@/features/countries/routes"));
const CustomerContactsRoutes = lazy(
  () => import("@/features/customerContacts/routes"),
);
const CustomersRoutes = lazy(() => import("@/features/customers/routes"));

// D
const DashboardsRoutes = lazy(() => import("@/features/dashboards/routes"));

// E
const EmailsRoutes = lazy(() => import("@/features/emails/routes"));

// F
// G
// H
const HtmlTemplatesRoutes = lazy(
  () => import("@/features/htmlTemplates/routes"),
);

// I
// J
// K
// L
const LeadsRoutes = lazy(() => import("@/features/leads/routes"));
const LossReasonsRoutes = lazy(() => import("@/features/lossReasons/routes"));

// M
const MailboxesRoutes = lazy(() => import("@/features/mailboxes/routes"));
// N
// O
const OriginCategoriesRoutes = lazy(
  () => import("@/features/originCategories/routes"),
);
const OriginsRoutes = lazy(() => import("@/features/origins/routes"));
const OmnichannelRoutes = lazy(() => import("@/features/omnichannel/routes"));

// P
const ProductsRoutes = lazy(() => import("@/features/products/routes"));
const PhoneCallsRoutes = lazy(() => import("@/features/phoneCalls/routes"));

// Q
const QuotesRoutes = lazy(() => import("@/features/quotes/routes"));

// R
const ReservationsRoutes = lazy(() => import("@/features/reservations/routes"));
const RefundsRoutes = lazy(() => import("@/features/refunds/routes"));
const RefundProductsRoutes = lazy(
  () => import("@/features/refundProducts/routes"),
);
const RolesRoutes = lazy(() => import("@/features/roles/routes"));

// S
const SettingsRoutes = lazy(() => import("@/features/settings/routes"));
const StorageTypesRoutes = lazy(() => import("@/features/storageTypes/routes"));
const SmsMessagesRoutes = lazy(() => import("@/features/smsMessages/routes"));

// T
const TagsRoutes = lazy(() => import("@/features/tags/routes"));

// U
const UnitTagsRoutes = lazy(() => import("@/features/unitTags/routes"));
const UnitsRoutes = lazy(() => import("@/features/units/routes"));
const UnitTypesRoutes = lazy(() => import("@/features/unitTypes/routes"));
const UserGroupsRoutes = lazy(() => import("@/features/userGroups/routes"));

// V
// W
// X
// Y
// Z

// Main App component with layout and suspense
function App() {
  return (
    <MainLayout>
      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
}

export const salesRoutes: RouteObject[] = [
  {
    path: "/app",
    element: <AuthGuard component={App} />,
    children: [
      {
        path: "/app/omnichannel",
        element: <OmnichannelRoutes />,
      },
    ],
  },
];

// Protected routes configuration
export const protectedRoutes: RouteObject[] = [
  {
    path: "/app",
    element: <AuthGuard component={App} />,
    children: [
      {
        index: true,
        element: <Navigate to="/app/home" />,
      },
      // A
      {
        path: "appointments/*",
        element: <AppointmentsRoutes />,
      },
      {
        path: "appointmentTypeTemplateMappings/*",
        element: <AppointmentTypeTemplateMappingsRoutes />,
      },
      {
        path: "advisedProducts/*",
        element: <AdvisedProductsRoutes />,
      },
      {
        path: "advisedUnits/*",
        element: <AdvisedUnitsRoutes />,
      },
      {
        path: "appUsers/*",
        element: <AppUsersRoutes />,
      },
      // B
      {
        path: "businessUnits/*",
        element: <BusinessUnitsRoutes />,
      },
      // C
      {
        path: "caseComments/*",
        element: <CaseCommentsRoutes />,
      },
      {
        path: "caseReasons/*",
        element: <CaseReasonsRoutes />,
      },
      {
        path: "cases/*",
        element: <CasesRoutes />,
      },
      {
        path: "complaintReasons/*",
        element: <ComplaintReasonsRoutes />,
      },
      {
        path: "contactRoles/*",
        element: <ContactRolesRoutes />,
      },
      {
        path: "contacts/*",
        element: <ContactsRoutes />,
      },
      {
        path: "contractLines/*",
        element: <ContractLinesRoutes />,
      },
      {
        path: "contracts/*",
        element: <ContractsRoutes />,
      },
      {
        path: "countries/*",
        element: <CountriesRoutes />,
      },
      {
        path: "customerContacts/*",
        element: <CustomerContactsRoutes />,
      },
      {
        path: "customers/*",
        element: <CustomersRoutes />,
      },
      // D
      {
        path: "dashboards/*",
        element: <DashboardsRoutes />,
      },
      // E
      {
        path: "emails/*",
        element: <EmailsRoutes />,
      },
      // F
      // G
      // H
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "htmlTemplates/*",
        element: <HtmlTemplatesRoutes />,
      },
      // I
      // J
      // K
      // L
      {
        path: "leads/*",
        element: <LeadsRoutes />,
      },
      {
        path: "lossReasons/*",
        element: <LossReasonsRoutes />,
      },
      // M
      {
        path: "mailboxes/*",
        element: <MailboxesRoutes />,
      },
      // N
      // O
      {
        path: "originCategories/*",
        element: <OriginCategoriesRoutes />,
      },
      {
        path: "origins/*",
        element: <OriginsRoutes />,
      },
      // P
      {
        path: "products/*",
        element: <ProductsRoutes />,
      },
      {
        path: "phoneCalls/*",
        element: <PhoneCallsRoutes />,
      },
      // Q
      {
        path: "quotes/*",
        element: <QuotesRoutes />,
      },
      // R
      {
        path: "reservations/*",
        element: <ReservationsRoutes />,
      },
      {
        path: "refunds/*",
        element: <RefundsRoutes />,
      },
      {
        path: "refundProducts/*",
        element: <RefundProductsRoutes />,
      },
      {
        path: "roles/*",
        element: <RolesRoutes />,
      },
      // S
      {
        path: "settings/*",
        element: <SettingsRoutes />,
      },
      {
        path: "smsMessages/*",
        element: <SmsMessagesRoutes />,
      },
      {
        path: "storageTypes/*",
        element: <StorageTypesRoutes />,
      },
      // T
      {
        path: "tags/*",
        element: <TagsRoutes />,
      },
      // U
      {
        path: "unitTags/*",
        element: <UnitTagsRoutes />,
      },
      {
        path: "units/*",
        element: <UnitsRoutes />,
      },
      {
        path: "unitTypes/*",
        element: <UnitTypesRoutes />,
      },
      {
        path: "usergroups/*",
        element: <UserGroupsRoutes />,
      },
      // V
      // W
      {
        path: "wizard",
        element: <Wizard />,
      },
      // X
      // Y
      // Z
    ],
  },
];
