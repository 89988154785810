import { ActionIcon, Button, Flex, Modal, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useEntityDeleteMutation } from "@/features/entity/mutations";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

interface DeleteFileProps {
  id: string;
  fileName: string;
}

export function DeleteFile({ id, fileName }: DeleteFileProps) {
  const { t } = useTranslation("features");
  const queryCache = useQueryClient();
  const [
    confirmationOpened,
    { open: openConfirmation, close: closeConfirmation },
  ] = useDisclosure(false);

  const handleQuickAction = () => {
    openConfirmation();
  };

  const { mutateAsync, isError: isDeleteError } = useEntityDeleteMutation({
    resourcePath: "/api/Attachments/DeleteAttachment/{id}",
    resourceId: id,
    queryKey: "deleteAttachment",
  });

  const deleteFile = async () => {
    try {
      await mutateAsync();
      void queryCache.invalidateQueries("attachment_list");
      closeConfirmation();
      if (!isDeleteError) {
        notifications.show({
          color: "blue",
          title: "Delete",
          message: "File deleted!",
        });
      }
    } catch (error) {
      notifications.show({
        color: "red",
        title: "Error",
        message: "An error occurred while deleting the file",
      });
    }
  };
  return (
    <>
      <ActionIcon
        variant="subtle"
        color="red"
        size="md"
        onClick={() => handleQuickAction()}
      >
        <IconX style={{ width: "60%", height: "60%" }} />
      </ActionIcon>

      <Modal
        opened={confirmationOpened}
        onClose={closeConfirmation}
        centered
        title={
          <Text fw={600} size="lg">
            {t("attachments.deleteTitle")}
          </Text>
        }
      >
        <Text size="sm">
          {`${t("appointments.deleteConfirmationQuestion")} ${fileName}?`}
        </Text>
        <Text mt="xs" fw={600} size="sm">
          {t("attachments.deleteConfirmationWarning")}
        </Text>
        <Flex gap="xs">
          <Button
            onClick={closeConfirmation}
            variant="outline"
            w={"48%"}
            mt={"xs"}
          >
            {t("attachments.deleteCancel")}
          </Button>
          <Button
            color="red"
            onClick={() => {
              void deleteFile();
            }}
            w={"48%"}
            mt={"xs"}
          >
            {t("attachments.deleteConfirmation")}
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
