import { Title, Text, Button, Container, Group } from "@mantine/core";
import classes from "./NotFound.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function NotFound() {
  const { t } = useTranslation("common");

  return (
    <Container className={classes.root}>
      <div
        className={classes.label}
        style={{ fontFamily: "var(--mantine-font-family-headings" }}
      >
        404
      </div>
      <Title
        style={{ fontFamily: "var(--mantine-font-family-headings" }}
        className={classes.title}
      >
        {t("notFound.title")}
      </Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        {t("notFound.description")}
      </Text>
      <Group justify="center">
        <Link to="/">
          <Button variant="subtle" size="md">
            {t("notFound.goBack")}
          </Button>
        </Link>
      </Group>
    </Container>
  );
}
