import UseUser, { type OCLeads } from "@/hooks/useUser";
import { type Schemas } from "@/types";
import { Center, Loader } from "@mantine/core";
import { type MRT_RowData, type MRT_TableInstance } from "mantine-react-table";
import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useState,
  type ReactNode,
} from "react";
import { useQueryClient } from "react-query";

interface UserContextType {
  finalUser?: Schemas["AppUserRetrieveDto"] | null;
  roles: string[];
  userUpdating: boolean;
  setUserUpdating: Dispatch<SetStateAction<boolean>>;
  OCLeadValue: string;
  OCLeadsValue: OCLeads[];
  setOCLeadsValue: Dispatch<SetStateAction<OCLeads[]>>;
  setOCLeadValue: Dispatch<SetStateAction<string>>;
  InitializeOCLeads: (table?: MRT_TableInstance<MRT_RowData> | null) => void;
  UpdateOmnichannelUser?: (
    userId: string | null,
    isActive: boolean,
    setUserUpdating?: (value: boolean) => void,
    table?: MRT_TableInstance<MRT_RowData> | null,
  ) => void;
  isLoading: boolean;
  isOmnichannelActive: boolean;
}

const defaultUserContext = {
  finalUser: undefined,
  isLoading: false,
  roles: [],
  OCLeadValue: "",
  OCLeadsValue: [],
  setOCLeadsValue: () => {
    console.warn(
      "Attempted to set OCDLeadValue without a LayoutVisibilityProvider",
    );
  },
  InitializeOCLeads: () => {
    console.warn(
      "Attempted to set OCDLeadValue without a LayoutVisibilityProvider",
    );
  },
  setOCLeadValue: () => {
    console.warn(
      "Attempted to set OCDLeadValue without a LayoutVisibilityProvider",
    );
  },
  UpdateOmnichannelUser: undefined,
  userUpdating: false,
  isOmnichannelActive: false,
  setUserUpdating: () => {
    console.warn(
      "Attempted to set userUpdating without a LayoutVisibilityProvider",
    );
  },
};

export const UserContext = createContext<UserContextType>(defaultUserContext);

interface UserContextProviderProps {
  children: ReactNode;
}
export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { finalUser, isLoading, mutate } = UseUser();
  const [userUpdating, setUserUpdating] = useState(false);
  const [OCLeadValue, setOCLeadValue] = useState("");
  const [OCLeadsValue, setOCLeadsValue] = useState<OCLeads[]>([]);
  const queryCache = useQueryClient();
  const roles = finalUser?.roles ?? [];
  if (isLoading) {
    return (
      <Center h="100%" w={"100%"}>
        <Loader />
      </Center>
    );
  }

  const InitializeOCLeads = (table?: MRT_TableInstance<MRT_RowData> | null) => {
    if (table) {
      const rows = table.getRowModel().rows;
      const OCLeads: OCLeads[] = [];
      if (rows.length > 0) {
        rows.forEach((row) => {
          const rowLead: Schemas["Lead"] = row.original;
          if (rowLead.id && rowLead.fullName) {
            OCLeads.push({
              label: rowLead.fullName,
              value: rowLead.id,
            });
          }
        });
        setOCLeadsValue(OCLeads);
      }
    }
  };

  const UpdateOmnichannelUser = (
    userId: string | null,
    isActive: boolean,
    setUserUpdating?: (value: boolean) => void,
    table?: MRT_TableInstance<MRT_RowData> | null,
  ) => {
    if (userId) {
      mutate(
        { omnichannelIsActive: isActive },
        {
          onSettled: () => {
            if (setUserUpdating) setUserUpdating(false);
            if (isActive) {
              InitializeOCLeads(table);
            } else {
              setOCLeadsValue([]);
            }
            void queryCache.invalidateQueries("lead_omnichannel_list");
          },
        },
      );
    }
  };

  const isOmnichannelActive = finalUser?.omnichannelIsActive ?? false;

  return (
    <UserContext.Provider
      value={{
        roles,
        finalUser,
        isLoading,
        UpdateOmnichannelUser,
        userUpdating,
        setUserUpdating,
        isOmnichannelActive,
        OCLeadValue,
        setOCLeadValue,
        OCLeadsValue,
        setOCLeadsValue,
        InitializeOCLeads,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
