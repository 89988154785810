import UseBusinessUnits from "@/hooks/businessUnit/useBusinessUnits";
import { Box, Button, Combobox, ScrollArea, useCombobox } from "@mantine/core";
import { useEffect, useState } from "react";
import classes from "./BusinessUnitSelect.module.css";
import { IconBuilding } from "@tabler/icons-react";

interface BusinessUnitSelectProps extends React.ComponentProps<typeof Button> {
  businessUnitId: string | null;
  setBusinessUnitId?: (
    businessUnitId: string | null,
    closeModal: boolean,
  ) => void;
  closeModal: boolean;
}

export function BusinessUnitSelect({
  businessUnitId,
  setBusinessUnitId,
  closeModal,
  ...props
}: BusinessUnitSelectProps) {
  const { businessUnits } = UseBusinessUnits();
  const [currentId, setCurrentId] = useState(businessUnitId);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const combobox = useCombobox({
    onDropdownClose: () => {
      setSearchTerm("");
      combobox.resetSelectedOption();
    },
    onDropdownOpen: () => combobox.focusSearchInput(),
  });

  useEffect(() => {
    setCurrentId(businessUnitId);
  }, [businessUnitId]);

  const businessUnitsFiltered = businessUnits.filter((bu) => {
    return bu.label.toLowerCase().includes(searchTerm.toLowerCase());
  });
  useEffect(() => {
    if (businessUnitsFiltered.length === 1) {
      combobox.selectFirstOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const options = businessUnitsFiltered.map((item, index) => (
    <Combobox.Option
      value={item.value}
      key={item.value}
      w={"100%"}
      active={item.value === currentId}
      ta={"center"}
      className={classes.option}
      mb={6}
      mt={6}
      onMouseOver={() => combobox.selectOption(index)}
    >
      {item.label}
    </Combobox.Option>
  ));

  const selectedBu = businessUnits.find((bu) => bu.value === currentId);
  const selectedLabel = selectedBu == undefined ? "-" : selectedBu.label;
  const comboboxTargetButton = (
    <Button
      {...props}
      variant="filled"
      color="white"
      c={"blue"}
      classNames={classes}
      leftSection={
        <IconBuilding color="#54a2de" width={18} height={18}></IconBuilding>
      }
      onClick={() => combobox.openDropdown()}
    >
      {selectedLabel.length > 7
        ? selectedLabel.substring(0, 7) + "..."
        : selectedLabel}
    </Button>
  );

  return (
    <Combobox
      store={combobox}
      width={200}
      withArrow
      position="bottom"
      shadow="md"
      onOptionSubmit={(value: string | null) => {
        if (setBusinessUnitId) {
          if (value === currentId) {
            setBusinessUnitId(null, closeModal);
          } else {
            setBusinessUnitId(value, closeModal);
          }
        } else {
          if (value === currentId) {
            setCurrentId(null);
          } else {
            setCurrentId(value);
          }
        }
        setSearchTerm("");
        combobox.resetSelectedOption();
        combobox.closeDropdown();
        localStorage.removeItem("selectedBusinessUnit");
      }}
    >
      <Combobox.Target>{comboboxTargetButton}</Combobox.Target>
      <Combobox.Dropdown mt={20}>
        <Combobox.Search
          w={"92%"}
          ml={"4%"}
          mb={"4%"}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.currentTarget.value)}
          placeholder="Search Business Units"
        />
        <ScrollArea.Autosize
          scrollbarSize={4}
          type="always"
          scrollbars={"y"}
          className={classes.scrollbar}
        >
          <Box h={"auto"} mah={500} w={"80%"} ml={"10%"}>
            <Combobox.Options>{options}</Combobox.Options>
          </Box>
        </ScrollArea.Autosize>
      </Combobox.Dropdown>
    </Combobox>
  );
}
