import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, Tooltip, Flex } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { IconCalendarCheck, IconCalendarUser } from "@tabler/icons-react";
import { type Schemas } from "@/types";
import { useEntityUpdateMutation } from "@/features/entity/mutations";
import { notifications } from "@mantine/notifications";
import { useQueryClient } from "react-query";

interface CloseAppointmentButtonsProps {
  currentState?: string;
  appointmentId?: string;
}

export function CloseAppointmentButtons({
  currentState,
  appointmentId,
}: CloseAppointmentButtonsProps) {
  const [
    confirmationOpened,
    { open: openConfirmation, close: closeConfirmation },
  ] = useDisclosure(false);
  const queryCache = useQueryClient();
  const [pendingState, setPendingState] = useState<
    "Open" | "Completed" | "NoShow" | "Cancelled" | undefined
  >(undefined);
  const { t } = useTranslation("features");
  const { mutate: update } = useEntityUpdateMutation<
    Schemas["Appointment"],
    Schemas["AppointmentPatchDto"]
  >({
    resourcePath: "/api/Appointments/{id}",
    resourceId: appointmentId ?? "",

    queryKey: "appointment",
  });

  const handleUpdateAppointmentState = (
    state: "Open" | "Completed" | "NoShow" | "Cancelled" | undefined,
  ) => {
    if (!state) return;

    update(
      {
        appointmentStatus: state,
        recordState: "Inactive",
      },
      {
        onSuccess: () => {
          notifications.show({
            color: "green",
            title: t("appointments.updateStateSuccessTitle"),
            message: t("appointments.updateStateSuccessMessage"),
          });
          void queryCache.invalidateQueries("appointment_list");
        },
        onError: () => {
          notifications.show({
            color: "red",
            title: t("appointments.updateStateErrorTitle"),
            message: t("appointments.updateStateErrorMessage"),
          });
        },
      },
    );
    closeConfirmation();
  };

  const handleQuickAction = (
    state: "Open" | "Completed" | "NoShow" | "Cancelled" | undefined,
  ) => {
    setPendingState(state);
    openConfirmation();
  };

  return (
    <>
      <Tooltip label={t("appointments.setAppointmentCompleted")}>
        <Button
          variant="outline"
          onClick={() => handleQuickAction("Completed")}
          disabled={currentState !== "Open"}
          size={"compact-md"}
        >
          <IconCalendarCheck size={"compact-md"} />
        </Button>
      </Tooltip>
      <Tooltip label={t("appointments.setAppointmentNoShow")}>
        <Button
          variant="outline"
          onClick={() => handleQuickAction("NoShow")}
          disabled={currentState !== "Open"}
          size={"compact-md"}
        >
          <IconCalendarUser size={"compact-md"} />
        </Button>
      </Tooltip>
      <Modal
        opened={confirmationOpened}
        onClose={closeConfirmation}
        centered
        title={
          pendingState == "Completed"
            ? t("appointments.confirmCompleted")
            : pendingState == "NoShow"
              ? t("appointments.confirmNoShow")
              : pendingState == "Cancelled"
                ? t("appointments.confirmCancelled")
                : ""
        }
      >
        <Flex gap="xs">
          <Button
            onClick={() => handleUpdateAppointmentState(pendingState)}
            w={"48%"}
            mt={"xs"}
          >
            {t("appointments.ok")}
          </Button>
          <Button
            onClick={closeConfirmation}
            variant="light"
            w={"48%"}
            mt={"xs"}
          >
            {t("appointments.cancelButton")}
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
