import React from "react";
import { MonthPickerInput, type DateValue } from "@mantine/dates";
import { IconChevronDown } from "@tabler/icons-react";

interface CustomMonthPickerInputProps {
  value: Date;
  onChange: (value: Date) => void;
}

export function CustomMonthPickerInput({
  value,
  onChange,
}: CustomMonthPickerInputProps) {
  const [opened, setOpened] = React.useState(false);

  const handleRightSectionClick = () => {
    setOpened((prev) => !prev);
  };

  return (
    <MonthPickerInput
      placeholder="Pick date"
      value={value}
      onChange={(value: DateValue) => {
        onChange(value!);
      }}
      rightSectionPointerEvents="auto"
      rightSection={
        <IconChevronDown onClick={handleRightSectionClick} size={16} />
      }
      popoverProps={{
        opened,
        onClose: () => setOpened(false),
      }}
      styles={(theme) => ({
        input: {
          fontWeight: 500,
          borderRadius: theme.radius.md,
        },
        root: {
          cursor: "pointer",
        },
      })}
      onClick={() => setOpened(true)}
    />
  );
}
