import { useUserContext } from "@/components/Layout/Contexts/User/useUserContext";
import { Button, Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

const timeout = 360_000;
const promptBeforeIdle = 60_000;

export function OmnichannelTimer() {
  const [idleOpened, { open, close }] = useDisclosure(false);
  const [state, setState] = useState<string>("Active");
  const [remaining, setRemaining] = useState<number>(0);
  const { UpdateOmnichannelUser, finalUser } = useUserContext();
  const navigate = useNavigate();

  const onIdle = () => {
    setState("Idle");
    if (UpdateOmnichannelUser) {
      UpdateOmnichannelUser(finalUser?.id ?? "", false);
      navigate("/app/leads");
      //for build errors keeping console log state.
      console.log(state);
      location.reload();
    }
  };

  const onActive = () => {
    setState("Active");
  };

  const onPrompt = () => {
    setState("Prompted");
    open();
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  const handleStillHere = () => {
    close();
    activate();
  };

  return (
    <Modal
      opened={idleOpened}
      onClose={handleStillHere}
      title="You have been idle for too long"
      centered
    >
      <Text ta={"center"}>
        You will be logged out of Omnichannel in {remaining} seconds
      </Text>
      <Button mt={20} ta={"center"} w="100%" onClick={handleStillHere}>
        Im back
      </Button>
    </Modal>
  );
}
