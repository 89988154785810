import { AppShell, Box, Center, Loader, ScrollArea } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { type PropsWithChildren } from "react";
import { Header } from "./Header";
import { Navbar } from "./Navbar";
import classes from "./MainLayout.module.css";
import BusinessUnitModal from "./BusinessUnitModal";
import UseUserBusinessUnit from "@/hooks/businessUnit/useUserBusinessUnit";
import { useLayoutVisibility } from "./Contexts/LayoutVisibility/LayoutVisibilityContext";
import UseNavBar from "@/hooks/useNavBar";

export function MainLayout({ children }: PropsWithChildren) {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  const { isHeaderVisible } = useLayoutVisibility();
  const { isLoading: isLoadingNavbar } = UseNavBar();
  const {
    showBusinessUnitModal,
    userBusinessUnitId,
    isLoading,
    updateUserBusinessUnit,
  } = UseUserBusinessUnit();

  if (isLoading || isLoadingNavbar) {
    return (
      <AppShell
        header={{ height: "6vh" }}
        navbar={{
          width: "200",
          breakpoint: "sm",
          collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
        }}
      >
        {isHeaderVisible && (
          <MainLayout.Header
            mobileOpened={mobileOpened}
            desktopOpened={desktopOpened}
            onClickMobile={toggleMobile}
            onClickDesktop={toggleDesktop}
            userBusinessUnitId={userBusinessUnitId!}
            setUserBusinessUnitId={updateUserBusinessUnit}
          />
        )}
        <MainLayout.Navbar onClickMobile={toggleMobile} />
        <AppShell.Main w={"100vw"} maw={"100vw"} h={"100vh"} mah={"100vh"}>
          <Center h={"100%"} w={"100%"}>
            <Loader />
          </Center>
        </AppShell.Main>
      </AppShell>
    );
  }

  return (
    <AppShell
      header={{ height: "6vh" }}
      navbar={{
        width: "200",
        breakpoint: "sm",
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
    >
      {isHeaderVisible && (
        <MainLayout.Header
          mobileOpened={mobileOpened}
          desktopOpened={desktopOpened}
          onClickMobile={toggleMobile}
          onClickDesktop={toggleDesktop}
          userBusinessUnitId={userBusinessUnitId!}
          setUserBusinessUnitId={updateUserBusinessUnit}
        />
      )}
      <MainLayout.Navbar onClickMobile={toggleMobile} />
      <ScrollArea
        h={"100vh"}
        w={"100vw"}
        maw={"100vw"}
        scrollbarSize={6}
        scrollbars="y"
        classNames={classes}
      >
        <AppShell.Main w={"100vw"} maw={"100vw"} h={"100vh"} mah={"100vh"}>
          {showBusinessUnitModal && (
            <BusinessUnitModal
              opened={showBusinessUnitModal}
              userBusinessUnitId={userBusinessUnitId!}
              updateUserBusinessUnit={updateUserBusinessUnit}
            />
          )}
          {!showBusinessUnitModal && (
            <Box mr={12} mt={4} key={`${userBusinessUnitId}`}>
              {children}
            </Box>
          )}
        </AppShell.Main>
      </ScrollArea>
    </AppShell>
  );
}

MainLayout.Header = Header;
MainLayout.Navbar = Navbar;
