import { type QueryKey } from "react-query";
import { type PathKeys } from "@/types";
import { useNavigate } from "react-router";
import { useEntityListQuery } from "../../queries";
import { type MutableRefObject, useState, type ReactNode } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import {
  type MRT_ColumnFilterFnsState,
  type MRT_ColumnFiltersState,
  type MRT_FilterOption,
  MantineReactTable,
  type MRT_ColumnDef,
  type MRT_RowData,
  type MRT_SortingState,
  useMantineReactTable,
  type MRT_TableInstance,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
} from "mantine-react-table";
import {
  Box,
  Center,
  type ComboboxItem,
  type ComboboxLikeRenderOptionInput,
  Flex,
  Group,
  RingProgress,
  Select,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { IconArrowDown, IconCheck, IconX } from "@tabler/icons-react";
import { type RingProgressSection } from "@/hooks/useListCommands";
import classes from "./TableMantine.module.css";
import { lowerCaseNthLetter } from "@/utils/filters";
import { useTranslation } from "react-i18next";
import { PossiblePriorities } from "../..";

interface BasicResponse<TData> {
  data?: TData[] | null;
  totalPages?: number;
  totalCount?: number;
}
interface BasicEntity {
  id?: string | null | undefined;
}

export interface ViewOption {
  value: string;
  label: string;
  default?: boolean;
  visibleColumns?: string[];
  filter: MRT_ColumnFiltersState;
  sorting?: MRT_SortingState | undefined;
}

interface TableProps {
  columns: MRT_ColumnDef<MRT_RowData>[];
  resourcePath: PathKeys;
  queryKey: QueryKey;
  entityPath: string;
  redirectTo?: string;
  disableNavigation?: boolean;
  pageSize?: number;
  selectionEnabled?: boolean;
  toolbarEnabled?: boolean;
  tableRef?: MutableRefObject<MRT_TableInstance<MRT_RowData> | null>;
  confirmed?: boolean;
  progressPercentage?: number;
  progressSections?: RingProgressSection[];
  visibleColumns?: string[];
  toolbar?: ReactNode;
  initialSorting?: MRT_SortingState | undefined;
  viewOptions?: ViewOption[];
  title?: string;
}

const PAGE_SIZE = 50;
const STARTING_PAGE_NUMBER = 0;

export function TableMantine<
  TData extends BasicEntity,
  TResponse extends BasicResponse<TData>,
>({
  columns,
  resourcePath,
  queryKey,
  entityPath,
  redirectTo,
  disableNavigation = false,
  pageSize,
  selectionEnabled = true,
  toolbarEnabled = true,
  tableRef,
  confirmed,
  progressPercentage = 0,
  progressSections,
  toolbar,
  visibleColumns = [],
  initialSorting,
  viewOptions,
  title,
}: TableProps) {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const [pagination, setPagination] = useState({
    pageIndex: STARTING_PAGE_NUMBER,
    pageSize: pageSize ?? PAGE_SIZE,
  });
  const defaultViewOption = viewOptions?.find((option) => option.default);
  const initialShowColumns: Record<string, boolean> = {};

  if (defaultViewOption?.visibleColumns?.length) {
    columns.forEach((column) => {
      initialShowColumns[column.accessorKey ?? ""] = false;
    });
    defaultViewOption.visibleColumns.forEach((columnKey) => {
      initialShowColumns[columnKey] = true;
    });
  } else if (visibleColumns.length > 0) {
    columns.forEach((column) => {
      initialShowColumns[column.accessorKey ?? ""] = false;
    });
    visibleColumns.forEach((column) => {
      initialShowColumns[column] = true;
    });
  }
  const [columnVisibility, setColumnVisibility] =
    useState<Record<string, boolean>>(initialShowColumns);

  const [isViewChanging, setIsViewChanging] = useState(false);
  const [sorting, setSorting] = useState<MRT_SortingState>(
    viewOptions?.filter((option) => option.default)[0]?.sorting ??
      initialSorting ??
      [],
  );

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    viewOptions?.filter((option) => option.default)[0]?.filter ?? [],
  );

  const [columnFilterFns, setColumnFilterFns] = // set default filter modes
    useState<MRT_ColumnFilterFnsState>(
      Object.fromEntries(
        columns.reduce(
          (
            results: (string | undefined)[][],
            { accessorKey, filterVariant },
          ) => {
            if (accessorKey) {
              switch (filterVariant) {
                case "text": {
                  results.push([accessorKey, "contains"]);
                  break;
                }
                case "date-range": {
                  results.push([accessorKey, "betweenInclusive"]);
                  break;
                }
                case "range": {
                  results.push([accessorKey, "betweenInclusive"]);
                  break;
                }
                case "multi-select": {
                  results.push([accessorKey, "equals"]);
                  break;
                }
                case "select": {
                  results.push([accessorKey, "equals"]);
                  break;
                }
                case "date": {
                  results.push([accessorKey, "betweenInclusive"]);
                  break;
                }
                default:
                  break;
              }
            }
            return results;
          },
          [],
        ),
      ) as MRT_ColumnFilterFnsState,
    );

  //Set available functions
  columns.forEach((column) => {
    switch (column.filterVariant) {
      case "text":
        column.columnFilterModeOptions = [
          "contains",
          "empty",
          "notEmpty",
          "equals",
          "notEquals",
          "startsWith",
          "endsWith",
        ];
        break;
      case "multi-select":
        column.columnFilterModeOptions = [
          // "empty", No current solution to determine if enum is nullable or not
          // "notEmpty",
          "equals",
          "notEquals",
        ];
        break;
      case "select":
        column.columnFilterModeOptions = [
          // "empty", No current solution to determine if enum is nullable or not
          // "notEmpty",
          "equals",
          "notEquals",
        ];
        break;
      default:
        column.columnFilterModeOptions = [
          // "empty", // Lookups do not support empty
          // "notEmpty",
          "equals",
          "notEquals",
        ];
        break;
    }
  });

  const [debouncedColumnFilters] = useDebouncedValue(columnFilters, 300);

  const getOperator = (operator: MRT_FilterOption | undefined) => {
    let returnOperator = "";
    //https://github.com/pdevito3/QueryKit
    switch (operator) {
      case "contains":
        returnOperator = "@=*";
        break;
      case "equals":
        returnOperator = "==*";
        break;
      case "notEquals":
        returnOperator = "!=*";
        break;
      case "startsWith":
        returnOperator = "_=*";
        break;
      case "endsWith":
        returnOperator = "_-=*";
        break;
      default:
        returnOperator = "@=*";
    }
    return returnOperator;
  };

  const getBetweenOperator = (
    operator: MRT_FilterOption | undefined,
    field: string,
    values: unknown[],
    appendAnd = true,
  ) => {
    let filter = "";
    //https://github.com/pdevito3/QueryKit
    switch (operator) {
      case "between":
        if (
          values[0] &&
          (typeof values[0] === "object" ||
            typeof values[0] === "string" ||
            typeof values[0] === "number")
        ) {
          filter +=
            (appendAnd ? " && " : " ") +
            field +
            " > " +
            JSON.stringify(values[0]);
        }
        if (
          values[1] &&
          (typeof values[1] === "object" ||
            typeof values[1] === "string" ||
            typeof values[0] === "number")
        ) {
          filter += " && " + field + " < " + JSON.stringify(values[1]);
        }
        break;
      case "betweenInclusive":
        if (
          values[0] &&
          (typeof values[0] === "object" ||
            typeof values[0] === "string" ||
            typeof values[0] === "number")
        ) {
          filter +=
            (appendAnd ? " && " : " ") +
            field +
            " >= " +
            JSON.stringify(values[0]);
        }
        if (
          values[1] &&
          (typeof values[1] === "object" ||
            typeof values[1] === "string" ||
            typeof values[0] === "number")
        ) {
          filter += " && " + field + " <= " + JSON.stringify(values[1]);
        }
        break;
      default:
        filter = "";
    }
    return filter;
  };

  const getFilterString = (
    columnFilters: MRT_ColumnFiltersState,
    columnFilterFns: MRT_ColumnFilterFnsState,
  ) => {
    let filterString = "";
    columnFilters.forEach((columnFilter) => {
      if (columnFilter.id) {
        const column = columns.filter((c) => c.id == columnFilter.id);
        const operator = columnFilterFns[columnFilter.id];
        if (column && column.length == 1) {
          if (
            columnFilter.id == "nextCallback" &&
            column[0]?.filterVariant == "date-range"
          ) {
            if (Array.isArray(columnFilter.value)) {
              const values = columnFilter.value;
              filterString += " && ( ";
              filterString += getBetweenOperator(
                operator,
                columnFilter.id,
                values,
                false,
              );
              filterString += " || " + columnFilter.id + " == null )";
            }
          } else {
            if (operator == "empty") {
              filterString +=
                " && " +
                columnFilter.id +
                " == null || " +
                columnFilter.id +
                ' == ""';
            } else if (operator == "notEmpty") {
              filterString +=
                " && " +
                columnFilter.id +
                " != null && " +
                columnFilter.id +
                ' != ""';
            } else {
              switch (column[0]?.filterVariant) {
                case "text": {
                  filterString +=
                    " && " +
                    columnFilter.id +
                    " " +
                    getOperator(operator) +
                    " " +
                    JSON.stringify(columnFilter.value);
                  break;
                }
                case "date-range": {
                  if (Array.isArray(columnFilter.value)) {
                    const values = columnFilter.value;
                    filterString += getBetweenOperator(
                      operator,
                      columnFilter.id,
                      values,
                    );
                  }
                  break;
                }
                case "range": {
                  if (Array.isArray(columnFilter.value)) {
                    const values = columnFilter.value;
                    filterString += getBetweenOperator(
                      operator,
                      columnFilter.id,
                      values,
                    );
                  }
                  break;
                }
                case "multi-select": {
                  let multiFilter = "";
                  if (Array.isArray(columnFilter.value)) {
                    const values = columnFilter.value;
                    values.forEach((value) => {
                      if (value && typeof value === "string") {
                        multiFilter +=
                          (operator == "equals" ? " || " : " && ") +
                          columnFilter.id +
                          " " +
                          getOperator(operator) +
                          " " +
                          JSON.stringify(value);
                      }
                    });
                  }
                  if (
                    multiFilter.startsWith(" || ") ||
                    multiFilter.startsWith(" && ")
                  ) {
                    filterString += " && (" + multiFilter.slice(4) + ")";
                  }
                  break;
                }
                case "select": {
                  if (
                    columnFilter.value &&
                    typeof columnFilter.value === "string"
                  ) {
                    filterString +=
                      " && " +
                      columnFilter.id +
                      " " +
                      getOperator(operator) +
                      " " +
                      JSON.stringify(columnFilter.value);
                  }
                  break;
                }
                default:
                  //For Lookup columns
                  filterString +=
                    " && " +
                    columnFilter.id +
                    ".id " + //Filter by Id attribute
                    getOperator(operator) +
                    " " +
                    JSON.stringify(columnFilter.value);
                  break;
              }
            }
          }
        }
      }
    });
    if (filterString.startsWith(" && ")) {
      filterString = filterString.slice(4);
    }
    return filterString;
  };

  const getQueryParams = (
    pageIndex: number,
    pageSize: number,
    sorting: MRT_SortingState,
    columnFilters: MRT_ColumnFiltersState,
    columnFilterFns: MRT_ColumnFilterFnsState,
    searchBy?: string, // Add searchBy as an optional parameter
  ) => {
    const filterText = getFilterString(columnFilters, columnFilterFns);
    return {
      pageNumber: pageIndex,
      orderBy: sorting[0]?.id,
      desc: sorting[0]?.desc,
      pageSize: pageSize,
      filter: filterText || undefined,
      searchBy: searchBy || undefined,
    };
  };

  const { data, isLoading, isFetching } = useEntityListQuery<TResponse>({
    resourcePath,
    queryKey,
    params: getQueryParams(
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      debouncedColumnFilters,
      columnFilterFns,
      debouncedSearchTerm,
    ),
  });

  const rowData = data?.data ?? [];

  const onViewChange = (value: string | null) => {
    setIsViewChanging(true);
    const selectedViewOption = viewOptions?.find(
      (viewOption) => viewOption.value === value,
    );
    if (selectedViewOption) {
      setColumnFilters(selectedViewOption.filter ?? []);
      setSorting(selectedViewOption.sorting ?? []);
      if (selectedViewOption.visibleColumns?.length) {
        const newColumnVisibility: Record<string, boolean> = {};
        columns.forEach((column) => {
          newColumnVisibility[column.accessorKey ?? ""] = false;
        });
        selectedViewOption.visibleColumns.forEach((columnKey) => {
          newColumnVisibility[columnKey] = true;
        });
        setColumnVisibility(newColumnVisibility);
      }
    }
    setIsViewChanging(false);
  };
  const filterRenderOption = (
    item: ComboboxLikeRenderOptionInput<ComboboxItem>,
  ) => {
    const label = item.option.label;
    const shouldTranslate = !PossiblePriorities.includes(label);

    return (
      <Box w="100%" ta={"center"}>
        {shouldTranslate
          ? t(entityPath + "." + lowerCaseNthLetter(label))
          : label}
      </Box>
    );
  };

  const { t } = useTranslation("features");
  const table = useMantineReactTable({
    columns: columns,
    data: rowData,
    enableColumnFilterModes: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableGlobalFilter: false,
    enableFacetedValues: true,
    columnFilterDisplayMode: "popover",
    enableColumnPinning: false,
    enableTopToolbar: toolbarEnabled,
    enableBottomToolbar:
      data?.totalPages !== undefined && data?.totalPages > 1 ? true : false,
    enableColumnActions: false,
    enableRowSelection: selectionEnabled,
    layoutMode: "grid",
    initialState: {
      density: "xs",
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
      sorting: initialSorting,
      columnFilters: columnFilters,
      columnVisibility: initialShowColumns,
    },
    defaultColumn: { minSize: 40, maxSize: 1000, size: 80 },
    displayColumnDefOptions: {
      "mrt-row-select": {
        size: 40,
        grow: false,
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableToolbarInternalActions: false,
    renderTopToolbar: ({ table }) => (
      <Group w={"100%"} justify="space-between" grow m={3}>
        <Flex justify="flex-start" align="center" direction="row" wrap="wrap">
          {viewOptions === undefined ? (
            <Select
              rightSection={<></>}
              leftSection={<></>}
              classNames={classes}
              data={[
                {
                  value: title ?? "",
                  label: title ?? "",
                },
              ]}
              defaultValue={title}
              allowDeselect={false}
              onChange={onViewChange}
            />
          ) : (
            <Select
              rightSection={<></>}
              leftSection={
                <IconArrowDown
                  size={22}
                  fontWeight={"bolder"}
                  color="#228be6"
                />
              }
              leftSectionPointerEvents="none"
              classNames={classes}
              data={viewOptions}
              defaultValue={
                viewOptions?.filter((option) => option.default)[0]?.value ?? ""
              }
              allowDeselect={false}
              onChange={onViewChange}
            />
          )}
          <TextInput
            placeholder="Search..."
            value={searchTerm}
            classNames={classes}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
          />
        </Flex>
        <Flex
          gap={"xs"}
          justify="right"
          align="center"
          direction="row"
          wrap="wrap"
        >
          {toolbar}
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleFullScreenButton table={table} mr={20} />
        </Flex>
      </Group>
    ),
    mantineFilterSelectProps: {
      renderOption: filterRenderOption,
    },
    mantineFilterMultiSelectProps: {
      renderOption: filterRenderOption,
    },
    mantinePaginationProps: {
      radius: "xl",
      size: "md",
      rowsPerPageOptions: [
        PAGE_SIZE.toString(),
        (PAGE_SIZE * 2).toString(),
        (PAGE_SIZE * 4).toString(),
      ],
    },
    manualPagination: true,
    manualSorting: false,
    rowCount: data?.totalCount,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualFiltering: true,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      pagination,
      sorting,
      columnFilterFns,
      columnFilters,
      columnVisibility,
      isLoading: isLoading || isFetching || isViewChanging,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {
        if (!disableNavigation && row?.original?.id) {
          navigate(
            redirectTo
              ? `/app/${entityPath}/${row?.original?.id}?redirectTo=${redirectTo}`
              : `/app/${entityPath}/${row?.original?.id}`,
          );
        }
      },
    }),
  });
  if (table !== null && tableRef !== undefined) {
    tableRef.current = table;
  }

  let color = "black";
  if (progressSections !== undefined) {
    if (progressSections.length !== 0) {
      if (progressSections[0] !== undefined) {
        if (progressSections[1] !== undefined) {
          color =
            progressSections[0].value > progressSections[1].value
              ? "red"
              : "teal";
        } else {
          color = "red";
        }
      } else {
        if (progressSections[1] !== undefined) {
          color = "teal";
        }
      }
    }
  }

  return (
    <>
      {confirmed && (
        <Center>
          <RingProgress
            sections={progressSections!}
            size={400}
            thickness={20}
            label={
              progressPercentage == 100 ? (
                <Center>
                  {color == "red" ? (
                    <IconX
                      color={color}
                      style={{ width: rem(106), height: rem(106) }}
                    />
                  ) : (
                    <IconCheck
                      color={color}
                      style={{ width: rem(106), height: rem(106) }}
                    />
                  )}
                </Center>
              ) : (
                <Text c={color} fw={700} ta="center" size={"lg"}>
                  {`${progressPercentage}%`}
                </Text>
              )
            }
          />
        </Center>
      )}
      {!confirmed && (
        <Box style={{ pointerEvents: "auto" }} mb={10}>
          <MantineReactTable table={table} />
        </Box>
      )}
    </>
  );
}
