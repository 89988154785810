import { type PathKeys } from "@/types";
import { type MRT_RowData, type MRT_TableInstance } from "mantine-react-table";
import { type MutableRefObject, type ReactNode } from "react";

export function getDirtyFormFields<T extends Record<string, unknown>>(
  fields: T,
  isCreateForm: boolean,
  predicate: (key: string) => boolean,
) {
  if (isCreateForm) return fields;
  return Object.entries(fields)
    .filter(([key, _]) => predicate(key))
    .reduce((acc, [key, value]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      acc[key] = value;
      return acc;
    }, {} as Partial<T>);
}

export const recordState = ["Active", "Inactive"];

export function GetResourcePath(
  entityName: string | undefined,
  entityId: string | undefined,
  path: string,
): PathKeys {
  if (entityId === undefined || entityName === undefined) {
    return `/api/${path}` as PathKeys;
  }
  if (entityId === null || entityName === null) {
    throw new Error(
      "entityId and entityName can not be null, leave it undefined",
    );
  }
  return `/api/${entityName}/${entityId}/${path}` as PathKeys;
}

export function GetCreatePath(
  entityId: string | undefined,
  entityParam: string | undefined,
  path: string,
): string {
  if (entityParam === undefined) {
    return `/app/${path}/create`;
  } else {
    return `/app/${path}/create?${entityParam}=${entityId}&redirectTo=${window.location.pathname}`;
  }
}

export interface OutterListProps {
  visibleColumns?: string[];
  parentEntityName?: string;
  parentEntityId?: string;
  parentEntityIdParam?: string;
  hideCreate?: boolean;
  toolbarExtension?: ReactNode;
  toolbarOverride?: ReactNode;
  queryKey?: string;
  pageSize?: number;
  setTableRef?: (
    ref: MutableRefObject<MRT_TableInstance<MRT_RowData> | null> | null,
  ) => void;
}

export interface InnerListProps {
  visibleColumns?: string[];
  resourcePath: PathKeys;
  createPath: string;
  parentEntityId?: string;
  parentEntityName?: string;
  hideCreate?: boolean;
  toolbarExtension?: ReactNode;
  toolbarOverride?: ReactNode;
  queryKey?: string;
  pageSize?: number;
  setTableRef?: (
    ref: MutableRefObject<MRT_TableInstance<MRT_RowData> | null> | null,
  ) => void;
}
