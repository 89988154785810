import { type Dispatch, type SetStateAction, useRef, useState } from "react";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { zodResolver } from "mantine-form-zod-resolver";
import {
  ActionIcon,
  Box,
  Flex,
  ScrollArea,
  Textarea,
  Title,
  rem,
} from "@mantine/core";
import { IconClock } from "@tabler/icons-react";
import { PhoneCallStatus } from "@/types/enums";
import { DateInput, TimeInput } from "@mantine/dates";
import { LeadLookupField } from "@/features/leads";
import { ContactLookupField } from "@/features/contacts";
import { type ReactNode } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { config } from "@/config";
import { getDirtyFormFields, recordState } from "@/features/entity/utils";
import { type Schemas } from "@/types";
import { ClosePhoneCallButtons } from "./ClosePhoneCallButtons";
import { PhoneCallLookupField } from "./PhoneCallLookupField";

const phoneCallStatusEnum = z.enum(PhoneCallStatus as [string]);
const recordStateEnum = z.enum(recordState as [string]);

const formSchema = z.object({
  id: z.string(),
  description: z.string(),
  startDate: z.date().nullable(),
  endDate: z.date().nullable(),
  callbackDate: z.date().nullable(),
  leadId: z.string().nullable(),
  lead: z.object({}).nullable(),
  contactId: z.string().nullable(),
  contact: z.object({}).nullable(),
  callbackId: z.string().nullable(),
  callback: z.object({}).nullable(),
  phoneCallStatus: phoneCallStatusEnum.refine((value) => !!value),
  recordState: recordStateEnum.refine((value) => !!value),
});

export type FormSchema = z.infer<typeof formSchema>;

interface PhoneCallFormProps {
  onSubmit: (values: Partial<FormSchema>) => void;
  onDelete?: VoidFunction;
  actionSection?: ReactNode;
  initialValues?: FormSchema;
  isCreate: boolean;
  showBackButton?: boolean;
  closeModal?: () => void;
  isModal?: boolean;
  setPhantomEvent?: Dispatch<SetStateAction<Schemas["PhoneCall"] | null>>;
}

export function PhoneCallForm({
  onSubmit,
  initialValues,
  isCreate,
  isModal,
}: PhoneCallFormProps) {
  const { t } = useTranslation("features");
  const debouncedOnSubmit = useDebounceCallback(onSubmit, 500);
  const refStart = useRef<HTMLInputElement>(null);
  const [formKey, setFormKey] = useState(0);
  const resetForm = () => {
    setFormKey((prev) => prev + 1);
  };
  const form = useForm<FormSchema>({
    initialValues: {
      id: initialValues?.id ?? "",
      description: initialValues?.description ?? "",
      startDate: initialValues?.startDate ?? null,
      endDate: initialValues?.endDate ?? null,
      callbackDate: initialValues?.callbackDate ?? null,
      leadId: initialValues?.leadId ?? "",
      lead: initialValues?.lead ?? null,
      contactId: initialValues?.contactId ?? "",
      contact: initialValues?.contact ?? null,
      callbackId: initialValues?.callbackId ?? "",
      callback: initialValues?.callback ?? null,
      phoneCallStatus: initialValues?.phoneCallStatus ?? "",
      recordState: initialValues?.recordState ?? "",
    },
    initialDirty: { startDate: true },
    validate: zodResolver(formSchema),
  });

  const isFormDisabled =
    !isCreate && form.getInputProps("recordState").value === "Inactive";
  const timeControl = (
    <ActionIcon
      variant="subtle"
      color="gray"
      onClick={() => refStart.current?.showPicker()}
    >
      <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
    </ActionIcon>
  );

  return (
    <form
      key={formKey}
      onSubmit={form.onSubmit((fields) => {
        const filteredFields = getDirtyFormFields(
          fields,
          isCreate,
          form.isDirty,
        );
        filteredFields.phoneCallStatus = form.values.phoneCallStatus;
        filteredFields.recordState = form.values.recordState;
        debouncedOnSubmit(filteredFields);
      })}
    >
      <Flex justify="space-between" align="center" direction="row">
        <Title order={3}>
          {isCreate
            ? t("phoneCalls.createPhoneCall")
            : t("phoneCalls.editPhoneCall")}
        </Title>
      </Flex>
      <ScrollArea
        scrollbarSize={4}
        type="hover"
        mah={
          document.location.pathname.includes("app/phoneCalls")
            ? "74vh"
            : isModal
              ? "74vh"
              : "56vh"
        }
      >
        <Box
          mr={10}
          style={
            isFormDisabled ? { pointerEvents: "none", opacity: "0.6" } : {}
          }
        >
          <Flex gap={"sm"}>
            <LeadLookupField
              flex={1}
              mt="sm"
              label={t("phoneCalls.lead")}
              initial={form.getValues().lead}
              {...form.getInputProps("leadId")}
              {...{ labelProps: { style: { flex: "1.5" } } }}
            />
            <ContactLookupField
              flex={1}
              disabled={form.getValues().leadId ? true : false}
              mt="sm"
              label={t("phoneCalls.contact")}
              initial={form.getValues().contact}
              {...form.getInputProps("contactId")}
              {...{ labelProps: { style: { flex: "1.5" } } }}
            />
          </Flex>
          <Flex gap={"sm"}>
            <DateInput
              flex={2}
              mt="sm"
              valueFormat={config.DATES.dateFormat}
              clearable
              minDate={new Date()}
              label={t("phoneCalls.startDate")}
              {...form.getInputProps("startDate")}
              onChange={(value) => {
                form.setFieldValue("startDate", value);
                form.setFieldValue("endDate", value);
              }}
              {...{ labelProps: { style: { flex: "1.5" } } }}
            />
            <TimeInput
              mt="sm"
              flex={2}
              defaultValue={form.values.startDate?.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })}
              onChange={(event) => {
                if (event.target.value !== undefined) {
                  const value = event.target.value.split(":");
                  if (value.length == 2) {
                    const hours = parseInt(value[0] ?? "");
                    const minutes = parseInt(value[1] ?? "");
                    form.values.startDate?.setHours(hours, minutes);
                    form.values.endDate?.setHours(hours, minutes);
                    resetForm();
                  }
                }
              }}
              label={"Start Time"}
              ref={refStart}
              rightSection={timeControl}
              {...{ labelProps: { style: { flex: "1.5" } } }}
            />
          </Flex>

          <Flex gap={"sm"}>
            <DateInput
              flex={2}
              mt="sm"
              valueFormat={config.DATES.dateFormat}
              clearable
              minDate={form.values.startDate ?? new Date()}
              label={t("phoneCalls.callbackDate")}
              {...form.getInputProps("callbackDate")}
              onChange={(value) => {
                if (value) {
                  const callbackDate = new Date(value);
                  const startDate = form.values.startDate;

                  if (startDate) {
                    callbackDate.setHours(
                      startDate.getHours(),
                      startDate.getMinutes(),
                    );
                  }
                  form.setFieldValue("callbackDate", callbackDate);
                }
              }}
              {...{ labelProps: { style: { flex: "1.5" } } }}
            />
            <TimeInput
              mt="sm"
              flex={2}
              minTime={
                form.values.startDate?.getHours() +
                ":" +
                form.values.startDate?.getMinutes()
              }
              defaultValue={form.values.callbackDate?.toLocaleTimeString(
                "en-US",
                {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                },
              )}
              onChange={(event) => {
                if (event.target.value !== undefined) {
                  const value = event.target.value.split(":");
                  if (value.length == 2) {
                    const hours = parseInt(value[0] ?? "");
                    const minutes = parseInt(value[1] ?? "");
                    form.values.callbackDate?.setHours(hours, minutes);
                    resetForm();
                  }
                }
              }}
              label={"Time"}
              ref={refStart}
              rightSection={timeControl}
              {...{ labelProps: { style: { flex: "1.5" } } }}
            />
          </Flex>
          <PhoneCallLookupField
            flex={1}
            disabled
            mt="sm"
            label={t("phoneCalls.callback")}
            initial={form.getValues().callback}
            {...form.getInputProps("callbackId")}
            {...{ labelProps: { style: { flex: "0.675" } } }}
          />

          <Textarea
            mt="sm"
            label={t("phoneCalls.description")}
            {...form.getInputProps("description")}
            minRows={4}
            autosize
            {...{ labelProps: { style: { flex: "0.675" } } }}
          />
        </Box>
      </ScrollArea>
      <Flex
        mt={10}
        mr={10}
        gap="xs"
        justify="center"
        align="center"
        direction="row"
        wrap="nowrap"
      >
        <ClosePhoneCallButtons
          currentState={form.values.phoneCallStatus}
          isCreate={isCreate}
          setPhoneCallStatus={(newState) =>
            form.setFieldValue("phoneCallStatus", newState)
          }
          setRecordState={(newState) =>
            form.setFieldValue("recordState", newState)
          }
          submitForm={(onlyPartial) =>
            form.onSubmit((values) => {
              if (onlyPartial) {
                const partialValues = {
                  phoneCallStatus: values.phoneCallStatus,
                  recordState: values.recordState,
                  callbackDate: values.callbackDate,
                };
                onSubmit(partialValues);
              } else {
                onSubmit(values);
              }
            })()
          }
        ></ClosePhoneCallButtons>
      </Flex>
    </form>
  );
}
