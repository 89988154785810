import { useCallback } from "react";
import { useInView } from "./useInView";

export function useInfiniteScroll(
  callback: () => void | Promise<void>,
  isActive = true,
) {
  const infiniteScrollCallback = useCallback(() => {
    if (!isActive) {
      return;
    }

    void callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const useInfiniteScrollRef = useInView(infiniteScrollCallback);

  return useInfiniteScrollRef;
}
