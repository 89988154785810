import { IconRefresh } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ButtonMain from "../ButtonMain/ButtonMain";
interface RefreshButtonProps {
  clicked?: () => void;
}

export function RefreshButton({ clicked }: RefreshButtonProps) {
  const { t } = useTranslation("features");

  return (
    <ButtonMain
      label={t("entity.refresh")}
      icon={<IconRefresh size={18} />}
      onClick={clicked}
    />
  );
}
