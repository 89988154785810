import { withAuthenticationRequired } from "@auth0/auth0-react";
import { type ComponentType } from "react";
import { PageLoader } from "@/components/PageLoader";

export function AuthGuard({ component }: { component: ComponentType }) {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <PageLoader />,
  });

  return <Component />;
}
