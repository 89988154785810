import { Box, Button, Center, Container, Group, Title } from "@mantine/core";

export function ErrorFallback() {
  const refreshPage = () => window.location.assign(window.location.origin);

  return (
    <Container>
      <Center h="90vh">
        <Box>
          <Title order={2}>Ooops, something went wrong :(</Title>
          <Group justify="center" mt="lg">
            <Button onClick={refreshPage}>Refresh</Button>
          </Group>
        </Box>
      </Center>
    </Container>
  );
}
