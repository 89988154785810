import { type QueryParams } from "@/features/entity/api";
import { useEntityListQuery } from "@/features/entity/queries";
import { type Schemas } from "@/types";
import {
  Center,
  Flex,
  Loader,
  Pagination,
  ScrollArea,
  TextInput,
  MultiSelect,
  Stack,
  Checkbox,
  Radio,
  Button,
  Grid,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { AppointmentType, BusinessUnitTypeFilter } from "@/types/enums";

import classes from "./appointmentBuDrawer.module.css";
import { useUserContext } from "@/components/Layout/Contexts/User/useUserContext";
import { useSettingsContext } from "@/components/Layout/Contexts/Settings/useSettingsContext";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { IconX } from "@tabler/icons-react";

interface AppointmentBuDrawerProps {
  values: string[];
  setValues: Dispatch<SetStateAction<string[]>>;
  appointmentTypes: string[];
  setAppointmentTypes: Dispatch<SetStateAction<string[]>>;
  appointmentSearch: string;
  setAppointmentSearch: Dispatch<SetStateAction<string>>;
  businessUnitTypes: string[];
  setBusinessUnitTypes: Dispatch<SetStateAction<string[]>>;
  defaultBU: string;
  leadId?: string;
  businessUnitNames: string[];
  setBusinessUnitNames: Dispatch<SetStateAction<string[]>>;
}

export function AppointmentBuDrawer({
  values,
  setValues,
  appointmentTypes,
  setAppointmentTypes,
  appointmentSearch,
  setAppointmentSearch,
  businessUnitTypes,
  setBusinessUnitTypes,
  defaultBU,
  leadId,
  businessUnitNames,
  setBusinessUnitNames,
}: AppointmentBuDrawerProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const [activePage, setPage] = useState(0);
  const { roles } = useUserContext();
  const isSalesAndServiceUser = roles.includes("Sales");
  const queryClient = useQueryClient();
  const { t } = useTranslation("features");
  const [activeBU, setActiveBU] = useState<string[]>(values);

  let queryParams: QueryParams = {
    pageSize: 50,
    pageNumber: activePage,
  };

  if (debouncedSearchTerm) {
    queryParams = {
      pageSize: undefined,
      searchBy: debouncedSearchTerm,
    };
  }

  const handleAppointmentSearch = (val: string) => {
    setAppointmentSearch(val);
  };

  const handleAppointmentTypeSelect = (val: string[]) => {
    setAppointmentTypes(val);
  };

  const handleBusinessUnitTypeSelect = (selectedBusinessUnit: string[]) => {
    setBusinessUnitTypes(selectedBusinessUnit);
  };

  const handleBusinessUnitTypeReset = () => {
    setValues([defaultBU]);
    setActiveBU([defaultBU]);
    if (leadId == null) localStorage.removeItem("selectedBusinessUnit");
    void queryClient.invalidateQueries("appointment_list");
    setBusinessUnitTypes([]);
    setAppointmentSearch("");
    setAppointmentTypes([]);
  };

  const { data, isFetching, isLoading } = useEntityListQuery<
    Schemas["BusinessUnitRetrieveDtoPagedList"]
  >({
    resourcePath: "/api/BusinessUnits",
    queryKey: "businessUnit",
    params: queryParams,
  });

  const { MovingHelpGroups, isLoading: settingsLoading } = useSettingsContext();

  const businessUnits =
    data?.data
      ?.flatMap((page) => page ?? [])
      .filter(
        (unit) =>
          unit.isHidden != true &&
          ((unit.type && businessUnitTypes.includes(unit.type)) ||
            (unit.type === null && businessUnitTypes.includes("SelfStorage")) ||
            (businessUnitTypes.length === 0 && unit.type !== "Other")),
      ) ?? [];
  const allBusinessUnits = businessUnits;

  MovingHelpGroups?.forEach((x) => {
    if (
      businessUnitTypes.includes("SelfStorage") ||
      businessUnitTypes.length === 0
    ) {
      allBusinessUnits.push({
        id: x.id,
        code: x.name,
      });
    }
  });

  const selectedBusinessUnits = allBusinessUnits.filter((x) => {
    return activeBU.includes(x.id!);
  });

  useEffect(() => {
    const selectedBusinessUnitNames = selectedBusinessUnits
      .map((unit) => unit.name ?? unit.code)
      .filter((name): name is string => typeof name === "string");

    if (
      businessUnitNames.length !== selectedBusinessUnitNames.length ||
      !businessUnitNames.every(
        (name, index) => name === selectedBusinessUnitNames[index],
      )
    ) {
      setBusinessUnitNames(selectedBusinessUnitNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinessUnits]);

  if (isFetching || isLoading || settingsLoading) {
    return (
      <Flex justify="center" align="center" h={"100%"}>
        <Loader />
      </Flex>
    );
  }

  const BUListSelected = () => {
    return (
      <Checkbox.Group
        value={activeBU}
        onChange={(value) => {
          setActiveBU(value);
          setValues(value);
        }}
        my={8}
      >
        <Grid justify="flex-start" grow gutter="sm" align="stretch">
          {selectedBusinessUnits.map((x) => (
            <Grid.Col span={6} key={x.code} style={{ cursor: "pointer" }}>
              <Checkbox
                icon={IconX}
                classNames={{
                  root: classes.selectedBuList,
                  inner: classes.selectedBuListInput,
                  body: classes.selectedBuListBody,
                  input: classes.selectedBuListBody,
                  label: classes.selectedBuListBody,
                }}
                value={x.id}
                label={x.code}
                radius="md"
                size="xs"
                h="100%"
              />
            </Grid.Col>
          ))}
        </Grid>
      </Checkbox.Group>
    );
  };
  const BUListAll = () => {
    if (isSalesAndServiceUser) {
      // Single selection using Radio.Group
      return (
        <Radio.Group
          value={activeBU[0] || ""}
          onChange={(value) => {
            const selectedValue = value ? [value] : [];
            setActiveBU(selectedValue);
            setValues(selectedValue);
          }}
        >
          <Stack>
            {allBusinessUnits.map((x) => (
              <label key={x.code} style={{ width: "100%" }}>
                <Radio
                  w={"100%"}
                  classNames={classes}
                  value={x.id}
                  label={x.code}
                  radius="xl"
                  size="md"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (activeBU[0] === x.id) {
                      // Deselect the radio button
                      setActiveBU([]);
                      setValues([]);
                    } else {
                      // Select the radio button
                      setActiveBU([x.id!]);
                      setValues([x.id!]);
                    }
                  }}
                />
              </label>
            ))}
          </Stack>
        </Radio.Group>
      );
    } else {
      return (
        <Checkbox.Group
          value={activeBU}
          onChange={(value) => {
            setActiveBU(value);
            setValues(value);
          }}
        >
          <Stack>
            {allBusinessUnits.map((x) => (
              <label key={x.code} style={{ width: "100%" }}>
                <Checkbox
                  w={"100%"}
                  classNames={classes}
                  value={x.id}
                  label={x.code}
                  radius="xl"
                  size="sm"
                />
              </label>
            ))}
          </Stack>
        </Checkbox.Group>
      );
    }
  };
  return (
    <>
      <Stack gap={8} mt={8} style={{ maxHeight: "calc(96vh - 40px)" }}>
        <Stack
          justify="center"
          gap={4}
          style={{
            border: "2px solid #f5f4f3",
            borderRadius: "8px",
            padding: "8px",
            backgroundColor: "#fdfdfd",
          }}
        >
          <Center>{t("appointments.appointmentTypeFilter")}</Center>
          <MultiSelect
            data={AppointmentType}
            value={appointmentTypes}
            onChange={handleAppointmentTypeSelect}
            clearable
            hidePickedOptions
          />
          <TextInput
            value={appointmentSearch}
            onChange={(event) =>
              handleAppointmentSearch(event.currentTarget.value)
            }
            placeholder={t("appointments.searchAppointments")}
            autoFocus
          />
        </Stack>
        <Stack
          justify="center"
          gap={4}
          style={{
            border: "2px solid #f5f4f3",
            borderRadius: "8px",
            padding: "8px",
            backgroundColor: "#fdfdfd",
          }}
        >
          <Center>{t("appointments.businessUnitTypeFilter")}</Center>
          <MultiSelect
            data={BusinessUnitTypeFilter}
            value={businessUnitTypes ? businessUnitTypes : []}
            onChange={handleBusinessUnitTypeSelect}
            clearable
            hidePickedOptions
          />
          <TextInput
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.currentTarget.value)}
            placeholder={t("appointments.searchBusinessUnits")}
            autoFocus
          />
        </Stack>
        <Stack
          justify="center"
          gap={2}
          style={{
            border: "2px solid #f5f4f3",
            borderRadius: "8px",
            padding: "8px",
            backgroundColor: "#fdfdfd",
          }}
        >
          <Center>{t("appointments.selectedBUs")}</Center>
          <ScrollArea.Autosize
            type="hover"
            mih={"4vh"}
            mah={"12vh"}
            maw={"16vw"}
            miw={"4vw"}
            scrollbars="y"
            scrollbarSize={8}
            offsetScrollbars={true}
          >
            {BUListSelected()}
          </ScrollArea.Autosize>
        </Stack>

        <ScrollArea.Autosize
          type="hover"
          mih={"24vh"}
          mah={"100vh"}
          maw={"16vw"}
          miw={"4vw"}
          scrollbars="y"
          scrollbarSize={8}
          offsetScrollbars={true}
          style={{
            overflow: "hidden",
            border: "2px solid #f5f4f3",
            borderRadius: "8px",
            padding: "16px",
            backgroundColor: "#fdfdfd",
          }}
        >
          {BUListAll()}
        </ScrollArea.Autosize>

        <Stack justify="center" align="stretch">
          <Button
            size="xs"
            variant={"light"}
            my={4}
            onClick={handleBusinessUnitTypeReset}
          >
            {t("appointments.resetFilters")}
          </Button>
          <Flex justify="center" align="center" direction="column" wrap="wrap">
            <Pagination
              value={activePage + 1}
              size="xs"
              boundaries={1}
              siblings={1}
              total={data?.totalPages ?? 1}
              onChange={(value) => {
                setPage(value - 1);
              }}
            />
          </Flex>
        </Stack>
      </Stack>
    </>
  );
}
