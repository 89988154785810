const storagePrefix = "unitLogic_";

export const storage = {
  setLanguage: (language: string) => {
    window.localStorage.setItem(
      `${storagePrefix}language`,
      JSON.stringify(language),
    );
  },
  getLanguage: () => {
    const language = window.localStorage.getItem(`${storagePrefix}language`);
    return language ? JSON.parse(language) : "en";
  },
};
