import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, Tooltip, Flex } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { IconCalendarX, IconPhoneCheck } from "@tabler/icons-react";
import { IconPhoneX } from "@tabler/icons-react";

interface ClosePhoneCallButtonsProps {
  currentState?: string;
  isCreate?: boolean;
  setPhoneCallStatus?: (
    state: "Open" | "Reached" | "NotReached" | "Cancelled",
  ) => void;
  setRecordState?: (state: "Inactive") => void;
  submitForm?: (onlyPartial: boolean) => void;
}

export function ClosePhoneCallButtons({
  currentState,
  isCreate,
  setPhoneCallStatus,
  setRecordState,
  submitForm,
}: ClosePhoneCallButtonsProps) {
  const [
    confirmationOpened,
    { open: openConfirmation, close: closeConfirmation },
  ] = useDisclosure(false);
  const [pendingState, setPendingState] = useState<
    "Open" | "Reached" | "NotReached" | "Cancelled" | undefined
  >(undefined);
  const { t } = useTranslation("features");

  const handleUpdatePhoneCallState = (
    state: "Open" | "Reached" | "NotReached" | "Cancelled" | undefined,
  ) => {
    if (!state) return;

    if (setPhoneCallStatus && setRecordState) {
      setPhoneCallStatus(state);
      setRecordState("Inactive");
      closeConfirmation();

      if (submitForm) {
        if (isCreate) {
          submitForm(false);
          return;
        }
        submitForm(true);
      }
    }
    closeConfirmation();
  };

  const handleQuickAction = (
    state: "Open" | "Reached" | "NotReached" | "Cancelled" | undefined,
  ) => {
    setPendingState(state);
    openConfirmation();
  };

  return (
    <>
      <Tooltip label={t("phoneCalls.setPhoneCallReached")}>
        <Button
          variant="outline"
          onClick={() => handleQuickAction("Reached")}
          disabled={isCreate ? false : currentState !== "Open"}
          size={"compact-md"}
        >
          <IconPhoneCheck size={"compact-md"} />
        </Button>
      </Tooltip>
      <Tooltip label={t("phoneCalls.setPhoneCallNotReached")}>
        <Button
          variant="outline"
          onClick={() => handleQuickAction("NotReached")}
          disabled={isCreate ? false : currentState !== "Open"}
          size={"compact-md"}
        >
          <IconPhoneX size={"compact-md"} />
        </Button>
      </Tooltip>
      {!isCreate && (
        <Tooltip label={t("phoneCalls.setPhoneCallCancelled")}>
          <Button
            variant="outline"
            onClick={() => handleQuickAction("Cancelled")}
            disabled={currentState !== "Open"}
            size={"compact-md"}
          >
            <IconCalendarX size={"compact-md"} />
          </Button>
        </Tooltip>
      )}
      <Modal
        opened={confirmationOpened}
        onClose={closeConfirmation}
        centered
        title={
          pendingState == "Reached"
            ? t("phoneCalls.confirmReached")
            : pendingState == "NotReached"
              ? t("phoneCalls.confirmNotReached")
              : pendingState == "Cancelled"
                ? t("phoneCalls.confirmCancelled")
                : ""
        }
      >
        <Flex gap="xs">
          <Button
            onClick={() => handleUpdatePhoneCallState(pendingState)}
            w={"48%"}
            mt={"xs"}
          >
            {t("phoneCalls.ok")}
          </Button>
          <Button
            onClick={closeConfirmation}
            variant="light"
            w={"48%"}
            mt={"xs"}
          >
            {t("phoneCalls.cancelButton")}
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
