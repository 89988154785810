import { Flex, useMantineTheme, Button, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconCalendarEvent,
  IconCalendarWeek,
  IconCalendarMonth,
} from "@tabler/icons-react";

interface HomeAgendaToolbarProps {
  selectedPeriod: string;
  onPeriodChange: (period: string) => void;
}

export default function HomeAgendaToolbar({
  selectedPeriod,
  onPeriodChange,
}: HomeAgendaToolbarProps) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const iconSize = isMobile ? 16 : 20;

  const periodItems = [
    { icon: IconCalendarEvent, label: "Today", value: "Today" },
    { icon: IconCalendarWeek, label: "Week", value: "Week" },
    { icon: IconCalendarMonth, label: "Month", value: "Month" },
  ];

  const handlePeriodClick = (value: string) => {
    onPeriodChange(value);
  };

  return (
    <Flex mb="xs" justify="center" align="center">
      {periodItems.map((item, index) => (
        <Button
          key={index}
          leftSection={<item.icon size={iconSize} />}
          variant={selectedPeriod === item.value ? "light" : "subtle"}
          color={selectedPeriod === item.value ? "blue" : "gray"}
          onClick={() => handlePeriodClick(item.value)}
          size="xs"
        >
          <Text size="xs" fw={600}>
            {item.label.slice(0, 1)}
          </Text>
        </Button>
      ))}
    </Flex>
  );
}
